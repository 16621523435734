import { FaInfoCircle, FaLandmark, FaMapMarkedAlt, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";
import { BsFillHouseCheckFill } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { CardInformative } from "@/pages/ReterritorializationAcsDashboard/CardInformative";

import "../../styles.scss";

type Props = {
	total: number;
	totalAcs: number;
	totalRegistrationMc: number;
	totalRegistraionFa: number;
	totalFamily: number;
	totalResidences: number;
	totalOtherBuildings: number;
};

export function GlobalInformativeVisits({
	total,
	totalAcs,
	totalFamily,
	totalOtherBuildings,
	totalRegistraionFa,
	totalRegistrationMc,
	totalResidences,
}: Props) {
	return (
		<Row className="row-image mx-3 bg-white">
			<Row className="ms-2">
				<h1 className="fs-5 pt-3 text-white fw-bolder">Total de visitas</h1>
				<h2 className="text-white fw-bolder" style={{ fontSize: "2.5rem" }}>
					{total?.toLocaleString()}
				</h2>
			</Row>
			<Row className=" row-information py-5 justify-content-evenly rounded">
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Nº de ACS"
						classNameValue="text-primary"
						classNameTitle="text-primary"
						value={totalAcs}
						icon={<FaUsers className="fs-2 text-primary" />}
						metaData={
							<CardOverlayTrigger
								header="Nº de ACS"
								message="Quantidade de ACS,s ativos que realizaram visitas no período selecionado."
								icon={<FaInfoCircle className="text-primary" />}
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Visitas MC"
						value={totalRegistrationMc}
						icon={<FaMapMarkedAlt className="fs-2" />}
						metaData={
							<CardOverlayTrigger
								header="Visitas MC"
								message="Quantidade de visitas realizadas pelos ACS,s ativos  dentro das microáreas(MC) no período selecionado e de acordo com o filtro utilizado."
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Visitas FA"
						value={totalRegistraionFa}
						icon={<FaMapMarkerAlt className="fs-2" />}
						metaData={
							<CardOverlayTrigger
								header="Visitas FA"
								message="Quantidade de visitas realizadas pelos ACS,s ativos fora de área (FA) no período selecionado e de acordo com o filtro utilizado."
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Nº de Familias"
						value={totalFamily}
						icon={<MdFamilyRestroom className="fs-2" />}
						metaData={
							<CardOverlayTrigger
								header="Nº de familias"
								message="Quantidade de famílias visitadas no período selecionado e de acordo com o filtro selecionado."
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Nº de Domicílios"
						value={totalResidences}
						icon={<BsFillHouseCheckFill className="fs-2" />}
						metaData={
							<CardOverlayTrigger
								header="Nº de Domicílios"
								message="Quantidade de domicílios visitados no período selecionado e de acordo com o filtro utilizado."
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-acs bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Outros imóveis"
						value={totalOtherBuildings}
						icon={<FaLandmark className="fs-2" />}
						metaData={
							<CardOverlayTrigger
								header="Outros Imóveis"
								message="Quantidade de outros tipos de imóveis visitados no período selecionado e de acordo com o filtro utilizado."
							/>
						}
					/>
				</Col>
			</Row>
		</Row>
	);
}

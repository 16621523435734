import Table from "react-bootstrap/Table";

import { indicatorsDetail } from "@/utils/indicators";
import { calculateISF } from "@/utils/calculateIsf";
import { calculatePerformance } from "@/utils/calculatePerformance";
import { calculateIndicatorDataPercentage } from "@/utils/calculatePercentage";
import { CityResumeData } from "@/@types/CityResumeData";

import { TableSkeletons } from "@/components/TableSkeletons";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";

type Props = {
	rankedCities?: CityResumeData[];
};

export function TableRankingCities({ rankedCities }: Props) {
	const { indicator1, indicator2, indicator3, indicator4, indicator5, indicator6, indicator7 } =
		indicatorsDetail;

	const rankedCitiesSort = rankedCities?.sort(
		(itemA, itemB) =>
			calculatePerformance(itemB.indicators) - calculatePerformance(itemA.indicators)
	);

	return (
		<Table responsive className="bg-white" bordered hover>
			<thead className="fw-bolder text-muted">
				<tr>
					<th className="text-center">Ranking</th>
					<th className="text-center">UF</th>
					<th className="text-center">Municipio</th>
					<th className="text-center">
						<label className="me-2">I1</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator1.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator1.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I2</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator2.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator2.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I3</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator3.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator3.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I4</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator4.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator4.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I5</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator5.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator5.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I6</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator6.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator6.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">I7</label>
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={indicator7.stateMeasures?.map(
										(stateMeasure) => {
											return `text-${stateMeasure.classColor}`;
										}
									)}
									titles={indicator7.stateMeasures.map(
										(mensure) => mensure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">
						<label className="me-2">ISF</label>
						<CardOverlayTrigger message="O Índice Sintético Final (ISF)  corresponde ao cálculo do desempenho do conjunto dos sete indicadores, a nota final é resultado da pontuação e a ponderação de cada indicador com condição atendida, deste modo esse número reflete o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada." />
					</th>
					<th className="text-center">
						<label className="me-2">Desempenho</label>
						<CardOverlayTrigger message="Desempenho corresponde ao cálculo do conjunto dos sete indicadores, refletindo o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada." />
					</th>
				</tr>
			</thead>
			<tbody className="overflow-auto" style={{ maxHeight: "0rem" }}>
				{rankedCities?.length ? (
					rankedCitiesSort?.map((item, index) => (
						<tr key={index}>
							<td
								title="posição"
								className=" border border-none border border-none text-center fw-bold"
							>{`${index + 1}º`}</td>
							<td title="UF" className="border border-none text-center fw-bold">
								{item.city.locale?.toLocaleUpperCase()}
							</td>
							<td title="cidade" className="border border-none text-center fw-bold">
								{item.city.federalUnit?.abbreviation?.toLocaleUpperCase()}
							</td>
							<td
								title="indicador 1"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator1.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorOne
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorOne
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 2"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator2.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorTwo
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorTwo
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 3"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator3.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorThree
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorThree
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 4"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator4.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorFour
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorFour
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 5"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator5.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorFive
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorFive
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 6"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator6.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorSix
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorSix
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="indicador 7"
								className={`border border-none text-center text-nowrap fw-bold text-${indicator7.getChartColor(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorSeven
									) || 0
								)}`}
							>
								{Math.round(
									calculateIndicatorDataPercentage(
										item?.indicators?.indicatorSeven
									) * 100
								) / 100 || 0}
								%
							</td>
							<td
								title="resultado isf"
								className="border border-none text-center text-nowrap fw-bold"
							>
								{calculateISF(item.indicators)}
							</td>
							<td
								title="resultado performance"
								className="border border-none text-center text-nowrap fw-bold"
							>
								{calculatePerformance(item.indicators)}
							</td>
						</tr>
					))
				) : (
					<TableSkeletons numberOfCells={12} numberOfRows={10} />
				)}
			</tbody>
		</Table>
	);
}

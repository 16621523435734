import { useState } from "react";
import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { PregnantPatient } from "@/@types/PregnantPatient";
import { Paginated } from "@/@types/paginated";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { TableSkeletons } from "@/components/TableSkeletons";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator2 } from "../ModalIndicator2";

import "./styles.scss";

type Props = {
	pregnantPatient: Paginated<PregnantPatient>;
	isLoading: boolean;
};

export function TableIndicator2({ isLoading, pregnantPatient }: Props) {
	const [patientSelected, setPatientSelected] = useState<PregnantPatient>({} as PregnantPatient);
	const [showModalIndicator2, setShowModalIndicator2] = useState(false);
	const handleShowModalIndicator2 = () => {
		setShowModalIndicator2(true);
	};
	const handleCloseModalIndicator2 = () => {
		setShowModalIndicator2(false);
	};
	return (
		<div>
			<ModalIndicator2
				show={showModalIndicator2}
				onHide={handleCloseModalIndicator2}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						<th>
							<CitizenSyncLabel />
						</th>
						<th>CIDADÃO</th>
						<th>DT NASC.</th>
						<th>CPF/CNS</th>
						<th>EQUIPE</th>
						<th>MC</th>
						<th>DUM</th>
						<th className="white-space-nowrap">1° ATENDIMENTO</th>
						<th className="white-space-nowrap">N° DE CONSULTAS</th>
						<th className="white-space-nowrap">IG 1° CONSULTA</th>
						<th>DPP</th>
						<th className="no-print no-excel">AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && pregnantPatient?.data ? (
						pregnantPatient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								<td title="Sincronização">
									<PremilinaryReportStatusIcon
										status={patient.preliminaryReportStatus}
									/>
								</td>
								<td title="Nome completo" className="text-nowrap">
									{patient.fullName || "NÃO INFORMADO"}
								</td>
								<td title="Data de nascimento" className="text-nowrap">
									{patient.birthdate
										? dayjs(patient.birthdate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td title="CPS/CNS" className="text-nowrap">
									{identifierOrNationalHealthCard(patient) || "NÃO INFORMADO"}
								</td>
								<td title="Equipe" className="text-nowrap">
									{patient.healthTeam?.name || "NÃO INFORMADO"}
								</td>
								<td title="Micro Área" className="text-nowrap">
									{patient.area || "NÃO INFORMADO"}
								</td>
								<td
									title="Data da última menstruação (DUM)"
									className="text-nowrap"
								>
									{displayFirstDumDate(patient.firstDumDate) || "NÃO INFORMADO"}
								</td>
								<td title="Data do primeiro atendimento" className="text-nowrap">
									{patient.firstAttendanceDate
										? dayjs(patient.firstAttendanceDate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>

								<td title="Número de consultas" className="text-nowrap">
									{displaySingularOrPlural(
										patient.attendances?.length,
										"consulta"
									) || "NÃO INFORMADO"}
								</td>
								<td
									title="Idade gestacional na primeira consulta (IG)"
									className="text-nowrap"
								>
									{patient.gestationalAge1St
										? displaySingularOrPlural(
												patient.gestationalAge1St,
												"semana"
										  )
										: "NÃO INFORMADO"}
								</td>
								<td title="Data provável do parto (DPP)" className="text-nowrap">
									{dayjs(patient?.dppDate).format("DD/MM/YYYY") ||
										"NÃO INFORMADO"}
								</td>
								<td className="text-end no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator2();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={12} height={20} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

import { useEffect, useState } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useApi } from "@/hooks/useApi";
import { useDebounce } from "@/hooks/useDebounce";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useLayout } from "@/../_metronic/layout/core";
import { Unit } from "@/contexts/UnitsContext";
import { User } from "@/@types/user";
import { Account } from "@/@types/Account";
import { Paginated } from "@/@types/paginated";
import { Option } from "@/@types/Option";
import { FederalUnit } from "@/@types/FederalUnit";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";

import { CitiesSelect } from "@/components/CitiesSelect";
import { SearchInput } from "@/components/SearchInput";
import { UnitsSelect } from "@/components/UnitsSelect";
import { FederalUnitsSelect } from "@/components/FederalUnitsSelect";
import { UsersTable } from "./UsersTable";
import { Restrict } from "@/components/Restrict";

import "./styles.scss";

export type UserRequest = User & {
	hasProfileActive?: boolean;
};

export function UsersManagement() {
	const api = useApi();
	const { setTitle } = useLayout();
	const { CancelToken } = axios;
	const usersManagementSourceCancel = CancelToken.source();
	const { user, uf, ibgeCode, currentAccount } = useCurrentAccount();
	const [fullName, setFullName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [paginatedUsers, setPaginatedUsers] = useState<Paginated<UserRequest>>(
		{} as Paginated<User>
	);
	const debouncedFullName = useDebounce(fullName, 1000);
	const [refreshPage, setRefreshPage] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [federalUnits, setFederalUnits] = useState<FederalUnit[]>([]);
	const [cities, setCities] = useState<Account[]>([]);
	const [healthUnits, setHealthUnits] = useState<Unit[]>([]);

	const defaultFederalUnitOption = user.isAdmin
		? { label: "Todos os estados", value: "" }
		: { label: uf, value: uf };
	const defaultCityOption = user.isAdmin
		? { label: "Todas as cidades", value: "" }
		: { label: currentAccount.locale || "", value: currentAccount.ibgeCode || "" };
	const defaultHealthUnitOption = user.isAdmin
		? { label: "Todas as unidades", value: "" }
		: { label: "", value: "" };

	const [selectedOptionFederalUnit, setSelectedOptionFederalUnit] =
		useState<Option<string>>(defaultFederalUnitOption);
	const [selectedOptionCity, setSelectedOptionCity] = useState<Option<string>>(defaultCityOption);
	const [selectedOptionUnit, setSelectedOptionUnit] =
		useState<Option<string>>(defaultHealthUnitOption);
	const [selectOptionStatus, setSelectedOptionStatus] = useState<Option<number | null>>();

	const isFederalUnitsSelectDisabled = !user.isAdmin;
	const isCitiesSelectDisabled = user.isAdmin ? false : !(uf && !ibgeCode);

	const filteredFederalUnits = federalUnits.filter(
		(federalUnit) => federalUnit.abbreviation === uf || user.isAdmin
	);

	const options = [
		{ value: null, label: "Todos" },
		{ value: 0, label: "Ativos" },
		{ value: 1, label: "Pendentes" },
	];

	async function getPermittedFederalUnits() {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedFederalUnits");
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function getPermittedCities(uf?: string) {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedCities", {
				params: { uf },
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function getPermittedHealthUnits(uf?: string, ibgeCode?: string) {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedHealthUnits", {
				params: { uf, ibgeCode },
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchPermittedFederalUnits() {
		setFederalUnits(await getPermittedFederalUnits());
	}

	async function fetchPermittedCities() {
		if (selectedOptionFederalUnit?.value) {
			setCities(await getPermittedCities(selectedOptionFederalUnit?.value));
		} else {
			setCities([]);
		}
	}

	async function fetchPermittedHealthUnits() {
		if (selectedOptionFederalUnit?.value && selectedOptionCity?.value) {
			setHealthUnits(
				await getPermittedHealthUnits(
					selectedOptionFederalUnit?.value,
					selectedOptionCity?.value
				)
			);
		} else {
			setHealthUnits([]);
		}
	}

	async function handleFetchUsers() {
		setIsLoading(true);
		let isLocked = null;
		let hasProfileActive = null;
		switch (selectOptionStatus?.value) {
			case null:
				isLocked = null;
				hasProfileActive = null;
				break;
			case 0:
				isLocked = false;
				hasProfileActive = true;
				break;
			case 1:
				isLocked = false;
				hasProfileActive = false;
				break;
			case 2:
				isLocked = true;
				hasProfileActive = null;
				break;
		}

		try {
			const { data } = await api.get(`/User/v1/UsersAccount/`, {
				cancelToken: usersManagementSourceCancel.token,
				params: {
					uf: selectedOptionFederalUnit?.value,
					ibgeCode: selectedOptionCity?.value,
					cnes: selectedOptionUnit?.value,
					pageNumber: pageNumber,
					pageSize: 10,
					fullName: fullName,
					isLocked: isLocked,
					hasProfileActive: hasProfileActive,
				},
			});
			setPaginatedUsers(data || ({} as Paginated<User>));
		} catch (error) {
			console.error(error);
			setPaginatedUsers({} as Paginated<User>);
		}
		setIsLoading(false);
	}

	async function handleChangeAccess(user: User) {
		try {
			const response = await api.post(
				"/User/v1/UserChangeIsLocked",
				{
					identifier: user.identifier,
				},
				{
					params: {
						identifier: user.identifier,
					},
				}
			);

			if (response.status === 200) {
				const slug = `Usuário: ${user.fullName}:`;
				if (user.isLocked) {
					toast.info(`${slug} DESBLOQUEADO`, { autoClose: 1500 });
				} else {
					toast.info(`${slug} BLOQUEADO`, { autoClose: 1500 });
				}
				handleFetchUsers();
			} else {
				toast.error("Falha ao tentar mudar status de bloqueio.", { autoClose: 1500 });
			}
		} catch (error) {
			toast.error("Falha ao tentar mudar status de bloqueio.", { autoClose: 1500 });
			console.error(error);
		}
	}

	async function revokeUserAccess(userRow: User, uf: string, ibgeCode?: string) {
		try {
			const response = await api.post("UserAccount/v1/RevokeAccess", {
				userId: userRow.id,
				uf,
				ibgeCode,
			});

			if (response.status === 200) {
				Swal.fire({
					title: "Removido com sucesso!",
					text: `O acesso do ${userRow?.fullName}  na cidade foi removido`,
					icon: "success",
				});
				handleFetchUsers();
			} else {
				toast.error("Falha ao tentar remover acessos", { autoClose: 1500 });
			}
		} catch (error) {
			toast.error("Falha ao tentar remover acessos", { autoClose: 1500 });
			console.error(error);
		}
	}

	async function handleConfirmRevoke(user: User, uf: string, ibgeCode?: string) {
		const { isConfirmed } = await Swal.fire({
			title: "Deseja remover o acesso?",
			text: "Não poderá ser desfeito!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
		});
		if (isConfirmed) {
			revokeUserAccess(user, uf, ibgeCode);
		}
	}

	function refreshTable() {
		setRefreshPage(true);
	}

	useEffect(() => {
		if (refreshPage) {
			handleFetchUsers();
			setRefreshPage(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshPage]);

	useEffect(() => {
		fetchPermittedCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptionFederalUnit.value]);

	useEffect(() => {
		fetchPermittedHealthUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptionCity.value]);

	useEffect(() => {
		setTitle("GESTÃO DE PROFISSIONAIS");
		fetchPermittedFederalUnits();
		fetchPermittedCities();
		fetchPermittedHealthUnits();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pageNumber !== 0) {
			handleFetchUsers();
		}
		return () => {
			if (usersManagementSourceCancel) {
				usersManagementSourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);

			return;
		}

		handleFetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedFullName,
		selectOptionStatus,
		selectedOptionFederalUnit,
		selectedOptionUnit,
		selectedOptionCity,
	]);

	return (
		<div className="row p-2 g-5 g-xl-8 pb-5">
			<ToastContainer />
			<Card>
				<Card.Body>
					<Row className="d-flex align-items-center justify-content-between">
						<Col
							xs={12}
							md={8}
							lg={8}
							className="d-flex flex-column justify-content-end mb-3"
						>
							<label className="form-label text-nowrap">
								Pesquisar Profissional:
							</label>
							<SearchInput
								placeholder="Pesquisar nome"
								value={fullName}
								setValue={setFullName}
							/>
						</Col>
						<Col
							xs={12}
							md={4}
							lg={4}
							className="d-flex flex-column justify-content-center mb-3"
						>
							<label className="d-flex form-label white-space-nowrap">
								Selecione um Filtro
							</label>
							<Select
								onChange={(event) => {
									setSelectedOptionStatus(event as Option<number>);
								}}
								value={selectOptionStatus}
								options={options}
								placeholder="Selecione..."
							/>
						</Col>
					</Row>
					<Row className="d-flex align-items-center justify-content-between">
						<Restrict permissions={[PermissionEnum.STATE_PROFESSIONAL_MANAGEMENT]}>
							<Col
								xs={4}
								md={2}
								lg={2}
								className="d-flex flex-column justify-content-start mb-3"
							>
								<FederalUnitsSelect
									value={selectedOptionFederalUnit}
									isDisabled={isFederalUnitsSelectDisabled}
									federalUnits={filteredFederalUnits}
									onChange={(newValue) => {
										setSelectedOptionFederalUnit(newValue as Option<string>);
										setSelectedOptionCity({
											label: "Todas as cidades",
											value: "",
										} as Option<string>);
										setSelectedOptionUnit({
											label: "Todas as unidades",
											value: "",
										} as Option<string>);
									}}
								/>
							</Col>
						</Restrict>
						<Restrict
							permissions={[
								PermissionEnum.STATE_PROFESSIONAL_MANAGEMENT,
								PermissionEnum.VIEW_PROFESSIONAL_MANAGEMENT,
							]}
						>
							<Col className="d-flex flex-column justify-content-start mb-3">
								<CitiesSelect
									value={selectedOptionCity}
									isDisabled={isCitiesSelectDisabled}
									accounts={cities}
									onChange={(event) => {
										setSelectedOptionCity(event as Option<string>);
										setSelectedOptionUnit({
											label: "Todas as unidades",
											value: "",
										} as Option<string>);
									}}
								/>
							</Col>
							<Col
								xs={12}
								md={4}
								lg={4}
								className="d-flex flex-column justify-content-center mb-3"
							>
								<UnitsSelect
									value={selectedOptionUnit}
									units={healthUnits}
									onChange={(event) =>
										setSelectedOptionUnit(event as Option<string>)
									}
									noDefaultOption
								/>
							</Col>
						</Restrict>
					</Row>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<div className="table-responsive">
						<UsersTable
							cities={cities}
							handleChangePageNumber={setPageNumber}
							pageNumber={pageNumber}
							selectedUf={selectedOptionFederalUnit?.value}
							selectedIbgeCode={selectedOptionCity?.value}
							selectedCnes={selectedOptionUnit?.value}
							isLoading={isLoading}
							paginatedUsers={paginatedUsers}
							refreshTable={refreshTable}
							handleConfirmRevoke={handleConfirmRevoke}
							handleChangeAccess={handleChangeAccess}
						/>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
}

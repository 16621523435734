import "./styles.scss";

type Props = {
	status: number;
};

export function PremilinaryReportStatusIcon({ status }: Props) {
	const preliminaryReportImageDictionary = [
		"legislative-proposal",
		"legislative-proposal",
		"health-information-system-for-primary-care",
		"synced",
	];

	const preliminaryReportImageAltDictionary = ["PEC", "PEC", "SISAB", "SINCRONIZADO"];

	const preliminaryReportFilename = preliminaryReportImageDictionary[status];
	const preliminaryReportImageAltFile = preliminaryReportImageAltDictionary[status];
	const source = `/media/images/${preliminaryReportFilename}.svg`;

	return (
		<>
			<label className="no-visible">{preliminaryReportImageAltFile}</label>
			<img
				src={source}
				alt={preliminaryReportImageAltFile}
				className="image-preliminary-report"
			/>
		</>
	);
}

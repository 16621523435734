import ReactApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type ChartProps = {
	type?:
		| "line"
		| "area"
		| "bar"
		| "pie"
		| "donut"
		| "radialBar"
		| "scatter"
		| "bubble"
		| "heatmap"
		| "treemap"
		| "boxPlot"
		| "candlestick"
		| "radar"
		| "polarArea"
		| "rangeBar";
	options: ApexOptions;
	series: ApexOptions["series"];
	width?: number | string;
	height?: number | string;
};

export function CustomChart({ ...rest }: ChartProps) {
	return <ReactApexCharts {...rest} />;
}

import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";
import XLSX from "sheetjs-style";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { toAbsoluteUrl } from "@/../_metronic/helpers";
import { useLayout } from "@/../_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { calculateIndicatorPercentage } from "@/utils/calculatePercentage";
import { SelectOptionsProps } from "@/@types/genericals";
import { Option } from "@/@types/Option";
import { ExportTableType } from "@/@types/ExportTableType";

import { defaultHealthTeam } from "@/components/HealthTeamsSelect";
import { HealthTeamReportTable } from "@/components/HealthTeamReportTable";
import { IndicatorsHealthTeam } from "@/components/HealthTeamReportTable";
import { defaultUnit } from "@/components/UnitsSelect";
import { QuartersSelect } from "@/components/QuartersSelect";
import { LegendData } from "@/components/LegendData";
import { OptionsPopover } from "@/components/Popovers/OptionsPopover";

import "./styles.css";

export function HealthTeamReport() {
	const api = useApi();
	const { setTitle } = useLayout();
	const { uf, ibgeCode, cnes, currentAccount } = useCurrentAccount();
	const { healthTeams } = useHealthTeams();
	const [isLoading, setIsLoading] = useState(false);
	const dashboardDataRef = useRef<HTMLTableElement | null>(null);

	const handlePrint = useReactToPrint({
		content: () => dashboardDataRef.current,
	});

	useEffect(() => {
		setTitle("Relatório Gerencial de Equipes");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const quarter = `Q${Math.ceil((dayjs().month() + 1) / 4)}/${dayjs().year()}`;

	const [selectedQuarter, setSelectedQuarter] = useState<SelectOptionsProps>({
		value: quarter,
		label: quarter.split("/").join(" - "),
	});
	const [selectedUnit] = useState<SelectOptionsProps>(defaultUnit);
	const hasSelectedUnit = selectedUnit.value !== "0";

	const [selectedHealthTeam] = useState<SelectOptionsProps>(defaultHealthTeam);

	const [indicatorsHealthTeam, setIndicatorsHealthTeam] = useState<IndicatorsHealthTeam>(
		{} as IndicatorsHealthTeam
	);

	const hasSelectedHealthTeam =
		Object.keys(selectedHealthTeam).length > 0 && selectedHealthTeam.value !== "0";

	async function getIndicatorsGroupedByHealthTeam() {
		if (!ibgeCode) return;
		setIsLoading(true);
		try {
			const { data } = await api.get<IndicatorsHealthTeam>(
				"/indicator/v1/dashboardGroupedByHealthTeam",
				{
					params: {
						uf,
						ibgeCode,
						cnes,
						quarter: selectedQuarter.value,
						...(hasSelectedUnit && { cnes: selectedUnit.value }),
						...(hasSelectedHealthTeam && { ine: selectedHealthTeam.value }),
					},
				}
			);
			setIndicatorsHealthTeam(data);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	}

	async function handleExportTable(exportTableType: ExportTableType) {
		if (exportTableType === "print") {
			handlePrint();
		} else if (exportTableType === "excel") {
			exportToExcel(indicatorsHealthTeam);
		}
	}

	function exportToExcel(indicatorsData: IndicatorsHealthTeam) {
		const dataForExcel = healthTeams.map((healthTeam) => {
			const indicators = Object.values(indicatorsData).map((indicatorArray) => {
				const matchingIndicator = indicatorArray.find(
					(indicator) => indicator.healthTeam.ine === healthTeam.ine
				);

				return {
					Valid: matchingIndicator?.Valid || 0,
					Identified: matchingIndicator?.Identified || 0,
					Percent: matchingIndicator?.Valid
						? calculateIndicatorPercentage(
								matchingIndicator.Valid,
								matchingIndicator.Identified
						  ) + "%"
						: "0%",
				};
			});

			return {
				INE: healthTeam?.ine,
				Equipe: healthTeam?.name,
				...Object.fromEntries(
					indicators.flatMap((indicator, index) => [
						[`Válidos (Indicador ${index + 1})`, indicator.Valid],
						[`Identificados (Indicador ${index + 1})`, indicator.Identified],
						[`Percentual (Indicador ${index + 1})`, indicator.Percent],
					])
				),
			};
		});

		const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Health Team Report");

		XLSX.writeFile(workbook, "health_team_report.xlsx");
	}

	useEffect(() => {
		async function fetchIndicators() {
			await getIndicatorsGroupedByHealthTeam();
		}
		fetchIndicators();

		return () => {
			setIndicatorsHealthTeam({} as IndicatorsHealthTeam);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuarter, selectedUnit, selectedHealthTeam]);

	return (
		<div className="p-2">
			<section className="mb-2">
				<Row className="d-flex justify-content-between w-100">
					<Col
						sm={12}
						md={12}
						lg={2}
						xl={2}
						className="text-primary text-nowrap no-print mb-2"
					>
						<div className="d-flex">
							<QuartersSelect
								id="quarters-select"
								value={selectedQuarter}
								onChange={(newValue) =>
									setSelectedQuarter(newValue as Option<string>)
								}
							/>
						</div>
					</Col>
					<Col sm={12} md={12} lg={1} xl={1} className="d-flex align-items-end mb-2">
						<OverlayTrigger
							trigger="click"
							placement="bottom"
							overlay={(props) =>
								OptionsPopover({
									handlePrintClick: () => handleExportTable("print"),
									handleExportExcelClick: () => handleExportTable("excel"),
									...props,
								})
							}
							rootClose
						>
							<Button variant="secondary" className="px-2" disabled={isLoading}>
								Opções
							</Button>
						</OverlayTrigger>
					</Col>
				</Row>
			</section>
			<section className="width-print" ref={dashboardDataRef}>
				<div className="print-container">
					<div className="d-flex align-items-center justify-content-between">
						<div>
							<img
								src={toAbsoluteUrl("/media/logos/logo-multintegrada.png")}
								alt="Multintegrada - Tecnologia"
								width={145}
								height={50}
							/>
						</div>
						<div>
							<h3 className="text-center py-10 px-5">
								Prefeitura Municipal de {currentAccount.locale}-
								{currentAccount.federalUnit?.abbreviation}
							</h3>
						</div>
						<div>
							<img
								src={toAbsoluteUrl("/media/logos/logo-indicadores-esus-azul.png")}
								alt="Multintegrada - Tecnologia"
								width={100}
								height={40}
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<strong>
							Gerado em: {dayjs().format("DD/MM/YYYY")} as {dayjs().format("HH:mm")}
						</strong>
					</div>
				</div>
				<LegendData
					titles={["Ruim", "Regular", "Bom", "Ótimo"]}
					ClassNamesColors={[
						"text-danger",
						"text-warning",
						"text-success",
						"text-primary",
					]}
				/>
				<HealthTeamReportTable
					indicatorsData={indicatorsHealthTeam}
					period={selectedQuarter}
				/>
			</section>
		</div>
	);
}

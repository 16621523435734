import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useLayout } from "@/../_metronic/layout/core";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { MdMode } from "react-icons/md";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { phoneFormat } from "@/utils/formatPhone";

import { ModalProfessionalEdit } from "@/components/ModalProfessionalEdit";
import { UpdatePassword } from "./UpdatePassword";

export function Personal() {
	const { setTitle } = useLayout();
	const { user } = useCurrentAccount();

	const [showModalProfessionalEdit, setShowModalProfessionalEdit] = useState(false);
	const handleShowModalProfessionalEdit = () => {
		setShowModalProfessionalEdit(true);
	};
	const handleCloseModalProfessionalEdit = () => {
		setShowModalProfessionalEdit(false);
	};

	const [showModalUpdatePassword, setShowModalUpdatePassword] = useState(false);
	const handleShowModalUpdatePassword = () => {
		setShowModalUpdatePassword(true);
	};
	const handleCloseModalUpdatePassword = () => {
		setShowModalUpdatePassword(false);
	};

	useEffect(() => {
		setTitle("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<ModalProfessionalEdit
				titleModal="Edição do Perfil"
				show={showModalProfessionalEdit}
				onHide={handleCloseModalProfessionalEdit}
				selectedUser={user}
			/>
			<UpdatePassword
				show={showModalUpdatePassword}
				onHide={handleCloseModalUpdatePassword}
			/>
			<h3 className="text-primary mt-3">Meu Perfil</h3>
			<section className="rounded bg-white">
				<Row className="d-flex justify-content-between w-100 px-3 py-3">
					<Col lg={6} className="d-flex align-items-center justify-content-start">
						<h2 className="text-primary ms-3">Dados Pessoais</h2>
					</Col>
					<Col lg={6} className="d-flex align-items-center justify-content-end">
						<Button variant="warning" onClick={handleShowModalProfessionalEdit}>
							<MdMode className="me-2" />
							Editar
						</Button>
					</Col>
				</Row>
				<Row className="d-flex justify-content-between w-100 px-5 py-3">
					<Col lg={6}>
						<Row className="py-2">
							<label className="fs-5 text-break">
								<strong>Nome completo: </strong>
								{user.fullName}
							</label>
						</Row>
						<Row className="py-2">
							<label className="fs-5 text-break">
								<strong>Email: </strong>
								{user.email}
							</label>
						</Row>
						<Row className="py-2">
							<label className="fs-5">
								<strong>Cargo: </strong>
								{user.occupation}
							</label>
						</Row>
					</Col>

					<Col lg={2}>
						<Row className="py-2">
							<label className="fs-5">
								<strong>Sexo: </strong>
								{user.gender}
							</label>
						</Row>
						<Row className="py-2">
							<label className="fs-5">
								<strong>CPF: </strong>
								{formatIdentifier(user.identifier)}
							</label>
						</Row>
					</Col>

					<Col lg={4}>
						<Row className="py-2">
							<label className="fs-5">
								<strong>Dt. Nascimento: </strong>
								{user.birthdate && dayjs(user.birthdate).format("DD/MM/YYYY")}
							</label>
						</Row>
						<Row className="py-2">
							<label className="fs-5">
								<strong>WhatsApp: </strong>
								{phoneFormat(user.phone)}
							</label>
						</Row>
					</Col>
				</Row>
				<hr className="dashed-line bg-white mx-4" style={{ border: "1px dashed #000" }} />
				<Row className="rounded px-5 py-5">
					<Row>
						<h2 className="text-primary">Endereço: </h2>
					</Row>
					<Row>
						<Col lg={1} className="fs-5 py-2">
							<strong className="me-1">Estado:</strong>
							{user.city?.federalUnit?.abbreviation}
						</Col>
						<Col lg={3} className="fs-5 py-2">
							<strong className="me-1">Cidade:</strong>
							{user.city?.locale}
						</Col>
						<Col lg={4} className="fs-5 py-2 text-break">
							<strong className="me-1">Logradouro:</strong>
							{user.street}
						</Col>
						<Col lg={2} className="fs-5 py-2">
							<strong className="me-1">N°:</strong>
							{user.houseNumber}
						</Col>
						<Col lg={2} className="fs-5 py-2">
							<strong className="me-1">Bairro:</strong>
							{user.neighborhood}
						</Col>
					</Row>
				</Row>
			</section>

			<section className="bg-white mt-5 px-5 py-3">
				<Row>
					<h2 className="text-primary">Credenciais</h2>
				</Row>
				<Button variant="primary" onClick={handleShowModalUpdatePassword}>
					Atualizar senha
				</Button>
			</section>
		</div>
	);
}

export function calculateStatusVaccinationSearchActive(
	appointedDays?: number,
	vaccinationDays?: number
) {
	if (appointedDays && vaccinationDays) {
		if (vaccinationDays > appointedDays) return "delayed";
		else {
			return "onTime";
		}
	}
	return "pending";
}

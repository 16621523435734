import Popover, { PopoverProps } from "react-bootstrap/Popover";
import { AiFillWarning } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

type StatusPopoverProps = PopoverProps;

export function StatusPopover({ ...rest }: StatusPopoverProps) {
	const iconStyles = {
		paddingBottom: 5,
		height: 25,
	};

	return (
		<Popover id="status-popover" {...rest}>
			<Popover.Header>Status do cadastro</Popover.Header>
			<Popover.Body>
				<p>Define se o cadastro está ou não desatualizado.</p>
				<ul>
					<li>
						Se fizer 12 meses ou menos desde o último cadastro, o status será{" "}
						<FaCheck className="text-success fs-2" style={iconStyles} />
					</li>
					<li>
						Se fizer mais de 12 meses desde o último cadastro, o status será{" "}
						<AiFillWarning className="text-danger fs-2" style={iconStyles} />
					</li>
				</ul>
			</Popover.Body>
		</Popover>
	);
}

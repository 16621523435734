import { FormEvent } from "react";

import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { SearchInput } from "@/components/SearchInput";

import { Option } from "@/@types/Option";

type Props = {
	fullName: string;
	setFullName: (fullName: string) => void;
	setVaccinationStatus: (vaccinationStatus: string) => void;
	handleFilter: (event: FormEvent) => void;
};

export function TripleViralCitizensFilters({
	fullName,
	setFullName,
	setVaccinationStatus,
	handleFilter,
}: Props) {
	const vaccinationOptions: Option<string>[] = [
		{ label: "Todos", value: "" },
		{ label: "Concluído", value: "finished" },
		{ label: "Pendente", value: "pending" },
		{ label: "Em atraso", value: "delayed" },
		{ label: "Perdido", value: "lost" },
		{ label: "Abandono", value: "abandoned" },
	];

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={12} md={12} lg={8} xl={8} className="justify-content-start mb-2">
					<div>
						<label className="form-label text-nowrap">Pesquisar Cidadão:</label>
						<SearchInput
							placeholder="Pesquisar nome"
							value={fullName}
							setValue={setFullName}
						/>
					</div>
				</Col>
				<Col sm={12} md={12} lg={4} xl={4} className="justify-content-start mb-2">
					<div className="d-flex flex-column">
						<label htmlFor={"vaccination-status-select"} className="form-label">
							Status:
						</label>
						<Select
							id="vaccination-status-select"
							options={vaccinationOptions}
							placeholder="Selecione..."
							styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
							onChange={(event) =>
								setVaccinationStatus((event as Option<string>).value)
							}
						/>
					</div>
				</Col>
			</Row>
		</Form>
	);
}

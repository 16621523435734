import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import classNamesGroups from "clsx";

import { api, authApi } from "@/services/api";

import { NextButton } from "@/components/NextButton";
import { CodeInput } from "@/components/CodeInput";

import { federalUnits } from "@/utils/federalUnits";
import { onlyNumbers } from "@/utils/genericals";
import statesAndCities from "@/utils/static/states-and-cities.json";
import Yup from "@/utils/mixins/yup";
import { StatesAndCities } from "@/@types/statesAndCities";

import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

type FormValues = typeof initialValues;

const initialValues = {
	state: "",
	city: "",
	occupation: "",
	name: "",
	identifier: "",
	email: "",
	phone: "+55",
	password: "",
	passwordConfirmation: "",
};

const registrationSchema = Yup.object().shape({
	state: Yup.string().required("O estado é obrigatório"),
	city: Yup.string().required("A cidade é obrigatória"),
	occupation: Yup.string().required("A ocupação é obrigatória"),
	name: Yup.string()
		.min(4, "Mínimo de 4 caracteres")
		.max(55, "Máximo de 55 caracteres")
		.required("Nome é obrigatório")
		.test("name", "Digite seu nome completo", (value) => {
			return Boolean(value && value.split(" ").length >= 2);
		}),
	email: Yup.string()
		.email("Digite um e-mail válido")
		.min(1, "Digite um e-mail válido")
		.required("E-mail é obrigatório"),
	phone: Yup.string()
		.DDIPhone("Digite um telefone válido")
		.min(19, "Digite um telefone válido")
		.max(19, "Digite um telefone válido")
		.required("Telefone é obrigatório"),
	identifier: Yup.string()
		.cpf("Digite um CPF válido")
		.min(14, "Digite o CPF corretamente")
		.required("CPF é obrigatório"),
	password: Yup.string()
		.min(6, "Mínimo de 6 caracteres")
		.max(20, "Máximo de 20 caracteres")
		.required("Senha é obrigatória"),
	passwordConfirmation: Yup.string()
		.min(6, "Mínimo de 6 caracteres")
		.max(20, "Máximo de 20 caracteres")
		.required("Confirmação de senha é obrigatória")
		.oneOf([Yup.ref("password"), null], "Senhas não conferem"),
});

export function Registration() {
	const { states, cities } = statesAndCities as StatesAndCities;
	const navigate = useNavigate();
	const [step, setStep] = useState(1);
	const [code, setCode] = useState("");
	const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(false);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
	const [otherOccupation, setOtherOccupation] = useState("");
	const [isExistingUser, setIsExistingUser] = useState(false);
	const registerToken = useRef("");
	const validateAccountToken = useRef("");
	const hasStepsWithDescription = step === 3 || step === 5;

	function handleGoToPreviousStep() {
		if (step === 1) {
			navigate("/auth/login");
			return;
		}

		setStep(step - 1);
	}

	async function handleGoToNextStep() {
		if (step === 5) {
			setIsPreviousButtonDisabled(true);
			setIsNextButtonDisabled(true);

			await validateAccount();
			setIsPreviousButtonDisabled(false);
			setIsNextButtonDisabled(false);

			return;
		}

		formik.validateForm();

		if (step === 3) {
			registerAndSendCode();
		}

		setStep(step + 1);
	}

	function getTitle() {
		switch (step) {
			case 3:
				const name = formik.values.name;

				return `Olá ${name ? name.split(" ")[0] : "Desconhecido"}, estamos quase lá...`;
			case 5:
				return "Este é o último passo!";
		}
	}

	function getSubtitle() {
		switch (step) {
			case 3:
				return (
					<>
						Ao clicar no botão, você concorda com os <br /> nossos termos de uso e
						políticas de privacidade
					</>
				);
			case 5:
				return (
					<>
						Ao informar seus dados e concluir, você automaticamente <br /> concorda com
						nossos termos de uso e políticas de privacidade
					</>
				);
		}
	}

	function capitalize(fullName: String) {
		const names = fullName.split(" ");

		names.forEach((name, index) => {
			names[index] = name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
		});

		return names.join(" ");
	}

	async function checkUserExists() {
		const userIdentifier = formik.values.identifier
			.replaceAll(".", "")
			.replaceAll("-", "")
			.replaceAll("_", "");
		if (userIdentifier.length !== 11) {
			return;
		}
		try {
			const response = await api.get("/User/v1/CheckUserExists", {
				params: { identifier: userIdentifier },
			});

			if (response.status === 204) {
				setIsExistingUser(false);
				setIsNextButtonDisabled(false);
			} else if (response.status === 200) {
				setIsExistingUser(true);
				setIsNextButtonDisabled(true);
				toast.error("Usuário já possui cadastro, tente login ou Recupere a senha");
			}
		} catch (error) {
			toast.error("Usuário já possui cadastro, tente login ou Recupere a senha");
			console.log(error);
		}
	}

	async function registerUser() {
		const occupation =
			formik.values.occupation !== "Outro" ? formik.values.occupation : otherOccupation;

		const city = JSON.parse(formik.values.city);
		const ibgeCode = String(city.id);
		const cityName = city.nome;
		const state = formik.values.state;
		await api.post("/user/v1/registeruser", {
			fullName: capitalize(formik.values.name),
			email: formik.values.email,
			identifier: onlyNumbers(formik.values.identifier),
			phone: onlyNumbers(formik.values.phone),
			occupation,
			ibgeCode,
			locale: cityName,
			federalUnitId: federalUnits.find((federalUnit) => federalUnit.abbreviation === state)
				?.id,
		});
	}

	async function register(values: FormValues) {
		const name = capitalize(values.name);
		const { data: register } = await authApi.post("/v1/auth/register", {
			fullName: name,
			email: values.email,
			identifier: onlyNumbers(values.identifier),
			phoneNumber: onlyNumbers(values.phone),
		});

		registerToken.current = register.data.token;
		await sendCode();
	}

	async function sendCode() {
		try {
			await authApi.post("/v1/auth/sendVerificationCode", undefined, {
				headers: { Authorization: `Bearer ${registerToken.current}` },
			});
		} catch (error) {
			toast.error("Não foi possível enviar o código, tente novamente mais tarde");
			console.log(error);
		}
	}

	async function registerAndSendCode() {
		try {
			await registerUser();
			await register(formik.values);
		} catch (error) {
			toast.error("Não foi possível realizar o cadastro, tente novamente mais tarde.");
			console.log(error);
		}
	}

	async function validateAccount() {
		const values = formik.values;

		try {
			const { data: record } = await authApi.post(
				"/v1/auth/validateAccount",
				{
					code,
					password: values.password,
					confirmPassword: values.passwordConfirmation,
				},
				{
					headers: { Authorization: `Bearer ${registerToken.current}` },
				}
			);

			validateAccountToken.current = record.data.token;
			setStep(6);
		} catch (error: any) {
			if (error.response.data.errors.includes("Invalid code")) {
				toast.error("Código de verificação inválido. Tente novamente.");
				setStep(4);
				return;
			}

			console.log(error);
		}
	}

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit() {},
	});

	useEffect(() => {
		if (step === 4 && code.length !== 6) {
			setIsNextButtonDisabled(true);
			return;
		}

		setIsNextButtonDisabled(false);
	}, [step, code]);

	useEffect(() => {
		setIsNextButtonDisabled(true);

		if (formik.errors.identifier === "Digite um CPF válido") {
			return;
		}

		checkUserExists();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.identifier, formik.errors.identifier]);

	useEffect(() => {
		if (step === 2) {
			if (
				!formik.values.name ||
				!formik.values.identifier ||
				!formik.values.occupation ||
				!formik.values.city
			) {
				setIsNextButtonDisabled(true);
			} else {
				setIsNextButtonDisabled(false);
			}
		}

		if (step === 3) {
			if (!formik.values.email || formik.values.phone.length < 19) {
				setIsNextButtonDisabled(true);
			} else {
				setIsNextButtonDisabled(false);
			}
		}
	}, [step, formik.values]);

	return (
		<>
			<ToastContainer />

			{hasStepsWithDescription && (
				<div className="text-center">
					<h3 className="text-dark">{getTitle()}</h3>
				</div>
			)}

			<div className="mt-10" id="registration-container">
				<form
					onSubmit={formik.handleSubmit}
					className={classNamesGroups("form w-100", step === 4 && "d-none")}
					noValidate
					id="registration-form"
				>
					<section className={classNamesGroups(step !== 1 && "d-none")}>
						<div className="col-12 mt-10">
							<h3 className="fw-bolder">1. Adicione seu CPF</h3>
						</div>

						<div className="col-12">
							<label htmlFor="identifier" className="sr-only">
								CPF
							</label>
							<InputMask
								{...formik.getFieldProps("identifier")}
								id="identifier"
								mask="999.999.999-99"
								name="identifier"
								placeholder="000.000.000-00"
								autoFocus
								value={formik.values.identifier}
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid": isExistingUser,
									},
									{
										"is-valid":
											formik.touched.identifier &&
											!formik.errors.identifier &&
											!isExistingUser,
									}
								)}
							/>

							{formik.touched.identifier && formik.errors.identifier && (
								<div className="fv-plugins-message-container text-danger">
									<span role="alert">{formik.errors.identifier}</span>
								</div>
							)}
						</div>
					</section>

					<section className={classNamesGroups(step !== 2 && "d-none")}>
						<div className="col-12">
							<h3 className="fw-bolder">2. Como você quer ser chamado?</h3>
						</div>

						<div className="col-12 mb-2">
							<label htmlFor="name" className="sr-only">
								Nome
							</label>
							<input
								{...formik.getFieldProps("name")}
								type="text"
								name="name"
								id="name"
								placeholder="João Almeida de Sousa Silva"
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid": formik.touched.name && formik.errors.name,
									},
									{
										"is-valid": formik.touched.name && !formik.errors.name,
									}
								)}
							/>

							{formik.touched.name && formik.errors.name && (
								<div className="fv-plugins-message-container text-danger">
									<span role="alert">{formik.errors.name}</span>
								</div>
							)}
						</div>
						<div className="col-12">
							<h3 className="fw-bolder">3. Adicione seu estado, cidade e cargo</h3>
						</div>

						<div className="col-12 mb-4">
							<div className="row pr-0">
								<div className="col-sm-12 col-md-2">
									<label htmlFor="state" className="sr-only">
										Estado
									</label>
									<select
										{...formik.getFieldProps("state")}
										name="state"
										id="state"
										className="form-select form-select-lg fs-3 text-muted fw-normal mb-2"
									>
										<option value="" disabled>
											Estado
										</option>
										{states.map((state) => (
											<option
												key={state.abbreviation}
												value={state.abbreviation}
												className="text-dark"
											>
												{state.abbreviation}
											</option>
										))}
									</select>
								</div>
								<div className="col-sm-12 col-md-10">
									<label htmlFor="city" className="sr-only">
										Cidade
									</label>
									<select
										{...formik.getFieldProps("city")}
										name="city"
										id="city"
										className="form-select form-select-lg fs-3 text-muted fw-normal"
									>
										<option value="" disabled>
											Cidade
										</option>
										{formik.values.state &&
											cities
												.filter(
													(city) =>
														city.microrregiao.mesorregiao.UF.sigla ===
														formik.values.state
												)
												.map((city) => (
													<option
														key={city.id}
														value={JSON.stringify(city)}
														className="text-dark"
													>
														{city.nome}
													</option>
												))}
									</select>
								</div>
							</div>
						</div>

						<div className="col-12">
							<label htmlFor="occupation" className="sr-only">
								Cargo
							</label>
							<select
								{...formik.getFieldProps("occupation")}
								name="occupation"
								id="occupation"
								className="form-select form-select-lg fs-3 text-muted fw-normal"
							>
								<option value="" disabled>
									Qual seu cargo
								</option>
								<option value="Secretário de Saúde">Secretário de Saúde </option>
								<option value="Secretário Adjunto">Secretário Adjunto (a)</option>
								<option value="Coordenador de Secretaria">
									Coordenador de Secretaria
								</option>
								<option value="Coordenador Externo">Coordenador Externo</option>
								<option value="Enfermeiro(a)">Enfermeiro(a)</option>
								<option value="Técnico em Enfermagem">Técnico em Enfermagem</option>
								<option value="Médico (a)">Médico (a)</option>
								<option value="Cirurgião Dentista ">Cirurgião Dentista</option>
								<option value="Auxiliar/Técnico de Saúde Bucal">
									Auxiliar/Técnico de Saúde Bucal
								</option>
								<option value="Agente Comunitário de Saúde">
									Agente Comunitário de Saúde
								</option>
								<option value="Outro">Outro</option>
							</select>
							{formik.values.occupation === "Outro" && (
								<div className="mt-3">
									<label htmlFor="othet" className="sr-only ">
										Outro Cargo:
									</label>
									<input
										className="form-control form-control-lg fs-3 fw-normal"
										type="text"
										placeholder="Digite seu cargo"
										value={otherOccupation}
										onChange={(e) => {
											setOtherOccupation(e.target.value);
										}}
									/>
								</div>
							)}
						</div>
					</section>

					<section className={classNamesGroups(step !== 3 && "d-none")}>
						<div className="col-12">
							<h3 className="fw-bolder">4. Qual é o seu e-mail?</h3>
						</div>

						<div className="col-12">
							<label htmlFor="email" className="sr-only">
								E-mail
							</label>
							<input
								{...formik.getFieldProps("email")}
								type="email"
								name="email"
								id="email"
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid": formik.touched.email && formik.errors.email,
									},
									{
										"is-valid": formik.touched.email && !formik.errors.email,
									}
								)}
							/>

							{formik.touched.email && formik.errors.email && (
								<div className="fv-plugins-message-container text-danger m-0">
									<span role="alert">{formik.errors.email}</span>
								</div>
							)}
						</div>

						<div className="col-12 mt-10">
							<h3 className="fw-bolder">5. Adicione seu contato para WhatsApp</h3>
						</div>

						<div className="col-12">
							<label htmlFor="phone" className="sr-only">
								Contato
							</label>
							<InputMask
								{...formik.getFieldProps("phone")}
								mask="+99 (99) 99999-9999"
								name="phone"
								placeholder="(00) 00000-0000"
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid": formik.touched.phone && formik.errors.phone,
									},
									{
										"is-valid": formik.touched.phone && !formik.errors.phone,
									}
								)}
							/>

							{formik.touched.phone && formik.errors.phone && (
								<div className="fv-plugins-message-container text-danger m-0">
									<span role="alert">{formik.errors.phone}</span>
								</div>
							)}
						</div>
					</section>

					<section className={classNamesGroups(step !== 5 && "d-none")}>
						<div className="col-12">
							<h3 className="fw-bolder">6. Insira sua senha para login</h3>
						</div>

						<div className="col-12">
							<label htmlFor="password" className="sr-only">
								Senha
							</label>
							<input
								{...formik.getFieldProps("password")}
								type="password"
								name="password"
								id="password"
								placeholder="Escolha uma senha"
								autoComplete="on"
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid":
											formik.touched.password && formik.errors.password,
									},
									{
										"is-valid":
											formik.touched.password && !formik.errors.password,
									}
								)}
							/>

							{formik.touched.password && formik.errors.password && (
								<div className="fv-plugins-message-container text-danger">
									<span role="alert">{formik.errors.password}</span>
								</div>
							)}
						</div>

						<div className="col-12 mt-3">
							<label htmlFor="password-confirmation" className="sr-only">
								Confirmação de senha
							</label>
							<input
								{...formik.getFieldProps("passwordConfirmation")}
								type="password"
								name="passwordConfirmation"
								id="password-confirmation"
								placeholder="Confirme sua senha"
								autoComplete="on"
								className={classNamesGroups(
									"form-control form-control-lg fs-3 fw-normal",
									{
										"is-invalid":
											formik.touched.passwordConfirmation &&
											formik.errors.passwordConfirmation,
									},
									{
										"is-valid":
											formik.touched.passwordConfirmation &&
											!formik.errors.passwordConfirmation,
									}
								)}
							/>

							{formik.touched.passwordConfirmation &&
								formik.errors.passwordConfirmation && (
									<div className="fv-plugins-message-container text-danger">
										<span role="alert">
											{formik.errors.passwordConfirmation}
										</span>
									</div>
								)}
						</div>
					</section>
				</form>
			</div>

			<section className={classNamesGroups("mb-20", step !== 4 && "d-none")}>
				<div className="text-center">
					<div className="col-12">
						<h3 className="fw-bolder">Confirme no seu WhatsApp para prosseguir</h3>
					</div>

					<div className="col-12 mt-7">
						<p className="text-muted h3 fw-normal">
							Uma mensagem de texto com um código de verificação <br /> foi enviada
							para o contato {formik.values.phone}.
						</p>
					</div>

					<div className="col-12 mt-7 d-flex flex-center">
						<CodeInput onCodeChange={setCode} />
					</div>
				</div>
			</section>

			<section className={classNamesGroups("mt-10", step !== 6 && "d-none")}>
				<div className="col-xl-8 mx-auto text-center">
					<h3 className="fw-bolder text-success">Conta Criada com sucesso.</h3>
					<p className="mt-4 h4 fw-600">
						A versão demonstrativa do sistema está disponível.
					</p>
					<NextButton
						type="button"
						text="Ok"
						iconNone
						className="col-4 m-auto mt-10"
						onClick={() => navigate("/auth")}
					/>
				</div>
			</section>

			<div className="col-12 mt-10 d-flex flex-stack">
				{step < 6 && (
					<>
						<NextButton
							type="button"
							text="Voltar"
							previous
							disabled={isPreviousButtonDisabled}
							onClick={handleGoToPreviousStep}
						/>
						<div className="d-flex flex-stack gap-4">
							{hasStepsWithDescription && (
								<span className="registration-subtitle fs-8">{getSubtitle()}</span>
							)}
							<NextButton
								type="button"
								text={step <= 4 ? "Próximo" : "Concluir"}
								form="registration-form"
								disabled={
									isNextButtonDisabled || formik.errors.identifier ? true : false
								}
								onClick={handleGoToNextStep}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}

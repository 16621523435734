import { useEffect, useState } from "react";

import Card from "react-bootstrap/Card";
import { Accept, useDropzone } from "react-dropzone";
import { BsArrowBarUp, BsFileEarmarkCheck } from "react-icons/bs";

import "./styles.scss";

type Props = {
	inputName: string;
	description: string;
	acceptedFileType: Accept;
	descriptionAcceptedFilesExtension: string;
	handleChange: (file: File) => void;
};

export function CardDropzone({
	inputName,
	description,
	acceptedFileType,
	descriptionAcceptedFilesExtension,
	handleChange,
}: Props) {
	const [files, setFiles] = useState<File[]>([]);
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: acceptedFileType,
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	useEffect(() => {
		const [file] = files;
		handleChange(file);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const [file] = acceptedFiles;
	const borderDropzoneBorderSlug = file ? "success" : "primary";
	const thumbs = files.map((file: any) => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				{Object.keys(acceptedFileType)?.find((fileType) => fileType.includes("image")) ? (
					<img
						className="img-preview"
						src={file.preview}
						alt="img preview"
						onLoad={() => {
							URL.revokeObjectURL(file.preview);
						}}
					/>
				) : Object.keys(acceptedFileType)?.find((fileType) =>
						fileType.includes("video")
				  ) ? (
					<video
						className="responsive"
						width="240"
						height="200"
						controls
						src={file.preview}
						onLoad={() => {
							URL.revokeObjectURL(file.preview);
						}}
					/>
				) : null}
			</div>
		</div>
	));

	return (
		<Card>
			<Card.Body className="card-inputs mb-4">
				<div className="fv-row">
					<div
						{...getRootProps({
							className: `dropzone border-${borderDropzoneBorderSlug}`,
						})}
					>
						<input
							type="file"
							name={inputName}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(event.currentTarget.files?.item(0)!)
							}
							{...getInputProps({ className: "d-none" })}
						/>
						<div className="dz-message needsclick d-flex align-items-center justify-content-center">
							{!file ? (
								<BsArrowBarUp className="text-primary fs-3x" />
							) : (
								<BsFileEarmarkCheck className="text-success fs-3x" />
							)}
							<div className="ms-4">
								{!file ? (
									<>
										<h3 className="fs-5 fw-bolder text-gray-900 mb-1">
											{description}
										</h3>
										<span className="fs-7 fw-bold text-gray-400">
											{descriptionAcceptedFilesExtension}
										</span>
									</>
								) : (
									<h3>{file?.name}</h3>
								)}
							</div>
						</div>
					</div>
				</div>
			</Card.Body>
			<Card.Footer className="thumbsContainer">{thumbs}</Card.Footer>
		</Card>
	);
}

import { useEffect } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Yup from "@/utils/mixins/yup";
import { useApi } from "@/hooks/useApi";
import { TrackList } from "@/@types/TrackList";

type Props = ModalProps & {
	selectedTrackList: TrackList;
	fetchTrackLists: () => Promise<void>;
};

export function ModalTrackListEdit({ selectedTrackList, fetchTrackLists, ...rest }: Props) {
	const api = useApi();
	const initialValues = {} as TrackList;

	const validationTrackListSchema = Yup.object().shape({
		id: Yup.number(),
		title: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 100 caracteres!")
			.required("Titulo é Obrigatório!"),
		description: Yup.string()
			.min(2, "Mínimo de 3 caracteres!")
			.max(255, "Máximo de 255 caracteres!")
			.required("Descrição é Obrigatório!"),
	});

	const formik = useFormik<TrackList>({
		initialValues,
		validationSchema: validationTrackListSchema,
		onSubmit: updateTrackList,
	});

	async function updateTrackList(trackList: TrackList) {
		try {
			await api.post("/TrackList/v1/Update", { id: selectedTrackList.id, ...trackList });
			toast.success("atualizado com sucesso.");
			fetchTrackLists();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (selectedTrackList) {
			formik.setValues({ ...selectedTrackList });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTrackList]);

	return (
		<Modal size="lg" scrollable {...rest}>
			<ToastContainer />
			<Modal.Header closeButton>
				<Modal.Title>Edição de playList</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<Form.Group>
						<Form.Label>Título</Form.Label>
						<Form.Control
							type="text"
							name="title"
							defaultValue={selectedTrackList.title}
							onChange={formik.handleChange}
							required
						/>
						{formik.errors.title && (
							<span className="text-danger">{formik.errors.title}</span>
						)}
					</Form.Group>
					<Form.Group>
						<Form.Label>Descrição</Form.Label>
						<Form.Control
							type="text"
							name="description"
							defaultValue={selectedTrackList.description}
							onChange={formik.handleChange}
							as="textarea"
							rows={3}
							required
						/>
						{formik.errors.description && (
							<span className="text-danger">{formik.errors.description}</span>
						)}
					</Form.Group>
					<Form.Group className="d-flex gap-2 justify-content-center mt-3">
						<Button variant="danger" onClick={rest.onHide}>
							Fechar
						</Button>
						<Button type="submit" variant="success">
							Salvar
						</Button>
					</Form.Group>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";

import { AuthLayout } from "../components/AuthLayout";
import { Login } from "../components/Login";
import { Registration } from "../components/Registration";
import { ForgotPassword } from "../components/ForgotPassword";

import "./styles.scss";

export function AuthPage() {
	return (
		<Routes>
			<Route
				element={
					<AuthLayout
						isLogin={true}
						loginFormContainerClassName="flex-center w-md-600px"
						outletClassName="w-md-400px"
					/>
				}
			>
				<Route path="login" element={<Login />} />
				<Route index element={<Login />} />
			</Route>

			<Route
				element={<AuthLayout loginFormContainerClassName="registration-form-container" />}
			>
				<Route path="criar-conta" element={<Registration />} />
			</Route>
			<Route
				element={
					<AuthLayout
						isLogin={false}
						loginFormContainerClassName="password-recovery-form-container"
					/>
				}
			>
				<Route path="esqueci-a-senha" element={<ForgotPassword />} />
			</Route>
			<Route
				element={
					<AuthLayout
						isLogin={false}
						loginFormContainerClassName="password-recovery-form-container"
					/>
				}
			>
				<Route path="esqueci-a-senha" element={<ForgotPassword />} />
			</Route>
		</Routes>
	);
}

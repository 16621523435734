import { Fragment } from "react";

import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import { TbClipboardX } from "react-icons/tb";

import { displayRegisterOrigin } from "@/utils/indicators";
import { PregnantPatient } from "@/@types/PregnantPatient";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator1({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>
								{patientSelected?.fullName
									? patientSelected?.fullName
									: "NÃO INFORMADO"}
							</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">Data de nascimento: </span>
							<span>
								{patientSelected?.birthdate
									? dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DUM: </span>
							<span>
								{patientSelected?.firstAttendanceDate
									? dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DPP: </span>
							<span>
								{patientSelected?.dppDate
									? dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</span>
						</Col>
					</Row>
				</section>
				{patientSelected?.attendances?.length === 0 ? (
					<NoRecordsFeedback
						icon={<TbClipboardX />}
						message="Nenhum atendimento foi encontrado"
					/>
				) : (
					<section className="d-flex flex-column gap-5">
						{patientSelected?.attendances?.map((attendance, index) => (
							<div
								className="bg-light-dark border border-primary rounded p-5"
								key={`patient-attendance-${index}`}
							>
								<Row>
									<Col sm={6} md={6} lg={9} xl={9}>
										<p>
											<span className="fw-bold text-primary">Equipe:</span>
											{` ${attendance?.healthTeam?.ine} | 
											${attendance?.healthTeam?.name}` || "NÃO INFORMADO"}
										</p>
										<p>
											<span className="fw-bold text-primary">IG: </span>
											{attendance?.gestationalAge
												? `${attendance?.gestationalAge} SEMANAS`
												: "--"}
										</p>
										<p>
											<span className="text-primary">Profissional 1: </span>
											<p>
												<span>Nome: </span>
												{attendance?.professional1?.name?.toUpperCase() ||
													"NÃO INFORMADO"}
											</p>
											<p>
												<span>Categoria: </span>
												{attendance.cbo1?.description?.toUpperCase() ||
													"NÃO INFORMADO"}
											</p>
										</p>
									</Col>
									<Col sm={3} md={6} lg={3} xl={3}>
										<Col className="align-items-center gap-2 text-start">
											<span className="fw-bold">Data do registro: </span>
											<Badge bg="primary" className="fs-5">
												{attendance?.date
													? dayjs(attendance?.date).format("DD/MM/YYYY")
													: "NÃO INFORMADO"}
											</Badge>
										</Col>

										<Col className="align-items-center mt-3 gap-2 text-start">
											<span className="fw-bold">Origem: </span>
											<Badge bg="primary" className="fs-5 text-end">
												{displayRegisterOrigin(
													attendance?.registerType?.name
												)}
											</Badge>
										</Col>
									</Col>
								</Row>

								<div>
									<div className="mt-5 fs-5">
										{attendance?.problems.map((problem, index, allProblems) => (
											<Fragment key={`attendance-problem-${index}`}>
												{problem?.cid?.cidCode !== "-" && (
													<>
														<span className="fw-bolder">
															{problem?.cid?.description}
														</span>{" "}
														- {problem?.cid?.cidCode} (CID) |{" "}
													</>
												)}

												{problem?.ciap?.ciapCode !== "-" && (
													<>
														<span className="fw-bolder text-uppercase">
															{problem?.ciap?.description}
														</span>{" "}
														- {problem?.ciap?.ciapCode} (CIAP2)
														{index !== allProblems?.length - 1 && " | "}
													</>
												)}
											</Fragment>
										))}
									</div>
								</div>
							</div>
						))}
					</section>
				)}
			</Modal.Body>
		</Modal>
	);
}

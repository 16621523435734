import { ReactNode, HTMLAttributes, MouseEvent } from "react";
import { FaPrint, FaFileExcel } from "react-icons/fa";
import Popover, { PopoverProps } from "react-bootstrap/Popover";

type OptionProps = HTMLAttributes<HTMLSpanElement> & {
	icon: ReactNode;
	children: ReactNode;
};

function Option({ children, icon, className, ...rest }: OptionProps) {
	return (
		<div>
			<span
				role="button"
				className={`d-flex align-items-center gap-2 fw-bold text-muted ${className}`}
				{...rest}
			>
				{icon}
				<span>{children}</span>
			</span>
		</div>
	);
}

type ClickEvent = (event: MouseEvent<HTMLSpanElement>) => void;

type OptionsPopoverProps = PopoverProps & {
	handlePrintClick: ClickEvent;
	handleExportExcelClick: ClickEvent;
};

export function OptionsPopover({
	handlePrintClick,
	handleExportExcelClick,
	...rest
}: OptionsPopoverProps) {
	return (
		<Popover id="options-popover" {...rest}>
			<Popover.Body className="px-0">
				<Option
					className="px-2"
					icon={<FaPrint className="fs-4" />}
					onClick={handlePrintClick}
				>
					Imprimir página
				</Option>
				<hr />
				<Option
					className="px-2"
					icon={<FaFileExcel className="fs-4" />}
					onClick={handleExportExcelClick}
				>
					Exportar excel
				</Option>
			</Popover.Body>
		</Popover>
	);
}

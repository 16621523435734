import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

export type Indicators = {
	indicator1: IndicatorDetails;
	indicator2: IndicatorDetails;
	indicator3: IndicatorDetails;
	indicator4: IndicatorDetails;
	indicator5: IndicatorDetails;
	indicator6: IndicatorDetails;
	indicator7: IndicatorDetails;
};

export type IndicatorDetails = {
	id: number;
	title: string;
	description: string;
	stateMeasures: StateMeasure[];
	indicatorTypeDescriptions: IndicatorTypeDescription;
	getChartColor: (indicatorPercentage: number) => string;
};

type IndicatorTypeDescription = {
	pendingIneligible: string;
	valid: string;
	pendingEligible: string;
};

/**
 *minValue >=
 *maxValue <
 */

export type StateMeasure = {
	description: string;
	minValue: number;
	maxValue: number;
	classColor: string;
};

const indicatorTitles = [
	"INDICADOR 1",
	"INDICADOR 2",
	"INDICADOR 3",
	"INDICADOR 4",
	"INDICADOR 5",
	"INDICADOR 6",
	"INDICADOR 7",
];

const indicatorDescriptions = [
	"Gestantes com pelo menos 6 (seis) consultas pré-natal realizadas, sendo a primeira até a 12ª semana de gestação",
	"Gestantes com a realização de exame para sífilis e HIV",
	"Gestantes com atendimento odontológico realizado",
	"Cobertura de exames citopatológicos",
	"Cobertura vacinal de poliomelite e pentavalente",
	"Percentual de pessoas hipertensas com consulta e pressão arterial aferida em cada semestre",
	"Percentual de diabéticos com solicitação de hemogoblina glicada",
];

export const descriptions = indicatorDescriptions;

const stateMeasures: StateMeasure[][] = [
	[
		{
			description: "< 16%",
			minValue: 0,
			maxValue: 16,
			classColor: "danger",
		},
		{
			description: "≥ 16% e < 28%",
			minValue: 16,
			maxValue: 28,
			classColor: "warning",
		},
		{
			description: "≥ 28% e < 45%",
			minValue: 28,
			maxValue: 45,
			classColor: "success",
		},
		{
			description: "≥ 45% e ≤ 100%",
			minValue: 45,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 24%",
			minValue: 0,
			maxValue: 24,
			classColor: "danger",
		},
		{
			description: "≥ 24% e < 42%",
			minValue: 24,
			maxValue: 42,
			classColor: "warning",
		},
		{
			description: "≥ 42% e < 60%",
			minValue: 42,
			maxValue: 60,
			classColor: "success",
		},
		{
			description: "≥ 60% e ≤ 100%",
			minValue: 60,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 24%",
			minValue: 0,
			maxValue: 24,
			classColor: "danger",
		},
		{
			description: "≥ 24% e < 42%",
			minValue: 24,
			maxValue: 42,
			classColor: "warning",
		},
		{
			description: "≥ 42% e < 60%",
			minValue: 42,
			maxValue: 60,
			classColor: "success",
		},
		{
			description: "≥ 60% e ≤ 100%",
			minValue: 60,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 16%",
			minValue: 0,
			maxValue: 16,
			classColor: "danger",
		},
		{
			description: "≥ 16% e < 28%",
			minValue: 16,
			maxValue: 28,
			classColor: "warning",
		},
		{
			description: "≥ 28% e < 40%",
			minValue: 28,
			maxValue: 40,
			classColor: "success",
		},
		{
			description: "≥ 40% e ≤ 100%",
			minValue: 40,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 38%",
			minValue: 0,
			maxValue: 38,
			classColor: "danger",
		},
		{
			description: "≥ 38% e < 66.5%",
			minValue: 38,
			maxValue: 66.5,
			classColor: "warning",
		},
		{
			description: "≥ 66.5% e < 95%",
			minValue: 66.5,
			maxValue: 95,
			classColor: "success",
		},
		{
			description: "≥ 95% e ≤ 100%",
			minValue: 95,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 20%",
			minValue: 0,
			maxValue: 20,
			classColor: "danger",
		},
		{
			description: "≥ 20% e < 35%",
			minValue: 20,
			maxValue: 35,
			classColor: "warning",
		},
		{
			description: "≥ 35% e < 50%",
			minValue: 35,
			maxValue: 50,
			classColor: "success",
		},
		{
			description: "≥ 50% e ≤ 100%",
			minValue: 50,
			maxValue: 100,
			classColor: "primary",
		},
	],
	[
		{
			description: "< 20%",
			minValue: 0,
			maxValue: 20,
			classColor: "danger",
		},
		{
			description: "≥ 20% e < 35%",
			minValue: 20,
			maxValue: 35,
			classColor: "warning",
		},
		{
			description: "≥ 35% e < 50%",
			minValue: 35,
			maxValue: 50,
			classColor: "success",
		},
		{
			description: "≥ 50% e ≤ 100%",
			minValue: 50,
			maxValue: 100,
			classColor: "primary",
		},
	],
];

const indicatorsTypeDescriptions: IndicatorTypeDescription[] = [
	{
		pendingIneligible:
			"GESTANTES QUE NÃO FIZERAM A PRIMEIRA CONSULTA ANTES DA 12ª SEMANA DE GESTAÇÃO OU JÁ CONCLUIRAM A GESTAÇÃOE E NÃO FIZERAM AS 6 CONSULTAS",
		valid: "GESTANTES QUE ESTÃO COM PELO MENOS 6 (SEIS) CONSULTAS DE PRÉ-NATAL REALIZADAS, SENDO A PRIMEIRA ATÉ A 12ª SEMANA DE GESTAÇÃO",
		pendingEligible:
			"GESTANTES QUE NÃO ESTÃO COM PELO MENOS 6 (SEIS) CONSULTAS DE PRÉ-NATAL REALIZADAS, SENDO A PRIMEIRA ATÉ A 12ª SEMANA DE GESTAÇÃO",
	},
	{
		pendingIneligible:
			"GESTANTES QUE NÃO FIZERAM OS EXAMES DE SÍFILIS E HIV DURANTE O PERÍODO DE GESTAÇÃO",
		valid: "GESTANTES COM REALIZAÇÃO DE EXAMES PARA SÍFILIS E HIV",
		pendingEligible: "GESTANTES QUE NÃO REALIZARAM EXAMES PARA SÍFILIS E HIV",
	},
	{
		pendingIneligible:
			"GESTANTES QUE NÃO TIVERAM ATENDIMENTOS ODONTOLÓGICOS DURANTE O PERIODO DE GESTAÇÃO",
		valid: "GESTANTES COM ATENDIMENTO ODONTOLÓGICO REALIZADO",
		pendingEligible: "GESTANTES QUE NÃO REALIZARAM ATENDIMENTO ODONTOLÓGICO",
	},
	{
		pendingIneligible:
			"MULHERES QUE COMPLETARAM 64 ANOS E NÃO FIZERAM O EXAME CITOPATOLÓGICO NOS ÚLTIMOS 3 ANOS",
		valid: "MULHERES QUE CONTÊM EXAMES CITOPATOLÓGICOS",
		pendingEligible: "MULHERES QUE NÃO CONTÊM EXAMES CITOPATOLÓGICOS",
	},
	{
		pendingIneligible:
			"CRIANÇAS QUE COMPLETARAM 1 ANO DE IDADE E NÃO COMPLETARAM A COBERTURA VACINAL",
		valid: "CRIANÇAS COM COBERTURA VACINAL DE POLIOMELITE E PENTAVALENTE",
		pendingEligible: "CRIANÇAS SEM COBERTURA VACINAL DE POLIOMELITE E PENTAVALENTE",
	},
	{
		pendingIneligible: "A CLASSIFICAÇÃO PERDIDOS NÃO SE APLICA NESTE INDICADOR",
		valid: "HIPERTENSOS COM CONSULTA E PRESSÃO ARTERIAL AFERIDA EM CADA SEMESTRE",
		pendingEligible: "HIPERTENSOS SEM CONSULTA OU PRESSÃO ARTERIAL AFERIDA EM CADA SEMESTRE",
	},
	{
		pendingIneligible: "A CLASSIFICAÇÃO PERDIDOS NÃO SE APLICA NESTE INDICADOR",
		valid: "DIABÉTICOS COM CONSULTA E SOLICITAÇÃO DE HEMOGLOBINA GLICADA",
		pendingEligible: "DIABÉTICOS SEM CONSULTA OU SOLICITAÇÃO DE HEMOGLOBINA GLICADA",
	},
];

const indicatorsDefault: IndicatorDetails[] = indicatorTitles.map<IndicatorDetails>(
	(indicatorTitle, index) => ({
		id: index + 1,
		title: indicatorTitle,
		description: indicatorDescriptions[index],
		stateMeasures: stateMeasures[index],
		indicatorTypeDescriptions: indicatorsTypeDescriptions[index],
		getChartColor: (indicatorPercentage: number) =>
			getIndicatorColor(stateMeasures[index], indicatorPercentage),
	})
);

export function getIndicatorColor(stateMeasures: StateMeasure[], percentage: number) {
	return (
		stateMeasures?.find(
			(stateMeasure) =>
				stateMeasure.minValue <= percentage &&
				(stateMeasure.maxValue < 100 ? stateMeasure.maxValue > percentage : percentage)
		)?.classColor || "secondary"
	);
}

export const indicatorsDetail = {
	indicator1: indicatorsDefault[0],
	indicator2: indicatorsDefault[1],
	indicator3: indicatorsDefault[2],
	indicator4: indicatorsDefault[3],
	indicator5: indicatorsDefault[4],
	indicator6: indicatorsDefault[5],
	indicator7: indicatorsDefault[6],
};

export function displayFirstDumDate(date: string) {
	dayjs.extend(isSameOrAfter);

	if (!date || dayjs(date).isSameOrAfter("3000-12-31")) {
		return "NÃO INFORMADO";
	} else {
		return dayjs(date).format("DD/MM/YYYY");
	}
}

export function displayRegisterOrigin(registerName: string) {
	if (registerName === "CDS Ficha de Atendimento Individual") {
		return "CDS";
	} else if (registerName === "ESUS PEC Atendimento") {
		return "PEC";
	}

	return registerName || "NÃO INFORMADO";
}

import { MutableRefObject } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { v4 as uuidv4 } from "uuid";

import { GenericObject } from "@/@types/genericals";

export function exportExcel(data: GenericObject[], fileName?: string) {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = "xlsx";

	const worksheet = XLSX.utils.json_to_sheet(data);
	const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

	const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
	const file = new Blob([excelBuffer], { type: fileType });

	FileSaver.saveAs(file, `${fileName || uuidv4()}.${fileExtension}`);
}

export function extractTable(tableRef: MutableRefObject<HTMLTableElement | null>) {
	const table = tableRef.current;

	const tableHeaders = Array.from(table?.querySelectorAll("thead th") || []).map((thead) => {
		if (!thead.classList.contains("no-excel")) {
			return thead.textContent;
		}

		return "";
	});

	const data = Array.from(table?.querySelectorAll("tbody tr") || []).map((row) => {
		const rowData = {} as GenericObject;

		row.querySelectorAll("td").forEach((cell, index) => {
			if (!cell.classList.contains("no-excel")) {
				const key = tableHeaders[index] as string;

				rowData[key] = (cell.dataset.content || cell.textContent)?.toUpperCase();
			}
		});

		return rowData;
	});

	return data;
}

import Select, { Props as SelectProps } from "react-select";

import makeAnimated from "react-select/animated";
import { FederalUnit } from "@/@types/FederalUnit";

type Props = SelectProps & {
	federalUnits: FederalUnit[];
};

export function FederalUnitsSelect({ federalUnits, ...rest }: Props) {
	const animatedComponents = makeAnimated();
	const options = [
		...federalUnits?.map((federalUnit) => ({
			value: federalUnit.abbreviation,
			label: federalUnit.abbreviation || "Todos os estados",
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label title="Lista de Estados Disponiveis" className="form-label">
				UF:
			</label>
			<Select
				options={options}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma UF encontrada"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				{...rest}
			/>
		</div>
	);
}

import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { Professional } from "@/@types/Professional";

type Props = SelectProps & {
	professionals: Professional[];
};

const animatedComponents = makeAnimated();

export const defaultProfessional = { value: "", label: "Todos os profissionais" };

export function ProfessionalsSelect({ professionals, ...rest }: Props) {
	const options = [
		defaultProfessional,
		...professionals?.map((professional) => ({
			value: professional.cns,
			label: professional.name,
		})),
	];

	return (
		<>
			<label htmlFor={rest.id} className="form-label">
				Profissionais:
			</label>
			<Select
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhum profissional encontrado"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				{...rest}
			/>
		</>
	);
}

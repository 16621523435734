import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type Props = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
	height?: number;
};

export function TripleViralDosagesAppliedChart({ series, categories, height }: Props) {
	const tripleViralAreaChartOptions: ApexOptions = {
		series: series,
		chart: {
			redrawOnWindowResize: true,
			type: "bar",
			foreColor: "black",
		},
		dataLabels: {
			enabled: true,
		},
		stroke: {
			curve: "smooth",
		},
		plotOptions: {
			bar: {
				horizontal: true,
			},
		},
		xaxis: {
			categories: categories,
		},
		yaxis: {
			opposite: false,
		},
	};

	return (
		<ReactApexChart
			series={tripleViralAreaChartOptions.series}
			options={tripleViralAreaChartOptions}
			type="bar"
			height={height || 600}
		/>
	);
}

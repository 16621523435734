import dayjs from "dayjs";

import { TbFileReport } from "react-icons/tb";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PregnantPatient } from "@/@types/PregnantPatient";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator3({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">DN: </span>
							<span>{dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DUM: </span>
							<span>{dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DPP: </span>
							<span>{dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")}</span>
						</Col>
					</Row>
				</section>
				<section className="d-flex flex-column gap-5">
					{patientSelected.dentalAttendances &&
					patientSelected.dentalAttendances?.length !== 0 ? (
						patientSelected.dentalAttendances?.map((attendance, index) => (
							<div
								key={`dental-attendance-${index}`}
								className="bg-light-dark border border-primary rounded p-5"
							>
								<Row className="d-flex align-items-start justify-content-between gap-5 attendance-header">
									<Col
										sm={12}
										md={8}
										lg={8}
										xl={8}
										className="attendance-info-container"
									>
										<section>
											<article>
												<p>
													<strong className="text-primary me-2">
														Equipe:
													</strong>
													{`${patientSelected.healthTeam?.ine || ""} | ${
														patientSelected.healthTeam?.name ||
														"Não informado"
													}`}
												</p>
											</article>

											<article>
												<strong className="text-primary">
													1º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.professional1?.name ||
														"Não informado"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.cbo1?.description ||
														"Não informado"}
												</p>
											</article>

											<article>
												<strong className="text-primary">
													2º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.professional2?.name ||
														"Não informado"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.cbo2?.description ||
														"Não informado"}
												</p>
											</article>
										</section>
									</Col>
									<Col sm={12} md={12} lg={3} xl={3}>
										<div className="d-flex align-items-center">
											<span>Data do registro: </span>
											<span className="ms-2 text-white text-center bg-primary p-2 rounded w-10">
												{dayjs(attendance.date).format("DD/MM/YYYY")}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						))
					) : (
						<NoRecordsFeedback
							icon={<TbFileReport />}
							message="Nenhum atendimento foi encontrado"
						/>
					)}
				</section>
			</Modal.Body>
		</Modal>
	);
}

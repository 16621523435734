import ReactPlayer from "react-player";

import { BaseReactPlayerProps } from "react-player/base";

export function Video({ ...rest }: BaseReactPlayerProps) {
	return (
		<div>
			<section className="player w-100">
				<ReactPlayer
					height={"90%"}
					width={"100%"}
					playing={false}
					controls={true}
					config={{
						file: {
							attributes: {
								controlsList: "nodownload",
								onContextMenu: (e: any) => e.preventDefault(),
							},
						},
					}}
					{...rest}
				/>
			</section>
		</div>
	);
}

import dayjs from "dayjs";

export function calculateDelayedVaccinationSearchActive(
	appointedDays?: number,
	vaccinationDays?: number
) {
	if (appointedDays && vaccinationDays) {
		return dayjs()
			.subtract(vaccinationDays, "days")
			.add(appointedDays, "days")
			.format("DD/MM/YYYY");
	}

	return "--/--/----";
}

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export type BarChartProps = {
	width?: number;
	height?: number;
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
	categories?: string[];
	apexOptions?: ApexOptions;
	isVertical?: boolean;
	colors?: string[];
	dataLabelsColors?: string[];
	isStacked?: boolean;
	toolbar?: boolean;
	zoom?: boolean;
	paddingTop?: number;
	paddingleft?: number;
	paddingRight?: number;
	paddingBottom?: number;
	yaxis?: number;
};

export function BarChart({
	paddingBottom,
	paddingRight,
	paddingTop,
	paddingleft,
	isStacked,
	colors,
	dataLabelsColors,
	categories,
	series,
	height,
	width,
	apexOptions,
	isVertical,
	yaxis,
}: BarChartProps) {
	const barChartOption: ApexOptions = apexOptions || {
		chart: {
			type: "bar",
			stacked: isStacked,
			toolbar: {
				show: true,
			},
			zoom: {
				enabled: true,
			},
		},
		grid: {
			padding: {
				top: paddingTop ?? 10,
				right: paddingRight ?? 50,
				bottom: paddingBottom ?? 10,
				left: paddingleft ?? 10,
			},
		},
		colors: colors && colors,
		plotOptions: {
			bar: {
				horizontal: !isVertical,
				borderRadius: 10,
				borderRadiusApplication: "end",
				borderRadiusWhenStacked: "last",
				barHeight: "70%",
				dataLabels: {
					total: {
						enabled: false,
						style: {
							fontSize: "13px",
							fontWeight: 900,
						},
					},
				},
			},
		},
		dataLabels: {
			style: {
				colors: dataLabelsColors ?? ["#333"],
			},
			textAnchor: "middle",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: yaxis ?? 200 },
		},
		xaxis: {
			type: "category",
			categories: categories,
		},
		legend: {
			position: "top",
		},
		fill: {
			opacity: 1,
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: !isVertical,
						},
					},
					chart: {
						height: height || 800,
						width: width || 400,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: true,
						labels: { align: "left", maxWidth: 100, style: { fontSize: "14px" } },
					},
					xaxis: {
						labels: {
							show: true,
						},
					},
				} as ApexOptions,
			},
		],
	};

	return (
		<ReactApexChart
			options={barChartOption}
			series={series}
			type="bar"
			height={height || 350}
			width={width}
		/>
	);
}

import { MutableRefObject } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { Paginated } from "@/@types/paginated";
import { PregnantPatient } from "@/@types/PregnantPatient";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";

type Props = {
	tableRef: MutableRefObject<HTMLTableElement | null>;
	pregnantPatient: Paginated<PregnantPatient>;
};

export function TableIndicator3ExportPdf({ tableRef, pregnantPatient }: Props) {
	return (
		<Table responsive className="table-rounded print-container border gy-7 gs-3" ref={tableRef}>
			<thead>
				<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
					<th>
						<CitizenSyncLabel />
					</th>
					<th>CIDADÃO</th>
					<th className="white-space-nowrap">DN</th>
					<th>CPF/CNS</th>
					<th>EQUIPE</th>
					<th>MC</th>
					<th>DUM</th>
					<th className="white-space-nowrap">1° ATENDIMENTO</th>
					<th className="white-space-nowrap">IG 1° CONSULTA</th>
					<th>DPP</th>
				</tr>
			</thead>
			<tbody>
				{pregnantPatient?.data?.map((patient, index) => (
					<tr key={`patient-pregnant-${index}`}>
						<td title="Sincronização">
							<PremilinaryReportStatusIcon status={patient.preliminaryReportStatus} />
						</td>
						<td title="Nome completo" className="text-nowrap">
							{patient.fullName || "NÃO INFORMADO"}
						</td>
						<td className="text-nowrap">
							{patient.birthdate
								? dayjs(patient.birthdate).format("DD/MM/YYYY")
								: "NÃO INFORMADO"}
						</td>
						<td title="CPF/CNS" className="text-nowrap">
							{identifierOrNationalHealthCard(patient) || "NÃO INFORMADO"}
						</td>
						<td title="Equipe" className="text-nowrap">
							{patient.healthTeam?.name || "NÃO INFORMADO"}
						</td>
						<td title="Micro Área" className="text-nowrap text-center">
							{patient.area || "NÃO INFORMADO"}
						</td>
						<td title="Data da última menstruação (DUM)" className="text-nowrap">
							{displayFirstDumDate(patient.firstDumDate) || "NÃO INFORMADO"}
						</td>
						<td
							title="Data do primeiro atendimento odontológico"
							className="text-nowrap"
						>
							{patient.dentalAttendances && patient.dentalAttendances[0]?.date
								? dayjs(patient.dentalAttendances[0]?.date).format("DD/MM/YYYY")
								: "NÃO INFORMADO"}
						</td>
						<td
							title="Idade gestacional na primeira consulta (IG)"
							className="text-nowrap"
						>
							{patient.gestationalAge1St
								? displaySingularOrPlural(patient.gestationalAge1St, "semana")
								: "NÃO INFORMADO"}
						</td>
						<td title="Data provável do parto (DPP)" className="text-nowrap">
							{dayjs(patient.dppDate).format("DD/MM/YYYY") || "NÃO INFORMADO"}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import axios from "axios";
import dayjs from "dayjs";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FaUser } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useUnits } from "@/hooks/useUnits";
import { useDebounce } from "@/hooks/useDebounce";
import { useProfessionals } from "@/hooks/useProfessionals";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { exportExcel } from "@/utils/exports";
import { GenericObject, SelectOptionsProps } from "@/@types/genericals";
import { Paginated } from "@/@types/paginated";
import { ExportTableType } from "@/@types/ExportTableType";

import { ManagedCitizensModal } from "@/components/ManagedCitizensModal";
import { RegistrationManagementTable } from "@/components/RegistrationManagementTable";
import { ModalCitizensDuplicates } from "@/components/ModalCitizensDuplicates";
import { UnitsSelect, defaultUnit } from "@/components/UnitsSelect";
import { ProfessionalsSelect, defaultProfessional } from "@/components/ProfessionalsSelect";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NumberOfRecords } from "@/components/NumberOfRecords";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { OptionsPopover } from "@/components/Popovers/OptionsPopover";
import { LoadingScreen } from "@/components/LoadingScreen";
import { SearchInput } from "@/components/SearchInput";

import "./styles.scss";

const animatedComponents = makeAnimated();

export type ManagedCitizen = {
	area: string;
	birthDate: string;
	city: string;
	cns: string;
	cpf: string;
	familyGuardianName: string;
	fatherName: string;
	gender: number;
	healthTeam: string;
	healthUnit: {
		cnes: string;
		name: string;
	};
	motherName: string;
	name: string;
	nis: string | null;
	professional: string;
	professionalCns: string;
	registerDate: string;
	registerType: string;
};

export type DuplicatedCitizen = {
	area: string;
	birthDate: string;
	city: string;
	cns: string;
	cpf: string;
	familyGuardianName: string;
	fatherName: string;
	gender: number;
	healthTeam: string;
	healthUnit: {
		cnes: string;
		name: string;
	};
	motherName: string;
	name: string;
	nis: string | null;
	professional: string;
	professionalCns: string;
	registerDate: string;
	registerType: string;
};

export type CitizenReport = {
	area: string;
	birthDate: string;
	cpf: string;
	name: string;
	pendingVisit: string;
	professional: string;
	registerDate: string;
};

function RegistrationManagement() {
	const api = useApi();
	const { CancelToken } = axios;
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { setTitle } = useLayout();
	const registrationSourceCancel = CancelToken.source();
	const [searchCitizen, setSearchCitizen] = useState("");
	const debouncedSearchCitizen = useDebounce(searchCitizen, 1500);
	const printRef = useRef<HTMLDivElement | null>(null);
	const [managedCitizens, setManagedCitizens] = useState<Paginated<ManagedCitizen>>(
		{} as Paginated<ManagedCitizen>
	);
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const [selectedUnit, setSelectedUnit] = useState<SelectOptionsProps>(defaultUnit);
	const { units } = useUnits();
	const hasSelectedUnit = selectedUnit.value !== "0";

	const [selectedProfessional, setSelectedProfessional] =
		useState<SelectOptionsProps>(defaultProfessional);
	const { professionals } = useProfessionals();

	const hasSelectedProfessional = selectedProfessional.value !== "0";

	const [isLoadingExport, setIsLoadingExport] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedCitizen, setSelectedCitizen] = useState<ManagedCitizen>({} as ManagedCitizen);

	const printTable = useReactToPrint({
		content: () => printRef.current,
	});

	const conditionFilterSelectOptions = [
		{ value: "0", label: "Todos os cidadãos" },
		{ value: "1", label: "Cidadãos sem CPF" },
		{ value: "2", label: "Cidadãos sem CNS" },
		{ value: "3", label: "Cidadãos sem NIS" },
		{ value: "4", label: "Cidadãos fora de área" },
		{ value: "5", label: "Cidadãos possivelmente duplicados" },
	];
	const [selectedFilter, setSelectedFilter] = useState<SelectOptionsProps>(
		conditionFilterSelectOptions[0]
	);
	const isDuplicateFilter = selectedFilter.value === "5";

	async function fetchCitizens(pageSize?: number) {
		try {
			const { data } = await api.get<Paginated<ManagedCitizen>>(
				"/registrationmanagement/v1/getcitizensbyfilter",
				{
					cancelToken: registrationSourceCancel.token,
					params: {
						uf,
						ibgeCode,
						pageNumber,
						pageSize: pageSize || 10,
						searchFullName: searchCitizen,
						queryFilter: selectedFilter.value,
						...(hasSelectedUnit && { codCnes: selectedUnit.value || cnes }),
						...(hasSelectedProfessional && {
							professional: selectedProfessional.value,
						}),
					},
				}
			);
			return data || ({} as Paginated<ManagedCitizen>);
		} catch (error) {
			console.error(error);
			return {} as Paginated<ManagedCitizen>;
		}
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	function handleChangePageSize(event: ChangeEvent<HTMLSelectElement>) {
		setPageSize(Number(event.target.value));
	}

	function handleDefineSelectedCitizen(citizen: ManagedCitizen) {
		setSelectedCitizen(citizen);
		if (isDuplicateFilter) {
			handleShowModalDuplicated();
		} else {
			handleShowModal();
		}
	}

	function transformManagedCitizenToExportExcel(managedCitizens: ManagedCitizen[]) {
		return managedCitizens.map(
			(managedCitizen) =>
				({
					Nome: managedCitizen.name,
					"Data de Nascimento": managedCitizen.birthDate
						? dayjs(managedCitizen.birthDate).format("DD/MM/YYYY")
						: "Não informado",
					"Cpf/Cns": managedCitizen.cpf || managedCitizen.cns,
					"Micro área": managedCitizen.area,
					"Profissional Acs": managedCitizen.professional,
					NIS: managedCitizen.nis,
					"Data de cadastro": managedCitizen.registerDate
						? dayjs(managedCitizen.registerDate).format("DD/MM/YYYY")
						: "Não informado",
					Status:
						dayjs().diff(dayjs(managedCitizen.registerDate), "month") > 12
							? "Desatualizado"
							: "Atualizado",
				} as GenericObject)
		);
	}

	async function handleExportTable(exportTableType: ExportTableType) {
		if (exportTableType === "print") {
			printTable();
		} else if (exportTableType === "excel") {
			setIsLoadingExport(true);
			const response = await fetchCitizens(managedCitizens?.totalRecords);
			setIsLoadingExport(false);
			if (Object.keys(response.data || []).length) {
				const excelData: GenericObject[] = transformManagedCitizenToExportExcel(
					response?.data
				);
				exportExcel(excelData, `Tabela de Cidadãos`);
			}
		}
	}

	async function fetch() {
		setIsLoading(true);
		setManagedCitizens(await fetchCitizens());
		setIsLoading(false);
	}

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);

			return;
		}
		fetch();

		return () => {
			if (registrationSourceCancel) {
				registrationSourceCancel.cancel("Request canceled because change filters.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUnit, selectedFilter, selectedProfessional, pageSize, debouncedSearchCitizen]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetch();
		}
		return () => {
			if (registrationSourceCancel) {
				registrationSourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("GESTÃO DE CIDADÃOS");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const [showModalDuplicated, setShowModalDuplicated] = useState(false);
	const handleCloseModalDuplicated = () => setShowModalDuplicated(false);
	const handleShowModalDuplicated = () => setShowModalDuplicated(true);

	return (
		<div className="p-2">
			<LoadingScreen loading={isLoadingExport} />
			<Breadcrumbs initialItem="Gestão de Cidadãos" initialLink="/cadastros" />

			<ManagedCitizensModal
				citizen={selectedCitizen}
				show={showModal}
				onHide={handleCloseModal}
			/>

			<ModalCitizensDuplicates
				isDuplicatedFilter={isDuplicateFilter}
				uf={uf}
				ibgeCode={ibgeCode}
				hasSelectedUnit={hasSelectedUnit}
				selectedUnit={selectedUnit.value}
				hasSelectedProfessional={hasSelectedProfessional}
				selectedProfessional={selectedProfessional.value}
				citizenDuplicated={selectedCitizen}
				selectedCitizen={selectedCitizen}
				show={showModalDuplicated}
				onHide={handleCloseModalDuplicated}
			/>

			<div className="row g-5 g-xl-8 pb-5">
				<div className="col-xl-12">
					<Card>
						<Card.Body>
							<div className="row filters-registration-management">
								<div className="col-lg-4 col-md-12">
									<label
										htmlFor="conditions-filter-select"
										className="form-label"
									>
										Selecione um filtro:
									</label>
									<Select
										options={conditionFilterSelectOptions}
										components={animatedComponents}
										placeholder="Selecione um filtro"
										id="conditions-filter-select"
										value={selectedFilter}
										onChange={(newValue) => {
											setSelectedFilter(newValue as SelectOptionsProps);
										}}
									/>
								</div>
								{!cnes && (
									<div className="col-lg-4 col-md-12">
										<UnitsSelect
											id="units-select"
											units={units}
											value={selectedUnit}
											onChange={(newValue) =>
												setSelectedUnit(newValue as SelectOptionsProps)
											}
										/>
									</div>
								)}

								<div className="col-lg-4 col-md-12">
									<ProfessionalsSelect
										id="professionals-select"
										professionals={professionals}
										value={selectedProfessional}
										onChange={(newValue) =>
											setSelectedProfessional(newValue as SelectOptionsProps)
										}
									/>
								</div>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-xl-12">
					<Card>
						<Card.Body>
							<section>
								<Row className="d-flex align-items-center justify-content-between w-100">
									<Col xs={0} sm={4} md={3} lg={4} xl={3} className="my-2">
										<NumberOfRecords
											onChange={handleChangePageSize}
											value={pageSize}
										/>
									</Col>
									<Col xs={9} sm={6} md={6} lg={6} xl={6} className="my-2">
										<SearchInput
											placeholder="Pesquisar nome"
											value={searchCitizen}
											setValue={setSearchCitizen}
										/>
									</Col>
									<Col
										xs={3}
										sm={2}
										md={3}
										lg={2}
										xl={3}
										className="d-flex my-2 justify-content-end"
									>
										<OverlayTrigger
											trigger="click"
											placement="bottom"
											overlay={(props) =>
												OptionsPopover({
													handlePrintClick: () =>
														handleExportTable("print"),
													handleExportExcelClick: () =>
														handleExportTable("excel"),
													...props,
												})
											}
											rootClose
										>
											<Button
												variant="secondary"
												className="px-2"
												disabled={isLoading}
											>
												Opções
											</Button>
										</OverlayTrigger>
									</Col>
								</Row>
							</section>

							{isLoading ? (
								<div className="d-flex flex-center" style={{ minHeight: "50vh" }}>
									<Spinner animation="border" variant="primary" />
								</div>
							) : managedCitizens.data?.length > 0 ? (
								<div ref={printRef}>
									<div className="print-container">
										<h3 className="text-center py-10 px-5">
											TABELA DE CIDADÃOS
										</h3>
										<Row>
											<span>
												<strong>Filtro:</strong>{" "}
												{selectedFilter.value
													? selectedFilter.label
													: "Todos"}
											</span>
											<span>
												<strong>Unidade:</strong>{" "}
												{hasSelectedUnit ? selectedUnit.label : "Todas"}
											</span>
											<span>
												<strong>Profissionais:</strong>{" "}
												{hasSelectedProfessional
													? selectedProfessional.label
													: "Todos"}
											</span>
										</Row>
									</div>
									<RegistrationManagementTable
										isDuplicateFilter={isDuplicateFilter}
										citizens={managedCitizens}
										handleDefineSelectedCitizen={handleDefineSelectedCitizen}
									/>
								</div>
							) : (
								<NoRecordsFeedback
									icon={<FaUser />}
									message="Nenhum cidadão foi encontrado nesse filtro."
								/>
							)}

							{managedCitizens?.data?.length > 0 && (
								<section>
									<div className="d-flex flex-stack">
										<div className="visibility-hidden">
											Mostrando {1} até {managedCitizens.pageSize} de{" "}
											{managedCitizens.totalRecords} itens
										</div>

										<PaginationLinks
											itemsPerPage={managedCitizens.pageSize}
											totalPages={managedCitizens.totalRecords}
											changeSelectedPage={handleChangePageNumber}
											pageNumber={pageNumber || 0}
										/>
									</div>
								</section>
							)}
						</Card.Body>
					</Card>
				</div>
			</div>
		</div>
	);
}

export function RegistrationManagementWrapper() {
	return (
		<>
			<RegistrationManagement />
		</>
	);
}

import { ReactNode } from "react";

type NoRecordsFeedbackProps = {
	icon: ReactNode;
	message: ReactNode;
};

export function NoRecordsFeedback({ icon, message }: NoRecordsFeedbackProps) {
	return (
		<section className="p-3 text-muted d-flex flex-column gap-3 align-items-center">
			<span className="display-1">{icon}</span>
			<span className="text-center">{message}</span>
		</section>
	);
}

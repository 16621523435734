import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaInfoCircle } from "react-icons/fa";

import { CitizenSyncPopover } from "@/components/Popovers/CitizenSyncPopover";

export function CitizenSyncLabel() {
	return (
		<OverlayTrigger trigger="click" placement="auto" overlay={CitizenSyncPopover} rootClose>
			<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
				SINC.
				<FaInfoCircle />
			</span>
		</OverlayTrigger>
	);
}

import Modal, { ModalProps } from "react-bootstrap/Modal";

type Props = ModalProps & {
	title?: string;
	zipCode?: string;
	city?: {
		id?: number;
		name?: string;
		state?: {
			id?: number;
			name?: string;
		};
	};
	state?: string;
	neighborhood?: string;
	street?: string;
	houseNumber?: string;
};

export function ModalMaps({
	title,
	zipCode,
	neighborhood,
	houseNumber,
	street,
	city,
	...rest
}: Props) {
	const stateReplaced = city?.state?.name
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");

	const cityReplaced = city?.name
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");
	const streetReplaced = street
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");

	const url = `${houseNumber || ""}+${streetReplaced || ""}-${cityReplaced}+${stateReplaced}`;

	return (
		<Modal size="xl" className="h-100" {...rest} style={{ height: "100vh !important" }}>
			<Modal.Header closeButton>
				<Modal.Title>{title || "NÃO INFORMADO"}</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className="d-flex justify-content-center overflow-auto"
				style={{ minHeight: "10rem !important" }}
			>
				{
					<iframe
						title="maps"
						width="100%"
						height="400rem"
						id="gmap_canvas"
						src={`https://maps.google.com/maps?q=${url},&t=&z=13&ie=UTF8&iwloc=&output=embed`}
					/>
				}
			</Modal.Body>
		</Modal>
	);
}

import { useState } from "react";

import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";

import {
	ChronicNotCommunicableDiseases,
	ResumeDiseasesChronicNotTransmissibleOfCities,
} from "./ChronicNotCommunicableDiseases";
import {
	ChronicCommunicableDiseases,
	ResumeDiseasesChronicTransmissibleOfCities,
} from "./ChronicCommunicableDiseases";

type Props = {
	resumeDiseasesChronicTransmissibleOfCities: ResumeDiseasesChronicTransmissibleOfCities[];
	resumeDiseasesChronicNotTransmissibleOfCities: ResumeDiseasesChronicNotTransmissibleOfCities[];
};

export function Diseases({
	resumeDiseasesChronicNotTransmissibleOfCities,
	resumeDiseasesChronicTransmissibleOfCities,
}: Props) {
	const [selectedOptionMenuDiseases, setSelectedOptionMenuDiseases] = useState(1);

	return (
		<Row className="m-2">
			<Card className="p-0">
				<Card.Header>
					<Card.Title>
						Doenças crônicas{" "}
						<label className="ms-3 text-danger fs-9">(Período anual)</label>
					</Card.Title>
					<Nav
						fill
						variant="tabs"
						defaultActiveKey="/dashboard-estadual/"
						className="d-flex align-items-center"
					>
						<Nav.Item
							onClick={() => {
								setSelectedOptionMenuDiseases(1);
							}}
						>
							<Nav.Link
								eventKey="/dashboard-estadual/"
								className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
							>
								Trasmissíveis
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							onClick={() => {
								setSelectedOptionMenuDiseases(2);
							}}
						>
							<Nav.Link
								eventKey="link-2"
								className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
							>
								Não transmissíveis
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Card.Header>
				<Card.Body className="p-0 bg-light">
					{selectedOptionMenuDiseases === 1 ? (
						<ChronicCommunicableDiseases
							resumeDiseasesChronicTransmissibleOfCities={
								resumeDiseasesChronicTransmissibleOfCities
							}
						/>
					) : (
						<ChronicNotCommunicableDiseases
							resumeDiseasesChronicNotTransmissibleOfCities={
								resumeDiseasesChronicNotTransmissibleOfCities
							}
						/>
					)}
				</Card.Body>
			</Card>
		</Row>
	);
}

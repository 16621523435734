import { FormEvent } from "react";
import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { BsSearch } from "react-icons/bs";

type Props = {
	startDate: string;
	endDate: string;
	setStartDate: (startDate: string) => void;
	setEndDate: (endDate: string) => void;
	handleFilter: (event: FormEvent) => void;
};

export function TripleViralReportFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleFilter,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={12} lg={6} className="justify-content-start">
					<div className="d-flex  align-items-center gap-3">
						<Form.Label className="align-items-center fw-bolder">Período</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								defaultValue={startDate}
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								onChange={(event) => setStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="data final"
								defaultValue={endDate}
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</InputGroup>
					</div>
				</Col>
				<Col sm={12} md={6} lg={2} className="justify-content-end">
					<Button
						className="d-flex align-items-center justify-content-center w-100"
						type="submit"
					>
						<BsSearch className="me-2" />
						Consultar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

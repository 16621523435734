import { ReactNode } from "react";

type Props = {
	title: string;
	value: number | string;
	icon?: ReactNode;
	classNameCard?: string;
	classNameValue?: string;
	classNameTitle?: string;
	metaData?: ReactNode;
};

export function CardInformative({
	title,
	value,
	icon,
	classNameCard,
	classNameValue,
	classNameTitle,
	metaData,
}: Props) {
	return (
		<div className={`d-flex flex-column justify-content-between w-100 ${classNameCard}`}>
			<div className="d-flex justify-content-between">
				<div className="justify-content-start">{icon}</div>
				{metaData}
			</div>
			<strong className="d-flex justify-content-center align-items-center">
				<label className={`fs-1 ${classNameValue}`}>
					{value ? value?.toLocaleString() : 0}
				</label>
			</strong>
			<div className="d-flex justify-content-center align-items-end">
				<label className={`fw-bolder mt-3 ${classNameTitle}`}>{title}</label>
			</div>
		</div>
	);
}

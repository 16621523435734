import { useEffect, useState } from "react";

import Nav from "react-bootstrap/Nav";

import { useLayout } from "@/../_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";

import { ActiveVaccinationPregnant } from "./ActiveVaccinationPregnant";
import { ActiveVaccinationChild } from "./ActiveVaccinationChild";
import { ActiveVaccinationTeen } from "./ActiveVaccinationTeen";
import { ActiveVaccinationOlder } from "./ActiveVaccinationOlder";

export function ActiveVaccinationSearch() {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { setTitle } = useLayout();

	const [optionMenu, setOptionMenu] = useState(1);

	function renderPanel() {
		switch (optionMenu) {
			case 1:
				return <ActiveVaccinationChild uf={uf} ibgeCode={ibgeCode} cnes={cnes} />;
			case 2:
				return <ActiveVaccinationTeen uf={uf} ibgeCode={ibgeCode} cnes={cnes} />;
			case 3:
				return <ActiveVaccinationOlder uf={uf} ibgeCode={ibgeCode} cnes={cnes} />;
			case 4:
				return <ActiveVaccinationPregnant uf={uf} ibgeCode={ibgeCode} cnes={cnes} />;
		}
	}

	useEffect(() => {
		setTitle("BUSCA ATIVA VACINAL");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div style={{ minWidth: "320px" }}>
			<section>
				<div className="d-flex align-items-stretch w-full mx-3 p-0 h-60px h-lg-70px overflow-auto mb-lg-0">
					<Nav
						fill
						variant="tabs"
						defaultActiveKey="/busca-ativa-vacinal/"
						className="nav nav-stretch flex-nowrap w-100 h-100"
					>
						<Nav.Item onClick={() => setOptionMenu(1)}>
							<Nav.Link
								eventKey="/busca-ativa-vacinal/"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Crianças
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">0 a 9 Anos</span>
								</div>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={() => setOptionMenu(2)}>
							<Nav.Link
								eventKey="link-2"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Adolescentes
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">10 a 19 Anos</span>
								</div>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={() => setOptionMenu(3)}>
							<Nav.Link
								eventKey="link-4"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Idosos
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">
										60 anos ou mais
									</span>
								</div>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={() => setOptionMenu(4)}>
							<Nav.Link
								eventKey="link-3"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Gestantes
									</span>
								</div>
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</div>
			</section>
			{renderPanel()}
		</div>
	);
}

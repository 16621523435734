export enum RoleEnum {
	SUPER_ADMINISTRADOR = "1",
	ADMIN = "2",
	GESTOR = "3",
	PROFISSIONAL_DE_SAUDE = "4",
}

export enum StateRoleEnum {
	STATE_ADMIN = "5",
}

import { useState, useEffect, createContext, ReactNode } from "react";

import * as auth from "@/modules/auth/redux/AuthRedux";
import { useDispatch } from "react-redux";

import { useApi } from "@/hooks/useApi";
import { Unit } from "./UnitsContext";
import { User } from "@/@types/user";
import { Account } from "@/@types/Account";
import { FederalUnit } from "@/@types/FederalUnit";

type CurrentAccountContextType = {
	currentHealthUnit: Unit;
	currentAccount: Account;
	currentFederalUnit: FederalUnit;
	isLoading: Boolean;
	user: User;
	getCurrentAccount: () => Promise<void>;
	uf: string;
	ibgeCode: string;
	cnes: string;
	permissions: string[];
};

export const CurrentAccountContext = createContext({} as CurrentAccountContextType);

type CurrentAccountContextProviderProps = {
	children: ReactNode;
};

export function CurrentAccountContextProvider({ children }: CurrentAccountContextProviderProps) {
	const dispatch = useDispatch();
	const api = useApi();
	const [currentAccount, setCurrentAccount] = useState<Account>({} as Account);
	const [currentFederalUnit, setCurrentFederalUnit] = useState<FederalUnit>({} as FederalUnit);
	const [currentHealthUnit, setCurrentHealthUnit] = useState<Unit>({} as Unit);
	const [permissions, setPermissions] = useState<string[]>([]);
	const [user, setUser] = useState<User>({} as User);
	const [isLoading, setIsLoading] = useState(true);
	const uf: string = sessionStorage.getItem("uf") || "";
	const ibgeCode: string = sessionStorage.getItem("ibgeCode") || "";
	const cnes: string = sessionStorage.getItem("cnes") || "";

	const logout = () => {
		dispatch(auth.actions.logout());
	};

	async function getCurrentAccount() {
		try {
			const { data } = await api.get("/UserAccount/v1/GetCurrent", {
				params: { uf, ibgeCode, cnes },
			});
			const { userAccount, permissions, currentCityLicense } = data;
			if (userAccount?.federalUnit?.abbreviation) {
				setCurrentFederalUnit(userAccount.federalUnit);
			}
			if (userAccount?.account && currentCityLicense) {
				userAccount.account.currentCityLicense = currentCityLicense;
				setCurrentAccount(userAccount.account);
			}
			setUser(userAccount.user);
			if (userAccount.healthUnit) {
				setCurrentHealthUnit(userAccount.healthUnit);
			}
			if (permissions?.length > 0) {
				setPermissions(permissions.concat(permissions));
			}
		} catch (error) {
			logout();
			console.log(error);
		}
	}

	useEffect(() => {
		async function fetchData() {
			await getCurrentAccount();
			setIsLoading(false);
		}

		fetchData();

		return () => setCurrentAccount({} as Account);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CurrentAccountContext.Provider
			value={{
				currentAccount,
				currentFederalUnit,
				currentHealthUnit,
				isLoading,
				user,
				getCurrentAccount,
				uf,
				ibgeCode,
				cnes,
				permissions,
			}}
		>
			{children}
		</CurrentAccountContext.Provider>
	);
}

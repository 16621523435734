import Button, { ButtonProps } from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";

type ViewCitizenButtonProps = ButtonProps;

export function ViewCitizenButton({ ...rest }: ViewCitizenButtonProps) {
	return (
		<Button
			variant="primary"
			size="sm"
			className="btn-icon"
			title="Visualizar paciente"
			{...rest}
		>
			<i className="fs-5">
				<FaEye />
			</i>
		</Button>
	);
}

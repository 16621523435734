import dayjs from "dayjs";

import { FaInfoCircle } from "react-icons/fa";
import { AiFillWarning } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { MicroareaPopover } from "@/components/Popovers/MicroareaPopover";
import { StatusPopover } from "@/components/Popovers/StatusPopover";
import { ViewCitizenButton } from "../ViewCitizenButton";
import { ManagedCitizen } from "@/pages/RegistrationManagement";

import { Paginated } from "@/@types/paginated";

type Props = {
	isDuplicateFilter: boolean;
	citizens: Paginated<ManagedCitizen>;
	handleDefineSelectedCitizen: (citizen: ManagedCitizen) => void;
};

export function RegistrationManagementTable({
	isDuplicateFilter,
	citizens,
	handleDefineSelectedCitizen,
}: Props) {
	return (
		<Table striped bordered hover responsive className="table-rounded border gs-2">
			{!isDuplicateFilter ? (
				<>
					<thead>
						<tr className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							<th>CIDADÃO</th>
							<th>CPF</th>
							<th>
								<OverlayTrigger
									trigger="click"
									placement="top"
									overlay={MicroareaPopover}
								>
									<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
										MC
										<FaInfoCircle />
									</span>
								</OverlayTrigger>
							</th>
							<th className="white-space-nowrap">PROFISSIONAL ACS</th>
							<th className="text-center">NIS</th>
							<th className="white-space-nowrap">CADASTRO</th>
							<th>
								<OverlayTrigger
									trigger="click"
									placement="top"
									overlay={StatusPopover}
								>
									<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
										STATUS
										<FaInfoCircle />
									</span>
								</OverlayTrigger>
							</th>
							<th className="no-print no-excel">AÇÕES</th>
						</tr>
					</thead>
					<tbody>
						{citizens.data?.map((citizen, index) => {
							const noIdentifier = !citizen.cpf || citizen.cpf.trim() === "0";
							const hasRegisterDate = citizen.registerDate;

							const hasOutdatedRegistration =
								!hasRegisterDate ||
								dayjs().diff(dayjs(citizen.registerDate), "month") > 12;

							return (
								<tr key={index} className="text-uppercase">
									<td
										data-content={`${citizen.name} - ${
											citizen.birthDate
												? dayjs(citizen.birthDate).format("DD/MM/YYYY")
												: "DATA DE NASCIMENTO NÃO INFORMADA"
										}`}
									>
										{citizen.name}
										{citizen.birthDate && (
											<span className="d-block fs-9">
												Nasceu em{" "}
												{dayjs(citizen.birthDate).format("DD/MM/YYYY")}
											</span>
										)}
									</td>
									<td>{noIdentifier ? "NÃO INFORMADO" : citizen.cpf}</td>
									<td>{citizen.area || "--"}</td>
									<td>{citizen.professional || "NÃO INFORMADO"}</td>
									<td className="text-center">
										{citizen.nis || "NÃO INFORMADO"}
									</td>
									<td className="text-center">
										{hasRegisterDate
											? dayjs(citizen.registerDate).format("DD/MM/YYYY")
											: "NÃO INFORMADO"}
									</td>
									<td
										className="text-center"
										data-content={
											hasOutdatedRegistration
												? "CADASTRO DESATUALIZADO"
												: "CADASTRO ATUALIZADO"
										}
									>
										<span className="d-flex flex-center text-light svg-icon svg-icon-light svg-icon-2x ms-n1">
											{hasOutdatedRegistration ? (
												<AiFillWarning className="text-danger" />
											) : (
												<FaCheck className="text-success" />
											)}
										</span>
									</td>
									<td className="text-end no-print no-excel">
										<ViewCitizenButton
											onClick={() => {
												handleDefineSelectedCitizen(citizen);
											}}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</>
			) : (
				<>
					<thead>
						<tr>
							<th>CIDADÃO</th>
							<th>CPF</th>
							<th>CNS</th>
							<th>NOME DO PAI</th>
							<th>NOME DA MÃE</th>
							<th className="no-print no-excel">AÇÕES</th>
						</tr>
					</thead>
					<tbody>
						{citizens.data?.map((citizen, index) => {
							return (
								<tr key={index}>
									<td
										data-content={`${citizen.name} - ${
											citizen.birthDate
												? dayjs(citizen.birthDate).format("DD/MM/YYYY")
												: "DATA DE NASCIMENTO NÃO INFORMADA"
										}`}
									>
										{citizen.name}
										{citizen.birthDate && (
											<span className="d-block fs-9">
												Nasceu em{" "}
												{dayjs(citizen.birthDate).format("DD/MM/YYYY")}
											</span>
										)}
									</td>
									<td>{citizen.cpf || "Não informado"}</td>
									<td>{citizen.cns || "Não informado"}</td>
									<td>{citizen.fatherName || "Não informado"}</td>
									<td>{citizen.motherName || "Não informado"}</td>
									<td>
										<ViewCitizenButton
											onClick={() => {
												handleDefineSelectedCitizen(citizen);
											}}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</>
			)}
		</Table>
	);
}

import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";
import axios from "axios";

import { TbMapPinOff } from "react-icons/tb";
import { FaMapMarkerAlt, FaUser, FaWhatsapp } from "react-icons/fa";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { phoneValidation } from "@/utils/phoneValidation";
import { exportExcel } from "@/utils/exports";
import { addressConcat } from "@/utils/adreessConcat";
import { HomeVisit } from "@/@types/HomeVisit";
import { Professional } from "@/@types/Professional";
import { Paginated } from "@/@types/paginated";
import { ExportTableType } from "@/@types/ExportTableType";
import { GenericObject } from "@/@types/genericals";
import { HomeVisitConclusionType } from "@/@types/HomeVisitConclusionType";
import { Option } from "@/@types/Option";

import { ModalMaps } from "@/components/ModalMaps";
import { TableSkeletons } from "@/components/TableSkeletons";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { PaginationLinks } from "@/components/PaginationLinks";
import { SearchInput } from "@/components/SearchInput";
import { OptionsPopover } from "@/components/Popovers/OptionsPopover";
import { LoadingScreen } from "@/components/LoadingScreen";
import { LegendData } from "@/components/LegendData";
import { TableForExportPdf, verifyConclusionType } from "./TableForExportPdf";
import { SelectConclusionType } from "@/components/SelectConclusionType";

type Props = ModalProps & {
	startDate: string;
	endDate: string;
	selectedProfessional: Professional;
};

export function ModalVisitsAcs({ selectedProfessional, startDate, endDate, ...rest }: Props) {
	const api = useApi();
	const printTableRef = useRef<HTMLDivElement | null>(null);
	const { uf, ibgeCode } = useCurrentAccount();
	const { CancelToken } = axios;
	const sourceCancel = CancelToken.source();
	const [searchFullNameCitizen, setSearchFullNameCitizen] = useState("");
	const debouncedFullName = useDebounce(searchFullNameCitizen, 2000);
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [exportTableType, setExportTableType] = useState<ExportTableType>();
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingExport, setIsLoadingExport] = useState(false);
	const [homeVisits, setHomeVisits] = useState<Paginated<HomeVisit>>({} as Paginated<HomeVisit>);
	const [homeVisitConclusionTypes, setHomeVisitConclusionTypes] = useState<
		HomeVisitConclusionType[]
	>([]);
	const [homeVisitsExportPdf, setHomeVisitsExportPdf] = useState<Paginated<HomeVisit>>(
		{} as Paginated<HomeVisit>
	);

	const [selectedConclusionTypeOption, setSelectedConclusionTypeOption] =
		useState<Option<number | undefined>>();

	const [selectedHomeVisit, setSelectedHomeVisit] = useState<HomeVisit>({} as HomeVisit);
	const [showModallocation, setShowModallocation] = useState(false);

	const handleShowModallocation = () => {
		setShowModallocation(true);
	};
	const handleCloseModallocation = () => {
		setShowModallocation(false);
	};

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	async function handleHomeVisitConclusionTypes() {
		try {
			const { data } = await api.get("homeVisitConclusionType/v1/GetAll", {
				params: { uf, ibgeCode },
			});
			setHomeVisitConclusionTypes(data || []);
		} catch (error) {
			console.log(error);
			setHomeVisitConclusionTypes([]);
		}
	}

	async function handleHomeVisits(pageSize?: number, page?: number) {
		try {
			setIsLoading(true);
			const { data } = await api.get(`homeVisit/v1/getPaginated`, {
				cancelToken: sourceCancel.token,
				params: {
					uf,
					ibgeCode,
					fullName: searchFullNameCitizen,
					professionalCns: selectedProfessional?.cns || "",
					homeVisitConclusionTypeId: selectedConclusionTypeOption?.value,
					startDate: startDate,
					endDate: endDate,
					pageNumber: page || pageNumber,
					pageSize: pageSize || 10,
				},
			});
			return data || ({} as Paginated<HomeVisit>);
		} catch (error) {
			console.log(error);
			return {} as Paginated<HomeVisit>;
		} finally {
			setIsLoading(false);
		}
	}

	async function handleFetchHomeVisitsExport() {
		setIsLoadingExport(true);
		const response = await handleHomeVisits(homeVisits?.totalRecords, 1);
		setHomeVisitsExportPdf(response);
		setIsLoadingExport(false);
	}

	async function handlePrint() {
		if (exportTableType === "print") {
			printTable();
		} else if (exportTableType === "excel") {
			const excelData: GenericObject[] = transformHomeVisitsToExportExcel(
				homeVisitsExportPdf?.data
			);
			exportExcel(excelData, `Tabela de visitas de cidadãos`);
		}
	}

	function transformHomeVisitsToExportExcel(homeVisitsExport: HomeVisit[]) {
		return homeVisitsExport.map(
			(homeVisit) =>
				({
					Nome: homeVisit.citizen?.fullName,
					Data: dayjs(homeVisit.date).format("DD/MM/YYYY"),
					Endereço: addressConcat(
						homeVisit.citizen?.citizenExtra?.street,
						homeVisit.citizen?.citizenExtra?.houseNumber,
						homeVisit.citizen?.citizenExtra?.neighborhood
					),
					Status:
						homeVisit.homeVisitConclusionType?.description?.toUpperCase() ===
						"VISITA REALIZADA"
							? "realizado"
							: homeVisit.homeVisitConclusionType?.description?.toUpperCase() ===
							  "AUSENTE"
							? "ausente"
							: homeVisit.homeVisitConclusionType?.description?.toUpperCase() ===
							  "VISITA RECUSADA"
							? "recusado"
							: "",
					Telefone: homeVisit.citizen?.citizenExtra?.phone,
				} as GenericObject)
		);
	}

	const printTable = useReactToPrint({
		content: () => printTableRef.current,
	});

	async function fetch() {
		setHomeVisits(await handleHomeVisits());
	}

	useEffect(() => {
		if (homeVisitsExportPdf?.data?.length) {
			handlePrint();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [homeVisitsExportPdf]);

	useEffect(() => {
		if (selectedProfessional.id) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProfessional]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		if (rest.show) {
			fetch();
		}

		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change filters.");
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName]);

	useEffect(() => {
		if (pageNumber !== 0) {
			if (rest.show) {
				fetch();
			}
		}
		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		if (pageNumber > 1) {
			setPageNumber(1);
		} else {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedConclusionTypeOption]);

	useEffect(() => {
		if (!rest.show) {
			setPageNumber(1);
		}
	}, [rest.show]);

	useEffect(() => {
		handleHomeVisitConclusionTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal size="xl" {...rest}>
			<ModalMaps
				show={showModallocation}
				onHide={handleCloseModallocation}
				title={selectedHomeVisit?.citizen?.fullName}
				zipCode={selectedHomeVisit?.citizen?.citizenExtra?.zipCode}
				city={selectedHomeVisit?.city}
				street={selectedHomeVisit.citizen?.citizenExtra?.street}
				houseNumber={selectedHomeVisit.citizen?.citizenExtra?.houseNumber}
				neighborhood={selectedHomeVisit.citizen?.citizenExtra?.neighborhood}
			/>
			<Modal.Header closeButton>
				<Modal.Title>
					<span className="fs-1">Acompanhamento de visitas</span>
					<p className="text-muted">{selectedProfessional?.name}</p>
				</Modal.Title>
			</Modal.Header>
			<ModalBody>
				<section>
					<Row className="d-flex justify-content-between w-100">
						<Col sm={12} md={6} lg={6} xxl={6}>
							<label className="mb-2">
								<strong>Pesquisar Cidadão:</strong>
							</label>
							<SearchInput
								placeholder="Pesquisar"
								value={searchFullNameCitizen}
								setValue={setSearchFullNameCitizen}
							/>
						</Col>
						<Col sm={8} md={4} lg={4} xxl={4}>
							<SelectConclusionType
								conclusionTypes={homeVisitConclusionTypes}
								value={selectedConclusionTypeOption}
								onChange={(newValue) =>
									setSelectedConclusionTypeOption(
										newValue as Option<number | undefined>
									)
								}
							/>
						</Col>
						<Col sm={3} md={2} lg={2} xxl={2} className="d-flex align-items-end">
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={(props) =>
									OptionsPopover({
										handlePrintClick: () => {
											setHomeVisitsExportPdf({} as Paginated<HomeVisit>);
											setExportTableType("print");
											handleFetchHomeVisitsExport();
										},
										handleExportExcelClick: () => {
											setHomeVisitsExportPdf({} as Paginated<HomeVisit>);
											setExportTableType("excel");
											handleFetchHomeVisitsExport();
										},
										...props,
									})
								}
								rootClose
							>
								<Button
									variant="secondary"
									className="w-100 px-3 mb-1 mt-3"
									disabled={isLoadingExport}
								>
									Opções
								</Button>
							</OverlayTrigger>
							<LoadingScreen loading={isLoadingExport} />
						</Col>
					</Row>
				</section>
				<LegendData
					titles={["Visita realizada", "Visita Ausente", "Visita Recusada"]}
					ClassNamesColors={["text-primary", "text-warning", "text-danger"]}
				/>
				<div className="overflow-auto mt-3">
					<Table>
						<thead className="fw-bolder bg-secondary">
							<tr>
								<th className="ps-2 text-center">Cidadão</th>
								<th className="text-center">Data</th>
								<th className="text-center">Endereço</th>
								<th className="text-center">Telefone</th>
								<th className="text-center">Whatsapp</th>
								<th className="text-center pe-2">Localização</th>
							</tr>
						</thead>
						<tbody>
							{!isLoading && homeVisits?.data ? (
								homeVisits.data?.map((homeVisit, index) => (
									<tr
										key={`home-visit-${index}`}
										className={verifyConclusionType(
											homeVisit.homeVisitConclusionType?.description
										)}
									>
										<td className="ps-2">
											{homeVisit.citizen?.fullName || "NÃO INFORMADO"}
										</td>
										<td className="text-center">
											{dayjs(homeVisit.date).format("DD/MM/YYYY")}
										</td>
										<td className="text-center">
											{addressConcat(
												homeVisit.citizen?.citizenExtra?.street,
												homeVisit.citizen?.citizenExtra?.houseNumber,
												homeVisit.citizen?.citizenExtra?.neighborhood
											)}
										</td>
										<td className="text-center">
											{homeVisit.citizen?.citizenExtra?.phone}
										</td>
										<td>
											{phoneValidation(
												homeVisit.citizen?.citizenExtra?.phone
											) ? (
												<a
													className="d-flex justify-content-center"
													rel="noreferrer"
													href={`https://wa.me/55${homeVisit.citizen?.citizenExtra?.phone}`}
													target="_blank"
												>
													<FaWhatsapp
														title="Contato cidadão"
														className="fs-2 text-success"
													/>
												</a>
											) : (
												<div
													title="Contato cidadão"
													className="d-flex justify-content-center"
												>
													<FaWhatsapp className="fs-2 text-secondary" />
												</div>
											)}
										</td>
										<td className="text-center">
											{homeVisit.citizen?.citizenExtra?.street ? (
												<FaMapMarkerAlt
													className="fs-1 text-danger cursor-pointer"
													onClick={() => {
														setSelectedHomeVisit(homeVisit);
														handleShowModallocation();
													}}
												/>
											) : (
												<TbMapPinOff className="fs-1 text-secondary" />
											)}
										</td>
									</tr>
								))
							) : (
								<TableSkeletons numberOfCells={6} />
							)}
						</tbody>
					</Table>
				</div>

				<section className="print-container" ref={printTableRef}>
					<Row>
						<h1 className="d-flex justify-content-center">
							Tabela de acompanhamento de visitas
						</h1>
						<p>
							<label className="fw-bolder me-2">Período:</label>
							<label>
								{`${dayjs(startDate).format("DD/MM/YYYY")} até ${dayjs(
									endDate
								).format("DD/MM/YYYY")}`}
							</label>
						</p>
						<p>
							<label className="fw-bolder me-2">Profissional:</label>
							<label>{selectedProfessional?.name}</label>
						</p>
						<p>
							<label className="fw-bolder me-2">Cns:</label>
							<label>{selectedProfessional?.cns || "NÃO INFORMADO"}</label>
						</p>
					</Row>
					<Row className="d-flex justify-content-between my-3">
						<Col>
							<LegendData
								titles={["Visita realizada", "Visita Ausente", "Visita Recusada"]}
								ClassNamesColors={["text-primary", "text-warning", "text-danger"]}
							/>
						</Col>
						<Col className="d-flex justify-content-end align-items-end me-2">
							<label>Data da exportação: {dayjs().format("DD/MM/YYYY")}</label>
						</Col>
					</Row>
					<TableForExportPdf homeVisits={homeVisitsExportPdf} />
				</section>
			</ModalBody>
			<Modal.Footer className="d-flex justify-content-center align-items-center">
				{homeVisits.totalRecords ? (
					<PaginationLinks
						itemsPerPage={homeVisits.pageSize}
						totalPages={homeVisits.totalRecords}
						changeSelectedPage={handleChangePageNumber}
						pageNumber={pageNumber || 0}
					/>
				) : (
					homeVisits.totalRecords === 0 &&
					!isLoading && (
						<div className="w-100 justify-content-center">
							<NoRecordsFeedback
								message={"Nenhum cidadão encontrado."}
								icon={<FaUser />}
							/>
						</div>
					)
				)}
			</Modal.Footer>
		</Modal>
	);
}

import { FormEvent, useEffect, useState } from "react";

import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { Option } from "@/@types/Option";
import { FederalUnit } from "@/@types/FederalUnit";
import { Account } from "@/@types/Account";
import { useApi } from "@/hooks/useApi";

type Props = {
	selectedFederalUnit: FederalUnit;
	setSelectedFederalUnit: (federalUnit: FederalUnit) => void;
	setSelectedCity: (city: Account) => void;
	handleFilter: (event: FormEvent) => void;
};

export function CityAdminReportFilters({
	selectedFederalUnit,
	setSelectedFederalUnit,
	setSelectedCity,
	handleFilter,
}: Props) {
	const api = useApi();
	const [federalUnits, setFederalUnits] = useState<FederalUnit[]>([]);
	const [cities, setCities] = useState<Account[]>([]);

	const federalUnitOptions: Option<FederalUnit>[] = federalUnits.map((federalUnit) => {
		return { label: federalUnit.name, value: federalUnit } as Option<FederalUnit>;
	});

	const cityOptions: Option<Account>[] = cities.map((city) => {
		return { label: city.locale, value: city } as Option<Account>;
	});

	async function getPermittedFederalUnits() {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedFederalUnits");
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function getPermittedCities(uf?: string) {
		try {
			const { data } = await api.get("/UserAccount/v1/GetPermittedCities", {
				params: { uf },
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchPermittedFederalUnits() {
		setFederalUnits(await getPermittedFederalUnits());
	}

	async function fetchPermittedCities() {
		if (selectedFederalUnit) {
			setCities(await getPermittedCities(selectedFederalUnit.abbreviation));
		} else {
			setCities([]);
		}
	}

	useEffect(() => {
		fetchPermittedFederalUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchPermittedCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFederalUnit]);

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col className="justify-content-start mb-2">
					<div className="d-flex flex-column">
						<label htmlFor={"federal-unit-admin-report-select"} className="form-label">
							UF:
						</label>
						<Select
							id="federal-unit-admin-report-select"
							options={federalUnitOptions}
							placeholder="Selecione..."
							styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
							onChange={(event) =>
								setSelectedFederalUnit((event as Option<FederalUnit>).value)
							}
						/>
					</div>
				</Col>
				<Col className="justify-content-start mb-2">
					<div className="d-flex flex-column">
						<label htmlFor={"city-admin-report-select"} className="form-label">
							Cidade:
						</label>
						<Select
							id="city-admin-report-select"
							options={cityOptions}
							placeholder="Selecione..."
							styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
							onChange={(event) => setSelectedCity((event as Option<Account>).value)}
						/>
					</div>
				</Col>
			</Row>
		</Form>
	);
}

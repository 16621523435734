import Popover from "react-bootstrap/Popover";

export function PopoverInformation(header: string, message: string) {
	return (
		<Popover id="citizen-sync-popover">
			<Popover.Header>
				<strong>{header}</strong>
			</Popover.Header>
			<Popover.Body>
				<ul>
					<li>{message}</li>
				</ul>
			</Popover.Body>
		</Popover>
	);
}

import { MutableRefObject } from "react";
import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { Paginated } from "@/@types/paginated";
import { PregnantPatient } from "@/@types/PregnantPatient";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";

type Props = {
	tableRef: MutableRefObject<HTMLTableElement | null>;
	pregnantPatient: Paginated<PregnantPatient>;
};

export function TableIndicator1ExportPdf({ tableRef, pregnantPatient }: Props) {
	return (
		<Table responsive className="table-rounded print-container border gy-7 gs-3" ref={tableRef}>
			<thead>
				<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
					<th>
						<CitizenSyncLabel />
					</th>
					<th className="white-space-nowrap">CIDADÃO</th>
					<th className="white-space-nowrap">DN</th>
					<th className="white-space-nowrap">CPF/CNS</th>
					<th className="white-space-nowrap">EQUIPE</th>
					<th>
						<abbr title="Micro Área">MC</abbr>
					</th>
					<th className="white-space-nowrap">
						<abbr title="Data da última menstruação">DUM</abbr>
					</th>
					<th className="white-space-nowrap">
						<abbr title="Data primeiro atendimento">1º ATENDIMENTO</abbr>
					</th>
					<th className="white-space-nowrap">Nº DE CONSULTAS</th>
					<th className="white-space-nowrap">
						<abbr title="Idade gestacional na primeira consulta (IG)">
							IG 1º ATENDIMENTO
						</abbr>
					</th>
					<th className="white-space-nowrap">
						<abbr title="Data provável do parto (DPP)">DPP</abbr>
					</th>
				</tr>
			</thead>
			<tbody>
				{pregnantPatient?.data?.map((patient, index) => {
					const numberOfAttendances = patient.attendances?.length;
					return (
						<tr key={`patient-pregnant-${index}`}>
							<td title="Sincronização">
								<PremilinaryReportStatusIcon
									status={patient.preliminaryReportStatus}
								/>
							</td>
							<td className="text-nowrap" title="Nome completo">
								{patient.fullName || "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap">
								{patient.birthdate
									? dayjs(patient.birthdate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap" title="CPF/CNS">
								{identifierOrNationalHealthCard(patient) || "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap" title="Equipe">
								{patient.healthTeam?.name || "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap text-center" title="Micro Área">
								{patient.area || "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap" title="Data da última menstruação (DUM)">
								{displayFirstDumDate(patient.firstDumDate) || "NÃO INFORMADO"}
							</td>
							<td title="Data do primeiro atendimento" className="text-nowrap">
								{patient.firstAttendanceDate
									? dayjs(patient.firstAttendanceDate).format("DD/MM/YYYY")
									: "NÃO INFORMADO"}
							</td>

							<td className="text-nowrap" title="Número de consultas">
								{displaySingularOrPlural(numberOfAttendances, "consulta") ||
									"NÃO INFORMADO"}
							</td>
							<td
								className="text-nowrap"
								title="Idade gestacional na primeira consulta (IG)"
							>
								{patient.gestationalAge1St
									? displaySingularOrPlural(patient?.gestationalAge1St, "semana")
									: "NÃO INFORMADO"}
							</td>
							<td className="text-nowrap" title="Data provável do parto (DPP)">
								{dayjs(patient?.dppDate).format("DD/MM/YYYY") || "NÃO INFORMADO"}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}

import { useState } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { BasePatient } from "@/@types/BasePatient";
import { Paginated } from "@/@types/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator4 } from "../ModalIndicator4";

import "./styles.scss";

type Props = {
	patient: Paginated<BasePatient>;
	isLoading: boolean;
};

export function TableIndicator4({ isLoading, patient }: Props) {
	const [patientSelected, setPatientSelected] = useState<BasePatient>({} as BasePatient);
	const [showModalIndicator4, setShowModalIndicator4] = useState(false);
	const handleShowModalIndicator4 = () => {
		setShowModalIndicator4(true);
	};
	const handleCloseModalIndicator4 = () => {
		setShowModalIndicator4(false);
	};
	return (
		<div>
			<ModalIndicator4
				show={showModalIndicator4}
				onHide={handleCloseModalIndicator4}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						<th>CIDADÃO</th>
						<th>DN</th>
						<th>CPF/CNS</th>
						<th>EQUIPE</th>
						<th>MC</th>
						<th className="text-nowrap">ÚLTIMA COLETA</th>
						<th className="text-nowrap">PRÓXIMA COLETA</th>
						<th>AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && patient?.data ? (
						patient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								<td title="nome completo" className="text-nowrap">
									{patient.fullName || "NÃO INFORMADO"}
								</td>
								<td title="data de nascimento" className="text-nowrap">
									{patient.birthdate
										? dayjs(patient.birthdate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td title="cpf/nis" className="text-nowrap">
									{identifierOrNationalHealthCard(patient) || "NÃO INFORMADO"}
								</td>
								<td title="equipe" className="text-nowrap">
									{patient.healthTeam?.name || "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap">{patient.area || "NÃO INFORMADO"}</td>
								<td title="ultima coleta" className="text-nowrap">
									{patient.attendanceProcedures?.length !== 0
										? dayjs(patient?.attendanceProcedures?.[0]?.date).format(
												"DD/MM/YYYY"
										  )
										: "NÃO INFORMADO"}
								</td>
								<td title="próxima coleta" className="text-nowrap">
									{patient.attendanceProcedures?.length !== 0
										? dayjs(patient?.attendanceProcedures?.[0]?.date)
												.add(36, "month")
												.format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td className="text-center no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator4();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={8} height={20} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

import dayjs from "dayjs";

export function quarters() {
	const previousYear = dayjs().subtract(2, "year").year();
	const current = dayjs();
	const nextYear = dayjs().add(1, "year").year();

	const quarters = Array.from({ length: (current.year() + 1 - previousYear) * 3 }, (_, index) => {
		const indexQuarter = (index % 3) + 1;
		const previousQuarters = `Q${indexQuarter}/${previousYear + Math.floor(index / 3)}`;
		return previousQuarters;
	});

	const nextQuarter = `Q1/${nextYear}`;

	return [...quarters, nextQuarter].reverse();
}

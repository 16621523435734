import Popover, { PopoverProps } from "react-bootstrap/Popover";

type CitizenSyncPopoverProps = PopoverProps;

export function CitizenSyncPopover({ ...rest }: CitizenSyncPopoverProps) {
	const iconStyles = {
		paddingBottom: 5,
		height: 35,
	};

	return (
		<Popover id="citizen-sync-popover" {...rest}>
			<Popover.Header>Sincronização do cidadão</Popover.Header>
			<Popover.Body>
				<p>Define a sincronização cadastral do cidadão.</p>
				<ul>
					<li>
						Caso o cidadão esteja registrado na <strong>base local</strong> e vinculado
						ao relatório de cadastros do <strong>SISAB</strong>, a sincronização será
						exibida como{" "}
						<img src="/media/images/synced.svg" alt="Sicronizado" style={iconStyles} />
					</li>
					<li>
						Caso o cidadão esteja registrado <strong>apenas</strong> na{" "}
						<strong>base local</strong>, a sincronização será exibida como{" "}
						<img
							src="/media/images/legislative-proposal.svg"
							alt="PEC"
							style={iconStyles}
						/>
					</li>
					<li>
						Caso o cidadão esteja identificado apenas no <strong>SISAB</strong>, a
						sincronização será exibida como{" "}
						<img
							src="/media/images/health-information-system-for-primary-care.svg"
							alt="SISAB"
							style={iconStyles}
						/>
					</li>
				</ul>
			</Popover.Body>
		</Popover>
	);
}

import { MutableRefObject } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { Paginated } from "@/@types/paginated";
import { BasePatient } from "@/@types/BasePatient";
import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";

type Props = {
	tableRef: MutableRefObject<HTMLTableElement | null>;
	patient: Paginated<BasePatient>;
};

export function TableIndicator5ExportPdf({ tableRef, patient }: Props) {
	return (
		<Table responsive className="table-rounded print-container border gy-7 gs-3" ref={tableRef}>
			<thead>
				<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
					<th>
						<CitizenSyncLabel />
					</th>
					<th>CIDADÃO</th>
					<th>NOME DA MÃE</th>
					<th>DN</th>
					<th>CPF/CNS</th>
					<th>EQUIPE</th>
					<th>MC</th>
					<th>IDADE</th>
				</tr>
			</thead>
			<tbody>
				{patient?.data?.map((patient, index) => (
					<tr key={`patient-pregnant-${index}`}>
						<td>
							<PremilinaryReportStatusIcon status={patient.preliminaryReportStatus} />
						</td>
						<td className="text-nowrap">{patient.fullName}</td>
						<td className="text-nowrap">
							{patient.motherName?.toUpperCase() || "NÃO INFORMADO"}
						</td>
						<td className="text-nowrap">
							{patient.birthdate
								? dayjs(patient.birthdate).format("DD/MM/YYYY")
								: "NÃO INFORMADO"}
						</td>
						<td className="text-nowrap">{identifierOrNationalHealthCard(patient)}</td>
						<td className="text-nowrap">
							{patient.healthTeam?.name || "NÃO INFORMADO"}
						</td>
						<td className="text-nowrap text-center">
							{patient.area || "NÃO INFORMADO"}
						</td>
						<td className="text-nowrap">{patient.age}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}

import dayjs from "dayjs";

import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaWhatsapp } from "react-icons/fa";

import { phoneValidation } from "@/utils/phoneValidation";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";

import { BaseModalProps } from "@/components/BaseModal";
import { TripleViralCitizenVaccinationsList } from "@/pages/TripleViralManagement/TripleViralCitizenVaccinationsList";
import { CitizenVaccinations } from "@/pages/TripleViralManagement/TripleViralCitizensTable";

type Props = BaseModalProps & {
	citizenVaccinations: CitizenVaccinations;
};

export function TripleViralCitizenVaccinationsModal({ citizenVaccinations, ...rest }: Props) {
	const { user } = useCurrentAccount();

	function getMessage(citizenVaccinations: CitizenVaccinations) {
		const firstDosageDate = dayjs(citizenVaccinations.citizen.birthdate)
			.add(12, "months")
			.format("DD/MM/YYYY");
		const secondDosageDate = dayjs(citizenVaccinations.citizen.birthdate)
			.add(15, "months")
			.format("DD/MM/YYYY");
		if (
			citizenVaccinations.vaccinationStatus === "abandoned" ||
			citizenVaccinations.vaccinationStatus === "lost" ||
			(citizenVaccinations.vaccinationStatus === "delayed" && !citizenVaccinations.statusD1)
		)
			return `Olá ${citizenVaccinations.citizen.motherName}, seu filho(a) ${citizenVaccinations.citizen.fullName} precisa tomar a vacina da triplice viral que o protege contra sarampo, caxumba e rubéola. O esquema de vacinação é composto de 2 doses idealmente aplicadas em crianças ao completar 1 ano(1ª dose) e 1 ano e 3 meses(2ª dose), peço com gentileza que vacine a criança no posto de cadastro. Atenciosamente: ${user.fullName}`;
		if (citizenVaccinations.vaccinationStatus === "delayed")
			return `Olá ${citizenVaccinations.citizen.motherName}, seu filho(a) ${citizenVaccinations.citizen.fullName} precisa tomar a 2ª dose da vacina da triplice viral que seria idealmente aplicada no dia ${secondDosageDate}. O esquema de vacinação é composto de 2 doses idealmente aplicadas em crianças ao completar 1 ano(1ª dose) e 1 ano e 3 meses(2ª dose), peço com gentileza que vacine a criança no posto de cadastro. Atenciosamente: ${user.fullName}`;
		if (citizenVaccinations.vaccinationStatus === "pending")
			return `Olá ${citizenVaccinations.citizen.motherName}, seu filho(a) ${
				citizenVaccinations.citizen.fullName
			} precisa tomar a ${
				citizenVaccinations.statusD1
					? `2ª dose da vacina da triplice viral que seria idealmente aplicada no dia ${secondDosageDate}`
					: `1ª dose da vacina da triplice viral que seria idealmente aplicada no dia ${firstDosageDate}`
			}. O esquema de vacinação é composto de 2 doses idealmente aplicadas em crianças ao completar 1 ano(1ª dose) e 1 ano e 3 meses(2ª dose), peço com gentileza que vacine a criança no posto de cadastro. Atenciosamente: ${
				user.fullName
			}`;
		return;
	}

	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header className="bg-dark" closeVariant="white" closeButton>
				<Modal.Title className="text-white">Vacinações do cidadão</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="my-2">
					<Col>
						<h4>Informações do cidadão</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Nome: </strong>
						{citizenVaccinations.citizen?.fullName}
					</Col>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Sexo: </strong>
						{citizenVaccinations.citizen?.gender
							? citizenVaccinations.citizen?.gender === 1
								? "Masculino"
								: "Feminino"
							: "Não informado"}
					</Col>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Data de nascimento: </strong>
						{citizenVaccinations.citizen?.birthdate
							? dayjs(citizenVaccinations.citizen?.birthdate).format("DD/MM/YYYY")
							: ""}
					</Col>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Idade: </strong>
						{citizenVaccinations.citizen?.age}
					</Col>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Nome da mãe: </strong>
						{citizenVaccinations.citizen?.motherName}
					</Col>
					<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
						<strong>Telefone: </strong>
						{citizenVaccinations.citizen?.phone}
						{phoneValidation(citizenVaccinations.citizen?.phone) ? (
							<a
								className="ms-2"
								rel="noreferrer"
								href={
									getMessage(citizenVaccinations) &&
									`https://wa.me/55${
										citizenVaccinations.citizen?.phone
									}?text=${getMessage(citizenVaccinations)}`
								}
								target="_blank"
							>
								<FaWhatsapp title="Contato cidadão" className="fs-2 text-success" />
							</a>
						) : (
							<div title="Contato cidadão" className="ms-2">
								<FaWhatsapp className="fs-2 text-secondary" />
							</div>
						)}
					</Col>
				</Row>
				<Row className="my-2">
					<Col>
						<h4>Vacinas de tríplice viral aplicadas</h4>
					</Col>
				</Row>
				<TripleViralCitizenVaccinationsList citizenVaccinations={citizenVaccinations} />
			</Modal.Body>
		</Modal>
	);
}

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export type Props = {
	height?: number;
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories?: string[];
	apexOptions?: ApexOptions;
	hideLegend?: boolean;
};

export function PieChart({ apexOptions, height, series, categories, hideLegend }: Props) {
	const semiDonutOption: ApexOptions =
		apexOptions ||
		({
			series: series || [],
			chart: {
				width: 100,
				type: "pie",
			},
			labels: categories || [],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 300,
						},
						legend: {
							position: "bottom",
							show: !hideLegend,
						},
					},
				},
			],
		} as ApexOptions);

	return (
		<ReactApexChart
			options={semiDonutOption}
			series={series}
			type="pie"
			height={height || 350}
		/>
	);
}

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ImmunobiologicalDosage } from "@/@types/ImmunobiologicalDosage";
import { Vaccine } from "@/@types/Vaccine";
import { Immunobiological } from "@/@types/Immunobiological";

import { VaccinationCards } from "../VaccinationCards";

type Props = {
	vaccinations: Vaccine[];
	immunobiological: Immunobiological;
	validateDosages?: ImmunobiologicalDosage[];
};

export function VaccinesByAcronym({ vaccinations, immunobiological, validateDosages }: Props) {
	if (!(vaccinations.length > 0 || (validateDosages && validateDosages?.length > 0))) {
		return null;
	}

	const vaccinesSingleDoses = vaccinations?.filter(
		(vaccination) =>
			!validateDosages?.find(
				(validateDosage) =>
					validateDosage.acronym.toUpperCase() ===
					vaccination.vaccine.immunobiologicalDosage?.acronym.toUpperCase()
			)
	);
	return (
		<div>
			<h3 className="text-uppercase text-dark fw-bold fs-1">
				<span>{immunobiological.acronym}</span>
				<div className="fs-8">{immunobiological.name}</div>
			</h3>
			<Row className="d-flex">
				{validateDosages?.map((validateDosage, index) => {
					const vaccines = vaccinations.filter(
						(vaccination) =>
							vaccination.vaccine.immunobiologicalDosage.acronym.toUpperCase() ===
							validateDosage.acronym.toUpperCase()
					);
					if (vaccines?.length > 0) {
						return vaccines.map((vaccine, index) => (
							<Col md={12} lg={4} key={index} className="my-2">
								<VaccinationCards
									key={`vaccine-cards${index}`}
									vaccine={vaccine}
									theme="success"
									title={vaccine.vaccine?.immunobiologicalDosage?.name || "Dose"}
								/>
							</Col>
						));
					}
					return (
						<Col md={12} lg={4} key={index} className="my-2">
							<VaccinationCards
								key={`vaccine-cards${index}`}
								theme="danger"
								title={validateDosage.name}
							/>
						</Col>
					);
				})}

				{vaccinesSingleDoses.map((vaccine, index) => (
					<Col md={12} lg={4} key={index} className="my-2">
						<VaccinationCards
							key={`vaccine-sigle-dosages${index}`}
							vaccine={vaccine}
							theme="success"
							title={vaccine.vaccine?.immunobiologicalDosage?.name || "Dose"}
						/>
					</Col>
				))}
			</Row>
		</div>
	);
}

import { createContext, ReactNode, useState, useEffect } from "react";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeam } from "@/@types/HealthTeam";

type HealthTeamContextType = {
	healthTeams: HealthTeam[];
};

export const HealthTeamContext = createContext({} as HealthTeamContextType);

type UnitsContextProviderProps = {
	children: ReactNode;
};

export function HealthTeamContextProvider({ children }: UnitsContextProviderProps) {
	const api = useApi();
	const [healthTeams, setHealthTeams] = useState<HealthTeam[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function getHealthTeams() {
		if (!ibgeCode) return;
		try {
			const { data } = await api.get<HealthTeam[]>("/healthteam/v1/gethealthteams", {
				params: { uf, ibgeCode, cnes },
			});
			setHealthTeams(data || []);
		} catch (error) {
			setHealthTeams([]);
			console.error(error);
		}
	}

	useEffect(() => {
		getHealthTeams();

		return () => setHealthTeams([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<HealthTeamContext.Provider value={{ healthTeams }}>{children}</HealthTeamContext.Provider>
	);
}

export enum PermissionEnum {
	SUPER_ADMIN = "super-admin",
	GUEST = "guest",
	VIEW_INDICATORS = "view-indicators",
	VIEW_CITIZEN_MANAGEMENT = "view-citizen-management",
	VIEW_PROFESSIONAL_MANAGEMENT = "view-professional-management",
	VIEW_REPORTS = "view-reports",
	CONFIG_DATA_IMPORT = "config-data-import",
	VIEW_STATE_REPORTS = "view-state-reports",
	STATE_PROFESSIONAL_MANAGEMENT = "state-professional-management",
}

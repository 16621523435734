import { IndicatorData } from "@/pages/PrevineBrasilDashboard";

export function calculateIndicatorPercentage(validated: number, identifiedTotal: number) {
	return identifiedTotal !== 0
		? Number(((validated * 100 || 0) / identifiedTotal || 1).toFixed(2))
		: 0;
}

export function calculateIndicatorDataPercentage(indicators: IndicatorData[]) {
	try {
		return Math.round(
			(Number(
				indicators
					?.map((itemindicator) => itemindicator?.Valid || 0)
					.reduce((acc, current) => acc + current, 0) || 0
			) /
				Number(
					indicators
						?.map((itemindicator) => itemindicator?.Identified)
						.reduce((acc, current) => acc + current, 0) || 1
				)) *
				100
		);
	} catch (error) {
		return 0;
	}
}

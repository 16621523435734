import { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { ToastContainer } from "react-toastify";
import ReactInputMask from "react-input-mask";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { identifierReplaceAll } from "@/utils/formatIdentifier";
import { useApi } from "@/hooks/useApi";
import { User } from "@/@types/user";

import { ChooseConfirmation } from "./ChooseConfirmation";
import { BaseModalProps } from "@/components/BaseModal";

type Props = BaseModalProps;

const initialValues = {
	identifier: "",
};

const forgotPasswordSchema = Yup.object().shape({
	identifier: Yup.string()
		.min(14, "CPF inválido")
		.max(14, "CPF inválido")
		.required("CPF é obrigatório"),
});

export function ForgotPassword({ ...rest }: Props) {
	const api = useApi();
	const [infoMask, setInfoMask] = useState<User>();
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: handleSubmit,
	});

	async function handleSubmit() {
		setIsLoading(true);
		try {
			const userIdentifier = identifierReplaceAll(formik.values.identifier);
			const { data } = await api.get(`/User/v1/GetUserInforMask`, {
				params: {
					identifier: userIdentifier,
				},
			});

			setInfoMask(data as User);
			handleShowChooseConfirmation();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	const [showChooseConfirmation, setShowChooseConfirmation] = useState(false);
	const handleShowChooseConfirmation = () => {
		handleCloseChooseConfirmation();
		setShowChooseConfirmation(true);
	};
	const handleCloseChooseConfirmation = () => setShowChooseConfirmation(false);

	return (
		<div>
			<ToastContainer />
			<ChooseConfirmation
				show={showChooseConfirmation}
				onHide={handleCloseChooseConfirmation}
				infoMask={infoMask}
				identifier={formik.values.identifier}
			/>

			<Modal.Header>
				<Modal.Title>
					Informe seu CPF que enviaremos um Código, para Definir uma nova senha
				</Modal.Title>
			</Modal.Header>
			<form noValidate id="forgot-pass-word-form" onSubmit={formik.handleSubmit}>
				<div className="d-flex justify-content-center mb-10">
					<div className="d-flex flex-column">
						<ReactInputMask
							placeholder="CPF"
							{...formik.getFieldProps("identifier")}
							className={clsx(
								"form-control form-control-lg",
								{
									"is-invalid":
										formik.touched.identifier && formik.errors.identifier,
								},
								{
									"is-valid":
										formik.touched.identifier && !formik.errors.identifier,
								}
							)}
							maskChar={null}
							name="identifier"
							id="input-identifier"
							type="text"
							mask="999.999.999-99"
							autoComplete="off"
							value={formik.values.identifier}
							onChange={formik.handleChange}
							style={{ width: "min-content" }}
							autoFocus={true}
						/>
						{formik.touched.identifier && formik.errors.identifier && (
							<div className="fv-plugins-message-container text-danger">
								<span role="alert">{formik.errors.identifier}</span>
							</div>
						)}
					</div>
				</div>

				<div className="d-flex flex-wrap justify-content-center gap-3">
					<Link to="/auth/login">
						<button
							type="button"
							id="kt_login_password_reset_form_cancel_button"
							className="btn btn-lg btn-light-primary fw-bolder"
						>
							Cancelar
						</button>
					</Link>

					<Button
						type="submit"
						id="bt-choose-confirmation-submit"
						className="btn btn-lg btn-primary fw-bolder"
						disabled={!formik.isValid && !infoMask}
					>
						{isLoading ? (
							<span>
								Por favor, aguarde...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						) : (
							<span className="indicator-label text-white">Enviar</span>
						)}
					</Button>
				</div>
			</form>
		</div>
	);
}

import dayjs from "dayjs";

import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Cbo } from "@/@types/Cbo";
import { HealthTeam } from "@/@types/HealthTeam";
import { HealthUnit } from "@/@types/HealthUnit";
import { Procedure } from "@/@types/Procedure";
import { Professional } from "@/@types/Professional";

type ExamsCardsProps = {
	procedure?: Procedure;
	professional?: Professional;
	procedureDate?: string;
	healthTeam?: HealthTeam;
	healthUnit?: HealthUnit;
	isPendingExam?: boolean;
	cbo?: Cbo;
};

export function ExamsCards({
	healthTeam,
	procedure,
	professional,
	procedureDate,
	isPendingExam,
	cbo,
}: ExamsCardsProps) {
	return (
		<div className="bg-light-dark border border-primary rounded p-5">
			<Row>
				<Col sm={12} md={9} lg={9} xl={9} className="d-flex align-items-center gap-5">
					<span>
						{isPendingExam ? (
							<FaExclamationTriangle className="text-warning fs-1" />
						) : (
							<FaCheck className="text-success fs-1" />
						)}
					</span>
					<div>
						<p className="fw-bold text-primary fs-5">
							Procedimento/Exame:
							<span className="ms-1 text-dark fw-normal">
								{procedure?.name
									? `${procedure.procedureCode} - ${procedure.name}`
									: "NÃO INFORMADO"}
							</span>
						</p>
						<p>
							<p className="fw-bold text-primary fs-5">
								Profissional:
								<span className="ms-1 text-dark fw-normal">
									{professional?.name || "NÃO INFORMADO"}
								</span>
							</p>
							<p className="fw-bold text-primary fs-5">
								Categoria:
								<span className="ms-1 text-dark fw-normal">
									{cbo?.description || "NÃO INFORMADO"}
								</span>
							</p>
						</p>
						<p className="fw-bold text-primary fs-5">
							Equipe:
							<span className="ms-1 text-dark fw-normal">
								{healthTeam?.ine
									? `${healthTeam?.ine}-${healthTeam?.name}`
									: "NÃO INFORMADO"}
							</span>
						</p>
					</div>
				</Col>

				<Col sm={12} md={3} lg={3} xl={3} className="d-flex justify-content-center">
					<span>
						Data do registro:
						<span className="ms-1 fs-3 text-white bg-primary rounded p-1">
							{procedureDate ? (
								dayjs(procedureDate)?.format("DD/MM/YYYY")
							) : (
								<span className="visibility-hidden">--/--/----</span>
							)}
						</span>
					</span>
				</Col>
			</Row>
		</div>
	);
}

import Select, { Props as SelectProps } from "react-select";

import { Option } from "@/@types/Option";
import { quarters } from "@/utils/quarters";

type Props = SelectProps;

export function QuartersSelect({ ...rest }: Props) {
	const options: Option<string>[] = quarters().map((quarter) => {
		return {
			value: quarter,
			label: quarter.split("/").join(" - "),
		};
	});

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id} className="form-label">
				Quadrimestres:
			</label>
			<Select
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum quadrimestre encontrado"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				options={options}
				{...rest}
			/>
		</div>
	);
}

import FormControl from "react-bootstrap/FormControl";
import { SearchIcon } from "@/components/SearchIcon";

type Props = {
	value: string;
	placeholder: string;
	setValue: (search: string) => void;
};

export function SearchInput({ value, placeholder, setValue }: Props) {
	return (
		<div className="d-flex align-items-center">
			<SearchIcon />

			<FormControl
				className="form-control ps-15 pb-3"
				placeholder={placeholder}
				value={value}
				onChange={(event) => setValue(event.target.value)}
				style={{ minWidth: "14rem" }}
			/>
		</div>
	);
}

import { MouseEvent, ReactNode } from "react";
import { FaUsers } from "react-icons/fa";

import { Skeleton } from "@/components/Skeleton";

import "./styles.scss";

type Props = {
	isLoading: boolean;
	icon?: ReactNode;
	theme: string;
	value?: JSX.Element | number;
	valueDescription: string;
	onPressIcon?: (event: MouseEvent<Element>) => void;
};

export function IndicatorQuantityStatistic({
	isLoading,
	icon,
	theme,
	value,
	valueDescription,
	onPressIcon,
}: Props) {
	return (
		<div className="d-flex gap-2 align-items-stretch">
			<span
				className={`card bg-${theme} hoverable py-5 align-items-center justify-content-center indicator-quantity-icon`}
				onClick={onPressIcon}
			>
				<span className="d-flex text-light svg-icon svg-icon-light svg-icon-3x ms-n1">
					{icon || <FaUsers />}
				</span>
			</span>
			<span
				className={`card bg-light-${theme} text-${theme} py-3 px-3 text-center justify-content-center indicator-quantity-title`}
			>
				{!isLoading ? value?.toLocaleString() : <Skeleton />}
			</span>
			<span
				className={`card align-self-center border border-${theme} text-${theme} py-3 px-1 fs-8 text-center indicator-quantity-description`}
			>
				{valueDescription}
			</span>
		</div>
	);
}

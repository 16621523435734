import { createRoot } from "react-dom/client";
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from "redux-persist/integration/react";
import { Provider, ProviderProps } from "react-redux";
import store, { persistor } from "./setup/redux/Store";
import { Chart, registerables } from "chart.js";

// Apps
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routes/AppRoutes";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

Chart.register(...registerables);

const providerProps: ProviderProps = {
	store: store,
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<Provider {...providerProps}>
		<PersistGate persistor={persistor} loading={<div>Loading...</div>}>
			<AppRoutes />
		</PersistGate>
	</Provider>
);

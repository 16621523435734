import Select from "react-select";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { IndicatorType } from "@/utils/enums/indicatorType";
import { Option } from "@/@types/Option";
import { ExportTableType } from "@/@types/ExportTableType";

import { SearchInput } from "@/components/SearchInput";
import { OptionsPopover } from "@/components/Popovers/OptionsPopover";

const optionsIndicatorType: Option<IndicatorType | null>[] = [
	{ value: null, label: "Todos" },
	{ value: IndicatorType.Validated, label: "Válidos" },
	{ value: IndicatorType.PendingEligible, label: "Pendentes" },
	{ value: IndicatorType.PendingIneligible, label: "Perdidos" },
];

type Props = {
	searchCitizen: string;
	searchBirthdate: string;
	isLoading: boolean;
	indicatorType: Option<IndicatorType | null>;
	setSearchCitizen: (searchCitizen: string) => void;
	setSearchBirthdate: (searchBirthdate: string) => void;
	setIndicatorType: (searchIndicatorType: Option<IndicatorType | null>) => void;
	handleExportTable: (exportType: ExportTableType) => Promise<void>;
};

export function FiltersIndicator3({
	searchBirthdate,
	searchCitizen,
	isLoading,
	indicatorType,
	setSearchBirthdate,
	setSearchCitizen,
	handleExportTable,
	setIndicatorType,
}: Props) {
	return (
		<Row className="d-flex w-100 align-items-center justify-content-between text-nowrap">
			<Col xs={12} sm={6} md={4} lg={4} xl={4} className="d-flex flex-column my-2">
				<label className="mb-2">
					<strong>Pesquisar Cidadão:</strong>
				</label>
				<SearchInput
					placeholder="Pesquisar"
					value={searchCitizen}
					setValue={setSearchCitizen}
				/>
			</Col>
			<Col xs={12} sm={6} md={3} lg={4} xl={3} className="d-flex flex-column my-2">
				<div>
					<label className="mb-2">
						<strong>Classificação:</strong>
					</label>
					<Select
						className="w-100"
						value={indicatorType}
						onChange={(option) => {
							setIndicatorType(option as Option<IndicatorType | null>);
						}}
						options={optionsIndicatorType}
						placeholder="Selecione..."
						styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
					/>
				</div>
			</Col>
			<Col xs={12} sm={6} md={3} lg={4} xl={2} className="d-flex flex-column my-2">
				<label className="mb-2">
					<strong>Data de Nascimento:</strong>
				</label>
				<input
					type="date"
					className="form-control form-control-solid rounded"
					placeholder="Data Nascimento"
					min="0000-01-01"
					max="3000-12-31"
					value={searchBirthdate}
					onChange={(event) => setSearchBirthdate(event.target.value)}
					style={{ minWidth: "12rem" }}
				/>
			</Col>
			<Col xs={12} sm={6} md={2} lg={2} xl={2} className="d-flex flex-column my-2">
				<OverlayTrigger
					trigger="click"
					placement="bottom"
					overlay={(props) =>
						OptionsPopover({
							handlePrintClick: () => handleExportTable("print"),
							handleExportExcelClick: () => handleExportTable("excel"),
							...props,
						})
					}
					rootClose
				>
					<Button variant="secondary" className="px-2" disabled={isLoading}>
						Opções
					</Button>
				</OverlayTrigger>
			</Col>
		</Row>
	);
}

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Account } from "@/@types/Account";

import { BarChart } from "@/components/Charts/BarChart";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";

export type ResumeDiseasesChronicNotTransmissibleOfCities = {
	city: Account;
	totalDiabetics: number;
	totalAttendancesCitizensWithDiabetics: number;
	totalHypertension: number;
	totalAttendancesCitizensWithHypertension: number;
};

type Props = {
	resumeDiseasesChronicNotTransmissibleOfCities: ResumeDiseasesChronicNotTransmissibleOfCities[];
};

export function ChronicNotCommunicableDiseases({
	resumeDiseasesChronicNotTransmissibleOfCities,
}: Props) {
	return (
		<Row>
			<Col sm={12} md={12} lg={6} xl={6}>
				<Card className="m-2">
					<Card.Header>
						<Card.Title>
							<label className="me-2"> Diabéticos</label>
							<CardOverlayTrigger
								children={
									<LegendData
										ClassNamesColors={["text-primary", "text-success"]}
										titles={["Atendidos", "Não atendidos"]}
									/>
								}
							/>
						</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto p-0" style={{ maxHeight: "414px" }}>
						<BarChart
							height={
								resumeDiseasesChronicNotTransmissibleOfCities.length < 10
									? 400
									: resumeDiseasesChronicNotTransmissibleOfCities.length * 30
							}
							categories={
								resumeDiseasesChronicNotTransmissibleOfCities?.map(
									(resume) => resume.city.locale
								) || []
							}
							series={[
								{
									name: "Atendidos",
									data:
										resumeDiseasesChronicNotTransmissibleOfCities?.map(
											(resume) => resume.totalAttendancesCitizensWithDiabetics
										) || [],
								},
								{
									name: "Não atendidos",
									data:
										resumeDiseasesChronicNotTransmissibleOfCities?.map(
											(resume) =>
												resume.totalDiabetics -
												resume.totalAttendancesCitizensWithDiabetics
										) || [],
								},
							]}
							paddingRight={0}
							paddingleft={0}
							isStacked
						/>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={12} lg={6} xl={6}>
				<Card className="m-2">
					<Card.Header>
						<Card.Title>
							<label className="me-2"> Hipertensos </label>
							<CardOverlayTrigger
								children={
									<LegendData
										ClassNamesColors={["text-primary", "text-success"]}
										titles={["Atendidos", "Não atendidos"]}
									/>
								}
							/>
						</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto p-0" style={{ maxHeight: "414px" }}>
						<BarChart
							height={
								resumeDiseasesChronicNotTransmissibleOfCities.length < 10
									? 400
									: resumeDiseasesChronicNotTransmissibleOfCities.length * 30
							}
							categories={
								resumeDiseasesChronicNotTransmissibleOfCities?.map(
									(resume) => resume.city.locale
								) || []
							}
							series={[
								{
									name: "Atendidos",
									data:
										resumeDiseasesChronicNotTransmissibleOfCities?.map(
											(resume) =>
												resume.totalAttendancesCitizensWithHypertension
										) || [],
								},
								{
									name: "Não atendidos",
									data:
										resumeDiseasesChronicNotTransmissibleOfCities?.map(
											(resume) =>
												resume.totalHypertension -
												resume.totalAttendancesCitizensWithHypertension
										) || [],
								},
							]}
							paddingleft={0}
							paddingRight={0}
							isStacked
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}

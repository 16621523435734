import { useState } from "react";

import dayjs from "dayjs";

import { FaEye } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { DiseaseClasssificationEnum } from "@/utils/enums/DiseaseClassificationEnum";
import { Paginated } from "@/@types/paginated";
import { DiseaseData } from "..";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";
import { TableSkeletons } from "@/components/TableSkeletons";
import { ModalCitizensWithDiseases } from "./ModalCitizensWithDiseases";

type Props = {
	diseaseData: Paginated<DiseaseData>;
	isLoading: boolean;
};

export function TableCitizensWithDiseases({ diseaseData, isLoading }: Props) {
	const [selectedCitizenWithDisease, setSelectedCitizenWithDisease] = useState<DiseaseData>(
		{} as DiseaseData
	);
	const [showModalCitizensWithDiseases, setShowModalCitizensWithDiseases] = useState(false);
	const handleShowModalCitizensWithDiseases = () => {
		setShowModalCitizensWithDiseases(true);
	};
	const handleCloseModalCitizensWithDiseases = () => {
		setShowModalCitizensWithDiseases(false);
	};

	function treatmentIsOver(item: DiseaseData) {
		return !item.currentStatusDisease &&
			item.lastIndividualRecordWithDiseaseNoDate &&
			(!item.lastProblemDate ||
				dayjs(item.lastIndividualRecordWithDiseaseNoDate).isSameOrAfter(
					dayjs(item.lastProblemDate)
				))
			? dayjs(item.lastIndividualRecordWithDiseaseNoDate).format("DD/MM/YYYY")
			: "-";
	}

	function reasonForIdentification(item: DiseaseData) {
		return item.firstProblemDate && item.firstIndividualRecordDate
			? dayjs(item.firstProblemDate).isAfter(dayjs(item.firstIndividualRecordDate))
				? "FAI"
				: "FCI"
			: item.firstProblemDate
			? "FAI"
			: "FCI";
	}

	function treatmentClassificationClassColor(status: number) {
		switch (status) {
			case DiseaseClasssificationEnum.RECOVERD:
				return "text-primary";

			case DiseaseClasssificationEnum.UNDERTREATMENT:
				return "text-warning";

			case DiseaseClasssificationEnum.UNTREATEDMENT:
				return "text-danger";
			default:
				return "";
		}
	}

	return (
		<div>
			<ModalCitizensWithDiseases
				selectedCitizenDiseaseData={selectedCitizenWithDisease}
				show={showModalCitizensWithDiseases}
				onHide={handleCloseModalCitizensWithDiseases}
			/>
			<LegendData
				titles={["Resolvido", "Em acompanhamento", "Não acompanhado"]}
				ClassNamesColors={["text-primary", "text-warning", "text-danger"]}
			/>
			<Table responsive className="bg-white" bordered hover>
				<thead className="fw-bolder text-muted">
					<tr>
						<th className="text-center text-nowrap">
							<label className="me-2">ENCERRAMENTO</label>
							<CardOverlayTrigger message="Encerrado quando a última FCI sem o problema é igual ou posterior à última FCI com o problema e o status da doença é inativo" />
						</th>
						<th className="text-center">NOME</th>
						<th className="text-center">DN</th>
						<th className="text-center text-nowrap">
							<label className="me-2">MOTIVO</label>
							<CardOverlayTrigger message="Motivo da primeira identificação se foi FCI ou FAI." />
						</th>
						<th className="text-center text-nowrap">
							<label className="me-2">1ª FCI</label>
							<CardOverlayTrigger message="Primeira vez que o cidadão foi identificado por FCI." />
						</th>
						<th className="text-center text-nowrap">
							<label className="me-2">ÚlTIMA FCI</label>
							<CardOverlayTrigger message="Última vez que o cidadão foi identificado por FCI." />
						</th>
						<th className="text-center text-nowrap">
							<label className="me-2">Nº DE ATENDIMENTOS</label>
							<CardOverlayTrigger message="Nº de atendimentos, independente do período filtrado." />
						</th>

						<th className="text-center text-nowrap">
							<label className="me-2">1º ATENDIMENTO</label>
							<CardOverlayTrigger message="Primeiro atendimento do cidadão com o problema, independente do período filtrado." />
						</th>

						<th className="text-center text-nowrap">
							<label className="me-2">ÚLTIMO ATENDIMENTO</label>
							<CardOverlayTrigger message="Último atendimento do cidadão com o problema, independente do período filtrado." />
						</th>
						<th className="text-center">AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{diseaseData.data && !isLoading ? (
						diseaseData.data?.map((item, index) => (
							<tr
								key={index}
								className={`${
									item?.status && treatmentClassificationClassColor(item?.status)
								}`}
							>
								<td
									title="Encerramento"
									className="align-content-center text-center"
								>
									{treatmentIsOver(item)}
								</td>
								<td title="Nome" className="align-content-center text-nowrap ps-2">
									{item.citizen.fullName}
								</td>
								<td className="align-content-center ps-2" title={item.citizen.age}>
									{dayjs(item.citizen.birthdate).format("DD/MM/YYYY")}
								</td>
								<td
									className="align-content-center text-center ps-2"
									title="Motivo da identificação"
								>
									{reasonForIdentification(item)}
								</td>

								<td className="align-content-center text-center">
									{item.firstIndividualRecordDate
										? dayjs(item.firstIndividualRecordDate).format("DD/MM/YYYY")
										: "-"}
								</td>

								<td className="align-content-center text-center">
									{item.lastIndividualRecordWithDiseaseYesDate
										? dayjs(item.lastIndividualRecordWithDiseaseYesDate).format(
												"DD/MM/YYYY"
										  )
										: "-"}
								</td>
								<td className="align-content-center text-center">
									{item.problems?.length}
								</td>

								<td className="align-content-center text-center">
									{item.firstProblemDate
										? dayjs(item.firstProblemDate).format("DD/MM/YYYY")
										: "-"}
								</td>
								<td className="align-content-center text-center">
									{item.lastProblemDate
										? dayjs(item.lastProblemDate).format("DD/MM/YYYY")
										: "-"}
								</td>
								<td className="align-content-center text-center">
									<Button
										className="bg-primary py-1 px-3"
										onClick={() => {
											handleShowModalCitizensWithDiseases();
											setSelectedCitizenWithDisease(item);
										}}
									>
										<FaEye className="fs-4" />
									</Button>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

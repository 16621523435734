import { useEffect } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsUpload } from "react-icons/bs";

import { useApi } from "@/hooks/useApi";
import { TrackFile } from "@/@types/TrackFile";

import { CardDropzone } from "@/components/CardDropzone";
import { TrackFileFormData } from "@/pages/TrackListManagement/FormTrackFile";

type Props = ModalProps & {
	selectedTrackFile: TrackFile;
	fetchTrackList: () => Promise<void>;
};

export function ModalTrackFileImageEdit({ selectedTrackFile, fetchTrackList, ...rest }: Props) {
	const api = useApi();
	const initialValues = {} as TrackFileFormData;
	const formik = useFormik<TrackFileFormData>({
		initialValues,
		onSubmit: handleUpdateTrackFileImage,
	});

	async function handleUpdateTrackFileImage() {
		if (!formik.values.thumbnailFile && !formik.values.imageFile) return;
		const formData = new FormData();
		if (formik.values.thumbnailFile) {
			formData.append("thumbnailFile", formik.values.thumbnailFile);
		}
		if (formik.values.imageFile) {
			formData.append("imageFile", formik.values.imageFile);
		}
		formData.append("id", selectedTrackFile.id?.toString() || "");
		try {
			const response = await api.post("/trackfile/v1/updateimages", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			if (response?.status === 200) {
				toast.success("Arquivos atualizados com sucesso");
			} else {
				toast.error("Erro ao registrar arquivo");
			}
		} catch (error) {
			console.log(error);
			toast.error("Erro ao registrar arquivo");
		}
	}

	useEffect(() => {
		if (selectedTrackFile) {
			formik.setValues({
				description: selectedTrackFile.description,
				title: selectedTrackFile.title,
			} as TrackFileFormData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTrackFile]);

	return (
		<Modal size="lg" scrollable {...rest}>
			<ToastContainer />
			<Modal.Header closeButton>
				<Modal.Title>Edição de item de playlist</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
					<Row className="my-3">
						<h5>Título: {selectedTrackFile.title}</h5>
						<h5>Descrição: {selectedTrackFile.description}</h5>
					</Row>
					<Row>
						<Col lg={6} className="mb-6">
							<Form.Group className="text-center bg-white">
								<Form.Label className="fs-5">
									<strong>Thumbnail</strong>
								</Form.Label>
								<CardDropzone
									inputName="thumbnailFile"
									description="Solte o arquivo de thumbnail aqui, para fazer o upload."
									acceptedFileType={{ "image/*": [".jpeg", ".png"] }}
									descriptionAcceptedFilesExtension="Upload somente de arquivo .jpg/.png (tamanho máximo: 10MB)"
									handleChange={(file: File) =>
										formik.setFieldValue("thumbnailFile", file)
									}
								/>
								{selectedTrackFile.urlThumbnail && !formik.values.thumbnailFile && (
									<div>
										<img
											height="100px"
											src={selectedTrackFile.urlThumbnail}
											alt=""
										/>
									</div>
								)}
							</Form.Group>
						</Col>
						<Col lg={6} classname="mb-6">
							<Form.Group className="text-center bg-white">
								<Form.Label className="fs-5">
									<strong>Imagem de capa alternativa</strong>
								</Form.Label>
								<CardDropzone
									inputName="imageFile"
									description="Solte o arquivo alternativo aqui, para fazer o upload."
									acceptedFileType={{ "image/*": [".jpeg", ".png"] }}
									descriptionAcceptedFilesExtension="Upload somente de arquivo .jpg/.png (tamanho máximo: 10MB)"
									handleChange={(file: File) =>
										formik.setFieldValue("imageFile", file)
									}
								/>
								{selectedTrackFile.urlImage && !formik.values.imageFile && (
									<div>
										<img
											height="100px"
											src={selectedTrackFile.urlImage}
											alt=""
										/>
									</div>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<div className="d-flex my-3 justify-content-center w-100">
							<Button
								className="ml-auto"
								type="submit"
								disabled={!formik.values.imageFile && !formik.values.thumbnailFile}
							>
								<BsUpload className="me-2" /> Upload
							</Button>
						</div>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

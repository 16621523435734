import Table from "react-bootstrap/Table";

import { removeDuplicates } from "@/utils/genericals";
import { Vaccination } from "@/@types/Vaccination";

import { IndicatorModalCard } from "@/components/IndicatorModalCard";

export type VaccinationCardData = Vaccination & {
	status?: "vaccinated" | "onTime" | "pending" | "delayed";
};

type Props = {
	vaccinations: VaccinationCardData[];
};

export function VaccinationCard({ vaccinations }: Props) {
	const immunobiologicalDosageNames = removeDuplicates<string[]>(
		vaccinations
			.map((vaccination) => vaccination.immunobiologicalDosage.acronym)
			.sort((a, b) => a.localeCompare(b))
	);
	const immunobiologicalIdentifiers = removeDuplicates<string[]>(
		vaccinations.map((vaccination) => vaccination.immunobiological.identifier?.toString() || "")
	);

	return (
		<section className="overflow-auto">
			<Table className="w-100 h-100" responsive>
				<thead className="w-100">
					<tr>
						<th
							colSpan={1 + (vaccinations?.length || 0)}
							className="text-center text-white bg-primary fs-6"
						>
							CARTÃO DE VACINAÇÃO
						</th>
					</tr>
				</thead>
				<tbody className="w-100 h-100">
					{immunobiologicalIdentifiers?.map((identifier, index) => (
						<tr
							key={`immunobiological-${index}`}
							className="border border-2 w-100 h-100"
						>
							<td className="align-middle text-center fw-bolder p-2">
								{vaccinations
									.find(
										(vaccine) =>
											vaccine.immunobiological.identifier ===
											String(identifier)
									)
									?.immunobiological.name.toUpperCase()}
							</td>
							{immunobiologicalDosageNames?.map((immunobiologicalDosage, index) => (
								<td key={`dosage-name-${index}`}>
									<div className="d-flex justify-content-center flex-column gap-2">
										{vaccinations
											?.filter(
												(vaccination) =>
													vaccination.immunobiological.identifier ===
														identifier &&
													vaccination.immunobiologicalDosage.acronym ===
														immunobiologicalDosage
											)
											.map((item, index) => (
												<IndicatorModalCard
													key={`card-${index}`}
													theme={
														item.status === "vaccinated"
															? "success"
															: item.status === "onTime"
															? "primary"
															: item.status === "pending"
															? "warning"
															: item.status === "delayed"
															? "danger"
															: "success"
													}
													title={item.immunobiologicalDosage?.name}
													subtitle={item.dateofVaccine ?? ""}
													subtitleHeading=""
													style={{
														minHeight: "13rem",
														minWidth: "13rem",
														maxHeight: "13rem",
														maxWidth: "13rem",
													}}
												>
													<span className="d-flex flex-column fs-8 text-gray-900 fw-bolder">
														<label className="fs-9 fw-light ms-2 text-gray-900">
															{item.professional?.name}
														</label>
													</span>
													<span className="d-flex flex-column fs-8 text-gray-900 fw-bolder">
														<hr />
														<label className="fs-9 fw-light ms-2 text-gray-900">
															{item.healthUnit?.name}
														</label>
													</span>
												</IndicatorModalCard>
											))}
									</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</section>
	);
}

import { FC } from "react";

const Error401: FC = () => {
	return (
		<>
			<h1 className="fw-bolder fs-4x text-gray-700 mb-10">Acesso não autorizado</h1>

			<div className="fw-bold fs-3 text-gray-400 mb-15">
				A página que você está procurando Precisa de liberação de acesso pelo seu superior!{" "}
				<br /> Solicite a liberação e tente novamente.
			</div>
		</>
	);
};

export { Error401 };

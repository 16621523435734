import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLayout } from "@/../../src/_metronic/layout/core";

import { useApi } from "@/hooks/useApi";
import { TrackOrder } from "@/@types/TrackOrder";
import { TrackList } from "@/@types/TrackList";

import { Skeleton } from "@/components/Skeleton";
import { CarouselImageProps, CarouselImages } from "./CarouselImages";
import { Banner } from "@/components/Banner";
import { ContinueWatching } from "./ContinueWatching";

import "./styles.scss";

export function InstitutionalPlataform() {
	const navigate = useNavigate();
	const api = useApi();
	const { setTitle } = useLayout();
	const [isLoadingTrackOrders, setIsLoadingTrackOrders] = useState(false);

	const [trackOrders, setTrackOrders] = useState<TrackOrder[]>([]);
	const trackLists = trackOrders
		.map((trackOrder) => trackOrder.trackList!)
		.reduce((accumulator: TrackList[], current: TrackList) => {
			return accumulator.find((item) => item.id === current.id)
				? accumulator
				: [...accumulator, current];
		}, []);

	async function handleTrackOrders() {
		setIsLoadingTrackOrders(true);
		try {
			const { data } = await api.get<TrackOrder[]>("/trackorder/v1/All");
			setTrackOrders(data || []);
		} catch (error) {
			console.log(error);
			setTrackOrders([]);
		} finally {
			setIsLoadingTrackOrders(false);
		}
	}

	const trackListImages: CarouselImageProps[] = trackLists.map<CarouselImageProps>(
		(trackList) => {
			return {
				url: trackList?.urlThumbnail,
				title: trackList?.title,
				description: trackList?.description,
				handleClick: () =>
					navigate(
						`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackList?.id}/video/1`
					),
			} as CarouselImageProps;
		}
	);

	function FilterTrackOrdersByTrackListId(trackOrders: TrackOrder[], trackListId?: number) {
		const trackOrdersFiltered: TrackOrder[] = trackOrders
			.filter((trackOrder) => trackOrder.trackListId === trackListId)
			.sort(
				(trackOrderA, trackOrderB) => trackOrderA.sequenceOrder - trackOrderB.sequenceOrder
			);
		return trackOrdersFiltered;
	}

	useEffect(() => {
		setTitle("AMBIENTE DE APOIO");
		handleTrackOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div style={{ backgroundColor: "#1b1b1b" }}>
			<section className="p-0">
				<Banner />
			</section>
			<section>
				<ContinueWatching />
			</section>
			<section className="mt-5 rounded ">
				<article className="p-3">
					<h1 className="text-white font-roboto">Principais conteúdos recomendados</h1>
					<h2 className="fs-6 text-muted font-roboto">
						Apresentamos a seguir todas as trilhas nas quais disponibilizamos
						compilações de vídeos relacionados à temática. Não perca a oportunidade de
						expandir seus conhecimentos e aprimorar a qualidade dos seus atendimentos na
						atenção primária à saúde. Clique para assistir e explore o rico conteúdo que
						preparamos para você.
					</h2>
				</article>
				<CarouselImages
					isLoading={isLoadingTrackOrders}
					carouselImagesProps={trackListImages}
				/>
			</section>
			{!isLoadingTrackOrders ? (
				trackLists.map((trackList, index) => (
					<section key={index} className="mt-5 rounded">
						<article className="p-3">
							<h1 className="font-roboto text-white">{trackList?.title}</h1>
							<h2 className="fs-6 text-muted">{trackList?.description}</h2>
						</article>
						<CarouselImages
							isLoading={isLoadingTrackOrders}
							carouselImagesProps={FilterTrackOrdersByTrackListId(
								trackOrders,
								trackList?.id
							)
								.filter((trackOrder) => trackOrder.trackFile?.urlThumbnail)
								.map<CarouselImageProps>((trackOrder) => {
									return {
										url: trackOrder.trackFile?.urlThumbnail || "",
										title: trackOrder.trackFile?.title,
										description: trackOrder.trackFile?.description,
										handleClick: () => {
											navigate(
												`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackOrder?.trackListId}/video/${trackOrder.sequenceOrder}`
											);
										},
									};
								})}
						/>
					</section>
				))
			) : (
				<section className="mt-5 rounded">
					<article className="p-3">
						<Skeleton height={20} width="50%" />
						<Skeleton height={15} width="20%" />
					</article>
					<CarouselImages
						isLoading={isLoadingTrackOrders}
						carouselImagesProps={Array(6)}
					/>
				</section>
			)}
		</div>
	);
}

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { Chart } from "@/@types/Chart";

type RadialChartSerie = number;
type RadialChartProps = Chart<RadialChartSerie> & {
	series: number[];
	labels: string[];
	colors?: string[];
};

export function DonutChart({ series, labels, height, width, colors }: RadialChartProps) {
	const options: ApexOptions = {
		dataLabels: {
			enabled: true,
		},
		plotOptions: {
			pie: {
				donut: { labels: { show: true, total: { showAlways: true, show: true } } },
			},
		},
		colors: colors,
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						show: false,
					},
				},
			},
		],
		labels: labels,
		legend: {
			position: "right",
			offsetY: 0,
			height: 230,
		},
	};
	return (
		<ReactApexChart
			options={options}
			series={series}
			type="donut"
			height={height}
			width={width}
		/>
	);
}

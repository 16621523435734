import { FC, useLayoutEffect, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";

import { FaClock, FaKey } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { getCityLicenseStatus } from "@/utils/cityLicenseStatus";

import { HeaderUserMenu } from "../../../partials";
import { BackgroundLetterAvatars } from "@/components/AvatarUser";
import { ModalNewCityLicense } from "@/components/ModalNewCityLicense";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
	toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
	const { user, currentAccount, uf } = useCurrentAccount();
	const [width, setWidth] = useState(window.innerWidth);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const userName = user.fullName?.split(" ", 2);

	const haveActiveTemporaryLicense =
		currentAccount.currentCityLicense &&
		getCityLicenseStatus(currentAccount.currentCityLicense) === "ativo" &&
		currentAccount.currentCityLicense?.endDate;

	useLayoutEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
	}, []);

	return (
		<div className="d-flex align-items-stretch flex-shrink-0 ">
			<ModalNewCityLicense handleClose={handleClose} show={show} onHide={handleClose} />
			{userName && (
				<div className="d-flex align-items-center ">
					{!currentAccount?.ibgeCode
						? !user.isAdmin &&
						  !uf && (
								<Button
									variant="warning"
									className="btn-sm me-10 text-black"
									style={{ opacity: "0.7" }}
									onClick={handleShow}
								>
									<FaKey className="mx-2" />
									<strong>Solicitar Acesso</strong>
								</Button>
						  )
						: haveActiveTemporaryLicense && (
								<Badge
									title="Periodo de licença temporário"
									className="bg-warning fs-5 px-2 me-5"
								>
									<FaClock className="me-1" />
									<label className="px-3">
										{dayjs(currentAccount.currentCityLicense?.endDate).diff(
											dayjs(),
											"days"
										)}{" "}
										D
									</label>
								</Badge>
						  )}
					{width > 1000 && (
						<span className="align-items-center text-primary mt-7 mb-5 ">
							Olá, {userName[0]} {userName[1]}
						</span>
					)}
					<div
						className={clsx("align-items-center", toolbarButtonMarginClass)}
						id="kt_header_user_menu_toggle"
					>
						<div
							className={clsx("cursor-pointer symbol", toolbarUserAvatarHeightClass)}
							data-kt-menu-trigger="click"
							data-kt-menu-attach="parent"
							data-kt-menu-placement="bottom-end"
							data-kt-menu-flip="bottom"
						>
							<BackgroundLetterAvatars fullName={user.fullName} />
						</div>
						<HeaderUserMenu />
					</div>
				</div>
			)}
		</div>
	);
};

export { Topbar };

import React, { useState } from "react";

export type DragAndDropListItem = {
	id: string;
	element: React.JSX.Element;
};

type Props = {
	items: DragAndDropListItem[];
	onDragClassName?: string;
	handleChange: (currentId: string, destinationId: string) => void;
};

export function DragAndDropList({ items, onDragClassName, handleChange }: Props) {
	const [dropIndicator, setDropIndicator] = useState<string | null>();

	function handleDragStart(e: React.DragEvent<HTMLElement>, currentId: string) {
		e.dataTransfer.setData("text/plain", currentId);
	}

	function handleDragEnd(e: React.DragEvent<HTMLElement>) {
		e.dataTransfer.clearData();
		setDropIndicator(null);
	}

	async function handleDrop(e: React.DragEvent<HTMLElement>, destinationId: string) {
		e.preventDefault();
		const currentId = e.dataTransfer.getData("text/plain");
		if (destinationId && currentId !== destinationId) {
			handleChange(currentId, destinationId);
		}
	}

	function handleDragOver(e: React.DragEvent<HTMLElement>) {
		e.preventDefault();
		setDropIndicator(e.currentTarget.id ? e.currentTarget.id : null);
	}

	const dragElements = items.map(
		(item, index) =>
			({
				...item.element,
				props: {
					...item.element.props,
					key: `draggable-item-${index}`,
					draggable: true,
					onDragOver: handleDragOver,
					onDrop: (event: React.DragEvent<HTMLElement>) => handleDrop(event, item.id),
					onDragStart: (event: React.DragEvent<HTMLElement>) =>
						handleDragStart(event, item.id),
					onDragEnd: handleDragEnd,
					className: `cursor-move ${item.element.props.className} ${
						dropIndicator ? onDragClassName : ""
					}`,
				},
			} as React.JSX.Element)
	);

	return <>{dragElements}</>;
}

import { useState } from "react";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Professional } from "@/@types/Professional";

import { ModalVisitsAcs } from "../ModalVisitsAcs.tsx";

type Props = {
	title: ApexTitleSubtitle | undefined;
	height?: number;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
	categories: Professional[];
	colors: string[];
	startDate: string;
	endDate: string;
};

export function VisitByAcsChart({
	series,
	categories,
	colors,
	title,
	height,
	startDate,
	endDate,
}: Props) {
	const [selectedProfessional, setSelectedProfessional] = useState<Professional>(
		{} as Professional
	);

	var visitAcs: ApexOptions = {
		title,
		chart: {
			type: "bar",
			stacked: true,
			toolbar: {
				show: true,
			},
			zoom: {
				enabled: true,
			},
			events: {
				dataPointSelection(event, chartContext, options) {
					setSelectedProfessional(categories[options?.dataPointIndex]);
					handleShowModalVisitsAcs();
				},
				dataPointMouseEnter: function (event) {
					event.target.style.cursor = "pointer";
				},
				xAxisLabelClick(event, chart, options?) {
					setSelectedProfessional(categories[options?.labelIndex]);
					handleShowModalVisitsAcs();
				},
			},
		},
		colors: colors,
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 10,
				borderRadiusApplication: "end",
				borderRadiusWhenStacked: "last",
				barHeight: "70%",
				dataLabels: {
					total: {
						enabled: true,
						style: {
							fontSize: "13px",
							fontWeight: 900,
						},
					},
				},
			},
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: 200 },
		},
		xaxis: {
			type: "category",
			categories: categories.map((category) => category.name),
			labels: {
				show: true,
				formatter: function (value) {
					return JSON.stringify(value);
				},
			},
		},
		legend: {
			position: "top",
		},
		fill: {
			opacity: 1,
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					chart: {
						height: height,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: true,
						labels: { align: "left", style: { fontSize: "14px" } },
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	const [showModalVisitsAcs, setShowModalVisitsAcs] = useState(false);
	const handleShowModalVisitsAcs = () => {
		setShowModalVisitsAcs(true);
	};
	const handleCloseModalVisitsAcs = () => {
		setShowModalVisitsAcs(false);
	};

	return (
		<div className="bg-white mt-10" style={{ maxHeight: "50rem", overflowY: "auto" }}>
			<ModalVisitsAcs
				show={showModalVisitsAcs}
				onHide={handleCloseModalVisitsAcs}
				startDate={startDate}
				endDate={endDate}
				selectedProfessional={selectedProfessional}
			/>
			<ReactApexChart options={visitAcs} series={series} type="bar" height={height} />
		</div>
	);
}

import { useState } from "react";
import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { PregnantPatient } from "@/@types/PregnantPatient";
import { Paginated } from "@/@types/paginated";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { TableSkeletons } from "@/components/TableSkeletons";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator1 } from "../ModalIndicator1";

import "./styles.scss";

type Props = {
	pregnantPatient: Paginated<PregnantPatient>;
	isLoading: boolean;
};

export function TableIndicator1({ isLoading, pregnantPatient }: Props) {
	const [patientSelected, setPatientSelected] = useState<PregnantPatient>({} as PregnantPatient);
	const [showModalAttendancePregnantWomen, setShowModalAttendancePregnantWomen] = useState(false);
	const handleShowModalAttendancePregnantWomen = () => {
		setShowModalAttendancePregnantWomen(true);
	};
	const handleCloseModalAttendancePregnantWomen = () => {
		setShowModalAttendancePregnantWomen(false);
	};
	return (
		<div>
			<ModalIndicator1
				show={showModalAttendancePregnantWomen}
				onHide={handleCloseModalAttendancePregnantWomen}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						<th>
							<CitizenSyncLabel />
						</th>
						<th className="white-space-nowrap">CIDADÃO</th>
						<th className="white-space-nowrap">DN</th>
						<th className="white-space-nowrap">CPF/CNS</th>
						<th className="white-space-nowrap">EQUIPE</th>
						<th>
							<abbr title="Micro Área">MC</abbr>
						</th>
						<th className="white-space-nowrap">
							<abbr title="Data da última menstruação">DUM</abbr>
						</th>
						<th className="white-space-nowrap">
							<abbr title="Data primeiro atendimento">1º ATENDIMENTO</abbr>
						</th>
						<th className="white-space-nowrap">Nº DE CONSULTAS</th>
						<th className="white-space-nowrap">
							<abbr title="Idade gestacional na primeira consulta (IG)">
								IG 1º ATENDIMENTO
							</abbr>
						</th>
						<th className="white-space-nowrap">
							<abbr title="Data provável do parto (DPP)">DPP</abbr>
						</th>
						<th className="text-end no-print no-excel">AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && pregnantPatient?.data ? (
						pregnantPatient?.data?.map((patient, index) => {
							const numberOfAttendances = patient.attendances?.length;
							return (
								<tr key={`patient-pregnant-${index}`}>
									<td title="Sincronização">
										<PremilinaryReportStatusIcon
											status={patient.preliminaryReportStatus}
										/>
									</td>
									<td className="text-nowrap" title="Nome completo">
										{patient?.fullName || "NÃO INFORMADO"}
									</td>
									<td className="text-nowrap">
										{patient.birthdate
											? dayjs(patient?.birthdate).format("DD/MM/YYYY")
											: "NÃO INFORMADO"}
									</td>
									<td className="text-nowrap" title="CPF/CNS">
										{identifierOrNationalHealthCard(patient) || "NÃO INFORMADO"}
									</td>
									<td className="text-nowrap" title="Equipe">
										{patient.healthTeam?.name || "NÃO INFORMADO"}
									</td>
									<td className="text-nowrap text-center" title="Micro Área">
										{patient.area || "NÃO INFORMADO"}
									</td>
									<td
										className="text-nowrap"
										title="Data da última menstruação (DUM)"
									>
										{displayFirstDumDate(patient.firstDumDate) ||
											"NÃO INFORMADO"}
									</td>
									<td
										title="Data do primeiro atendimento"
										className="text-nowrap"
									>
										{patient.firstAttendanceDate
											? dayjs(patient.firstAttendanceDate).format(
													"DD/MM/YYYY"
											  )
											: "NÃO INFORMADO"}
									</td>

									<td className="text-nowrap" title="Número de consultas">
										{displaySingularOrPlural(numberOfAttendances, "consulta") ||
											"NÃO INFORMADO"}
									</td>
									<td
										className="text-nowrap"
										title="Idade gestacional na primeira consulta (IG)"
									>
										{patient.gestationalAge1St
											? displaySingularOrPlural(
													patient.gestationalAge1St,
													"semana"
											  )
											: "NÃO INFORMADO"}
									</td>
									<td
										className="text-nowrap"
										title="Data provável do parto (DPP)"
									>
										{dayjs(patient.dppDate).format("DD/MM/YYYY") ||
											"NÃO INFORMADO"}
									</td>
									<td className="text-end no-print">
										<ViewCitizenButton
											onClick={() => {
												setPatientSelected(patient);
												handleShowModalAttendancePregnantWomen();
											}}
										/>
									</td>
								</tr>
							);
						})
					) : (
						<TableSkeletons numberOfCells={12} height={20} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import { ToastContainer, toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import { FaPen, FaPlusCircle } from "react-icons/fa";
import { MdAddAPhoto } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { useApi } from "@/hooks/useApi";
import { TrackList } from "@/@types/TrackList";
import { TrackOrder } from "@/@types/TrackOrder";
import { TrackFile } from "@/@types/TrackFile";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ModalTrackFileEdit } from "@/pages/TrackListManagement/TrackListDetails/ModalTrackFileEdit";
import { ModalTrackFileImageEdit } from "@/pages/TrackListManagement/TrackListDetails/ModalTrackFileImageEdit";
import { DragAndDropList, DragAndDropListItem } from "@/components/DragAndDropList";

export function TrackListDetails() {
	const api = useApi();
	const navigate = useNavigate();
	const { trackListId } = useParams();
	const [selectedFile, setSelectedFile] = useState<TrackFile>({} as TrackFile);
	const [trackList, setTrackList] = useState<TrackList>({} as TrackList);
	const [trackOrders, setTrackOrders] = useState<TrackOrder[]>([]);
	const [showModalTrackFileEdit, setShowModalTrackFileEdit] = useState(false);
	const [showModalTrackFileImageEdit, setShowModalTrackFileImageEdit] = useState(false);
	const handleCloseModalTrackFileEdit = () => setShowModalTrackFileEdit(false);
	const handleShowModalTrackFleEdit = () => setShowModalTrackFileEdit(true);

	async function getTrackList(trackListId: number) {
		try {
			const { data } = await api.get<TrackList>("/tracklist/v1/GetById", {
				params: { trackListId },
			});
			return data || ({} as TrackList);
		} catch (error) {
			console.log(error);
			return {} as TrackList;
		}
	}

	async function fetchTrackList() {
		if (!trackListId || !Number(trackListId)) return;
		setTrackList(await getTrackList(Number(trackListId)));
	}

	async function getTrackOrders(trackListId: number) {
		try {
			const { data } = await api.get<TrackOrder[]>("/trackorder/v1/GetByTrackListId", {
				params: { trackListId: trackListId },
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchTrackOrders() {
		if (!trackListId || !Number(trackListId)) return;
		setTrackOrders(await getTrackOrders(Number(trackListId)));
	}

	async function removeTrackOrder(trackOrderId: number) {
		await api.post("/trackorder/v1/delete", { trackOrderId });
	}

	async function handleRemoveTrackOrder(trackOrderId?: number) {
		if (!trackOrderId) return;
		try {
			await removeTrackOrder(trackOrderId);
			fetchTrackOrders();
			toast.success("Video removido da playlist com sucesso");
		} catch (error) {
			toast.error("Erro ao remover vídeo da playlist");
			console.log(error);
		}
	}

	async function updateOrderTrackOrder(
		trackListId: number,
		trackOrderId: number,
		newSequenceOrder: number
	) {
		await api.post("/trackorder/v1/updateorder", {
			trackListId,
			trackOrderId,
			newSequenceOrder,
		});
	}

	async function handleChangeOrder(oldSequenceOrder: string, newSequenceOrder: string) {
		const trackOrder = trackOrders.find(
			(trackOrder) => trackOrder.sequenceOrder.toString() === oldSequenceOrder
		);
		if (!trackOrder || !trackOrder.id) return;
		try {
			await updateOrderTrackOrder(
				trackOrder.trackListId,
				trackOrder.id,
				Number(newSequenceOrder)
			);
			fetchTrackOrders();
			toast.success("Ordem do vídeo da playlist atualizada com sucesso");
		} catch (error) {
			toast.error("Erro ao atualizar ordem do vídeo da playlist");
			console.log(error);
		}
	}

	const dragAndDropItems = trackOrders.map((trackOrder, index) => {
		return {
			id: trackOrder.sequenceOrder.toString(),
			element: (
				<tr className="text-muted" key={`draggable-trackorder-item-${index}`}>
					<td style={{ position: "relative" }}>
						<img
							className="d-inline-block"
							height={100}
							loading="lazy"
							src={trackOrder.trackFile?.urlThumbnail}
							alt={trackOrder.trackFile?.title}
						/>
						<div style={{ position: "absolute", bottom: 16, right: 16 }}>
							<button
								title="Editar imagem"
								onClick={() => {
									setSelectedFile(trackOrder?.trackFile || ({} as TrackFile));
									setShowModalTrackFileImageEdit(true);
								}}
								className="rounded-circle"
								style={{ width: "30px", height: "30px" }}
							>
								<MdAddAPhoto />
							</button>
						</div>
					</td>
					<td>{trackOrder.trackFile?.title}</td>
					<td>{trackOrder.trackFile?.description}</td>
					<td>
						{dayjs("0")
							.add(trackOrder.trackFile?.duration || 0, "seconds")
							.format("HH:mm:ss")}
					</td>
					<td className="border-0">
						<div className="d-flex align-items-center justify-content-center">
							<Button
								title="Editar"
								variant="warning"
								onClick={() => {
									handleShowModalTrackFleEdit();
									setSelectedFile(trackOrder?.trackFile || ({} as TrackFile));
								}}
								className="m-2"
							>
								<FaPen className="me-2" />
							</Button>
							<Button
								title="Remover"
								variant="danger"
								onClick={() => handleRemoveTrackOrder(trackOrder.id)}
							>
								<BsTrash />
							</Button>
						</div>
					</td>
				</tr>
			),
		} as DragAndDropListItem;
	});

	useEffect(() => {
		fetchTrackList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (trackList.id) {
			fetchTrackOrders();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackList]);

	return (
		<section className="bg-white p-4">
			<ToastContainer />
			<ModalTrackFileEdit
				show={showModalTrackFileEdit}
				onHide={handleCloseModalTrackFileEdit}
				fetchTrackList={fetchTrackList}
				selectedTrackFile={selectedFile}
			/>
			<ModalTrackFileImageEdit
				show={showModalTrackFileImageEdit}
				onHide={() => setShowModalTrackFileImageEdit(false)}
				fetchTrackList={fetchTrackList}
				selectedTrackFile={selectedFile}
			/>
			<Row>
				<div className="d-flex justify-content-between">
					<h1 className="fs-4 text-primary mb-5">Gestão de playlist</h1>
					<Button
						onClick={() => navigate(`/configuracoes/track-lists/${trackListId}/novo`)}
						variant="primary"
					>
						<FaPlusCircle /> Novo
					</Button>
				</div>
			</Row>

			<Row className="mt-2 mb-10">
				<div className="d-flex flex-row">
					<div className="me-4">
						<img
							height={150}
							loading="lazy"
							src={trackList.urlThumbnail}
							alt={trackList?.title}
						/>
					</div>
					<div className="my-4">
						<div className="d-flex align-items-center justify-content-between">
							<h2 className="text-muted fs-5">
								<label className="text-black me-2">Titulo:</label>
								{trackList.title}
							</h2>
						</div>
						<h2 className="mt-2 fs-5 text-muted">
							<label className="text-black me-2">Descrição:</label>
							{trackList.description}
						</h2>
					</div>
				</div>
			</Row>

			<Table
				striped
				responsive
				className="table align-middle fs-6 dataTable no-footer bg-white rounded"
			>
				<thead>
					<tr className="fw-bold text-center">
						<th>Capa</th>
						<th>Titulo</th>
						<th>Descrição</th>
						<th>Duração</th>
						<th>Ações</th>
					</tr>
				</thead>
				<tbody className="text-center">
					{trackOrders ? (
						<DragAndDropList
							items={dragAndDropItems}
							handleChange={handleChangeOrder}
						/>
					) : (
						<TableSkeletons numberOfCells={5} />
					)}
				</tbody>
			</Table>
		</section>
	);
}

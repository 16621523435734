import { useNavigate, useParams } from "react-router";

import dayjs from "dayjs";

import ListGroup from "react-bootstrap/ListGroup";

import { TrackFile } from "@/@types/TrackFile";

import "@/pages/InstitutionalPlatform/styles.scss";

type Props = {
	videos: TrackFile[];
	isLoading: boolean;
};

export function Playlist({ isLoading, videos }: Props) {
	const navigate = useNavigate();
	const { trackListId } = useParams();

	return (
		<ListGroup
			className="list-group"
			style={{
				height: "42rem",
				overflowY: "auto",
			}}
		>
			{videos.map((video, index) => (
				<ListGroup.Item
					key={`item-video-${index}`}
					className="d-flex zoom py-1 ps-1 bg-dark-blue cursor-pointer "
					onClick={() =>
						navigate(
							`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackListId}/video/${
								index + 1
							}`
						)
					}
				>
					<img
						height={82}
						width={120}
						className="rounded"
						alt="indicador"
						src={video.urlThumbnail}
					/>
					<div className="w-100 ps-2">
						<div className="d-flex justify-content-between">
							<h1 className="cursor-pointer px-1 fs-6 text-white ">{video.title}</h1>
						</div>
						<p className="px-1 fs-8 text-muted">
							{dayjs("0").add(video.duration, "seconds").format("HH:mm:ss")}
						</p>
					</div>
				</ListGroup.Item>
			))}
		</ListGroup>
	);
}

import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsCheck, BsEye, BsXCircle } from "react-icons/bs";
import { MdBlock } from "react-icons/md";
import { toast } from "react-toastify";
import { FaBook } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { getCityLicenseStatus } from "@/utils/cityLicenseStatus";
import { useApi } from "@/hooks/useApi";
import { Account } from "@/@types/Account";
import { CityLicense } from "@/@types/CityLicense";
import { User } from "@/@types/user";

import { BaseModalProps } from "../BaseModal";
import { ModalLicense } from "../ModalLicense";
import { NoRecordsFeedback } from "../NoRecordsFeedback";
import UserInformationModal from "./UserInformationModal";

type Props = BaseModalProps & {
	selectedCity: Account;
};

export function ModalLicenseManagement({ selectedCity, ...rest }: Props) {
	const api = useApi();
	const [cityLicenses, setCityLicenses] = useState<CityLicense[]>([]);
	const [userSelectedLicense, setSelectedUserLicense] = useState<User>();

	async function handleLicenseHistory() {
		try {
			const { data } = await api.get("/CityLicense/v1/GetByCity", {
				params: { ibgeCode: selectedCity.ibgeCode },
			});
			setCityLicenses(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function invalidateLicense(ibgeCode?: string) {
		if (!ibgeCode) return;
		try {
			await api.post("/CityLicense/v1/Invalidate", { ibgeCode: ibgeCode });
			toast.success(`Licença revogada com sucesso`, { autoClose: 3000 });
			handleLicenseHistory();
		} catch (error) {
			toast.error(`Erro ao revogar licença`, { autoClose: 3000 });
			console.log(error);
		}
	}

	async function acceptLicense(ibgeCode?: string) {
		if (!ibgeCode) return;
		try {
			await api.post("/CityLicense/v1/Activate", { ibgeCode: ibgeCode });
			toast.success(`Solicitação aceita com sucesso`, { autoClose: 3000 });
			handleLicenseHistory();
		} catch (error) {
			toast.error(`Erro ao aceitar solicitação`, { autoClose: 3000 });
			console.log(error);
		}
	}

	async function rejectLicense(ibgeCode?: string) {
		if (!ibgeCode) return;
		try {
			await api.post("/CityLicense/v1/Reject", { ibgeCode: ibgeCode });
			toast.success(`Solicitação rejeitada com sucesso`, { autoClose: 3000 });
			handleLicenseHistory();
		} catch (error) {
			toast.error(`Erro ao rejeitar solicitação`, { autoClose: 3000 });
			console.log(error);
		}
	}

	const [showModalLicense, setShowModalLicense] = useState(false);
	const handleShowModalLicense = () => {
		setShowModalLicense(true);
	};
	const handleCloseModalLicense = () => {
		setShowModalLicense(false);
		handleLicenseHistory();
	};

	const [showModalInfo, setShowModalInfo] = useState(false);

	const handleCloseModalInfo = () => setShowModalInfo(false);
	const handleShowModalInfo = () => setShowModalInfo(true);

	useEffect(() => {
		if (selectedCity?.ibgeCode) {
			handleLicenseHistory();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCity]);

	return (
		<Modal size="xl" scrollable {...rest}>
			<ModalLicense
				show={showModalLicense}
				onHide={handleCloseModalLicense}
				city={selectedCity}
			/>
			<UserInformationModal
				show={showModalInfo}
				onHide={handleCloseModalInfo}
				userSelectedLicense={userSelectedLicense}
			/>
			<Modal.Header closeButton>
				<Modal.Title>
					Histórico de Licenças
					<label className="d-flex text-primary fs-6 ">
						{[selectedCity?.locale, selectedCity?.federalUnit?.abbreviation]
							.filter((value) => value && value.trim().length > 0)
							.join(" - ")}
					</label>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex justify-content-end gap-2">
					<Button variant="secondary" onClick={handleShowModalLicense}>
						Nova Licença
					</Button>
				</div>

				<Table striped bordered hover className="m-3">
					<thead>
						<tr>
							<th className="text-center">
								<strong>DATA DE INICIO</strong>
							</th>
							<th className="text-center">
								<strong>DATA DE EXPIRAÇÃO</strong>
							</th>
							<th className="text-center">
								<strong>CPF SOLICITANTE</strong>
							</th>
							<th className="text-center">
								<strong>NOME SOLICITANTE</strong>
							</th>
							<th className="text-center">
								<strong>CARGO</strong>
							</th>
							<th className="text-center">
								<strong>TIPO DE LICENÇA</strong>
							</th>
							<th className="text-center">
								<strong>AÇÕES</strong>
							</th>
						</tr>
					</thead>

					<tbody>
						{cityLicenses.map((cityLicense, index) => (
							<tr key={index}>
								<td className="text-center">
									{cityLicense.startDate
										? dayjs(cityLicense.startDate).format("DD/MM/YYYY HH:mm")
										: "-"}
								</td>
								<td className="text-center">
									{cityLicense.endDate
										? dayjs(cityLicense.endDate).format("DD/MM/YYYY HH:mm")
										: "-"}
								</td>
								<td className="text-center">{cityLicense.user?.identifier}</td>
								<td className="text-center">{cityLicense.user?.fullName}</td>
								<td className="text-center">{cityLicense.user?.occupation}</td>
								<td className="text-center">{cityLicense.license?.name}</td>
								<td>
									{index === 0 &&
										getCityLicenseStatus(cityLicense) &&
										(getCityLicenseStatus(cityLicense) === "pendente" ? (
											<div className="d-flex">
												<Button
													className="me-2"
													title="Aprovar"
													variant="success"
													onClick={() =>
														acceptLicense(cityLicense?.city?.ibgeCode)
													}
												>
													<BsCheck className="fs-3" />
												</Button>
												<Button
													className="me-2"
													title="Rejeitar"
													variant="danger"
													onClick={() =>
														rejectLicense(cityLicense?.city?.ibgeCode)
													}
												>
													<BsXCircle className="fs-3" />
												</Button>
												<Button
													title="Informações"
													onClick={() => {
														handleShowModalInfo();
														setSelectedUserLicense(cityLicense.user);
													}}
												>
													<BsEye />
												</Button>
											</div>
										) : (
											getCityLicenseStatus(cityLicense) === "ativo" && (
												<div className="d-flex">
													<Button
														className="me-2"
														title="Invalidar licença"
														variant="danger"
														onClick={() =>
															invalidateLicense(
																cityLicense?.city?.ibgeCode
															)
														}
													>
														<MdBlock className="fs-3" />
													</Button>
													<Button
														title="Informações"
														onClick={() => {
															handleShowModalInfo();
															setSelectedUserLicense(
																cityLicense.user
															);
														}}
													>
														<BsEye className="fs-3" />
													</Button>
												</div>
											)
										))}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				{cityLicenses.length < 1 && (
					<div className="d-flex justify-content-center">
						<NoRecordsFeedback icon={<FaBook />} message={"Não Existe Licenças"} />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}

import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ToastContainer } from "react-toastify";
import { FaBook, FaCircle, FaHome, FaPen, FaSync } from "react-icons/fa";

import { useLayout } from "@/../_metronic/layout/core";
import { getCityLicenseStatus } from "@/utils/cityLicenseStatus";
import { useDebounce } from "@/hooks/useDebounce";
import { useApi } from "@/hooks/useApi";
import { Account } from "@/@types/Account";
import { Paginated } from "@/@types/paginated";
import { CityLicense } from "@/@types/CityLicense";

import { TableSkeletons } from "@/components/TableSkeletons";
import { SearchInput } from "@/components/SearchInput";
import { PaginationLinks } from "@/components/PaginationLinks";
import { ModalUpdateUnits } from "@/components/ModalUpdateUnits";
import { ModalCitiesEdit } from "@/components/ModalCitiesEdit";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { ModalLicenseManagement } from "@/components/ModalLicenseManagement";

export function CitiesManagement() {
	const api = useApi();
	const [isLoading, setIsLoading] = useState(false);
	const [paginatedCities, setPaginatedCities] = useState<Paginated<Account>>(
		{} as Paginated<Account>
	);
	const [pageNumber, SetPageNumber] = useState(1);
	const [cityName, setCityName] = useState<string>("");
	const [selectedCity, setSelectedCity] = useState<Account>({} as Account);
	const debouncedCityName = useDebounce(cityName, 500);

	async function handleFetchCities() {
		setIsLoading(true);
		try {
			const { data } = await api.get(`Account/v1/accountsAdminPaginated`, {
				params: {
					pageNumber: pageNumber,
					pageSize: 10,
					cityName: cityName,
				},
			});
			setPaginatedCities(data);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	}

	function handleChangePageNumber(newPage: number) {
		SetPageNumber(newPage);
	}

	const { setTitle } = useLayout();

	useEffect(() => {
		if (debouncedCityName && pageNumber !== 1) {
			SetPageNumber(1);
			return;
		}

		handleFetchCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber, debouncedCityName]);

	useEffect(() => {
		setTitle("GESTÃO DE CIDADES");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showModalCitiesEdit, setShowModalCitiesEdit] = useState(false);
	const handleShowModalCitiesEdit = () => {
		setShowModalCitiesEdit(true);
	};
	const handleCloseModalCitiesEdit = () => setShowModalCitiesEdit(false);

	const [showModalLicenseManagement, setShowModalLicenseManagement] = useState(false);
	const handleShowModalLicenseManagement = () => {
		setShowModalLicenseManagement(true);
	};
	const handleCloseModalLicenseManagement = () => {
		setShowModalLicenseManagement(false);
		handleFetchCities();
	};

	const [showModalUpdateUnits, setShowModalUpdateUnits] = useState(false);
	const handleShowModalUpdateUnits = () => {
		setShowModalUpdateUnits(true);
	};
	const handleCloseModalUpdateUnits = () => {
		setShowModalUpdateUnits(false);
	};

	return (
		<div className="p-2 row g-5 g-xl-8 pb-5">
			<ToastContainer />
			<div className="col-xl-12">
				<Card>
					<Card.Body>
						<div>
							<Row className="d-flex align-items-center">
								<Col className="d-flex flex-column justify-content-end ">
									<label className="form-label">Pesquisar Cidade:</label>
									<SearchInput
										placeholder="Pesquisar Cidade"
										value={cityName}
										setValue={setCityName}
									/>
								</Col>
							</Row>
						</div>
					</Card.Body>
				</Card>
			</div>
			<div className="col-xl-12">
				<ModalCitiesEdit
					show={showModalCitiesEdit}
					onHide={handleCloseModalCitiesEdit}
					city={selectedCity}
				/>

				<ModalLicenseManagement
					show={showModalLicenseManagement}
					onHide={handleCloseModalLicenseManagement}
					selectedCity={selectedCity}
				/>

				<ModalUpdateUnits
					show={showModalUpdateUnits}
					onHide={handleCloseModalUpdateUnits}
					uf={selectedCity.federalUnit?.abbreviation}
					ibgeCode={selectedCity?.ibgeCode}
				/>
				<Card>
					<Card.Body>
						<div className="table-responsive mb-4">
							<div className="d-flex align-items-center">
								<label>legenda:</label>
								<label>
									<FaCircle className="text-success mx-2" /> Ativa
								</label>
								<label>
									<FaCircle className="text-warning mx-2" />
									Pendente
								</label>
								<label>
									<FaCircle className="text-danger mx-2" />
									Inativa
								</label>
							</div>
							<table
								id="table-professionals"
								className="table align-middle table-row-dashed fs-7 fw-bold gy-5 dataTable no-footer"
							>
								<thead>
									<tr className="text-center text-muted">
										<th className="min-w-125px">STATUS</th>
										<th className="min-w-125px">IBGE CODE</th>
										<th className="min-w-125px">CIDADE</th>
										<th className="min-w-125px">ESTADO</th>
										<th className="min-w-125px">DATA DE EXPIRAÇÃO</th>
										<th className="min-w-125px">TIPO DE LICENÇA</th>
										<th className="min-w-125px">AÇÕES</th>
									</tr>
								</thead>
								<tbody className="table-tbody">
									{paginatedCities.data ? (
										<>
											{paginatedCities.data?.map((city: Account) => (
												<tr
													key={city.ibgeCode}
													className="text-center border-bottom-1 border-dark"
												>
													<td>
														{(() => {
															switch (
																getCityLicenseStatus(
																	city?.currentCityLicense ||
																		({} as CityLicense)
																)
															) {
																case "ativo":
																	return (
																		<FaCircle className="text-success" />
																	);
																case "inativo":
																	return (
																		<FaCircle className="text-danger" />
																	);
																case "pendente":
																	return (
																		<FaCircle className="text-warning" />
																	);
																default:
																	return null;
															}
														})()}
													</td>
													<td>{city.ibgeCode}</td>
													<td>{city.locale} </td>
													<td>{city.federalUnit?.abbreviation}</td>
													<td>
														{city.currentCityLicense?.endDate
															? dayjs(
																	city.currentCityLicense?.endDate
															  ).format("DD/MM/YYYY HH:mm")
															: "-"}
													</td>
													<td>
														{city.currentCityLicense?.license?.name
															? city.currentCityLicense?.license?.name
															: "-"}
													</td>

													<td>
														<div className="d-flex justify-content-center gap-2 w-100">
															<Button
																title="Editar Cidade"
																variant="warning"
																className="fs-8 px-5 py-2 ml-auto"
																onClick={() => {
																	setSelectedCity(city);
																	handleShowModalCitiesEdit();
																}}
															>
																<FaPen />
															</Button>
															<Button
																title="Renovar Licença"
																variant="secondary"
																className="fs-8 px-5 py-2 ml-auto"
																onClick={() => {
																	setSelectedCity(city);
																	handleShowModalLicenseManagement();
																}}
															>
																<FaBook />
															</Button>
															<Button
																title="Atualizar Unidades"
																variant="primary"
																className="fs-8 px-5 py-2 ml-auto"
																onClick={() => {
																	setSelectedCity(city);
																	handleShowModalUpdateUnits();
																}}
															>
																<FaSync />
															</Button>
														</div>
													</td>
												</tr>
											))}
										</>
									) : (
										<TableSkeletons numberOfCells={7} numberOfRows={10} />
									)}
								</tbody>
							</table>
						</div>
						{paginatedCities.pageSize && paginatedCities.totalRecords ? (
							<PaginationLinks
								itemsPerPage={paginatedCities.pageSize}
								totalPages={paginatedCities.totalRecords}
								changeSelectedPage={handleChangePageNumber}
								pageNumber={pageNumber || 0}
							/>
						) : (
							!isLoading && (
								<NoRecordsFeedback message="NÃO ENCONTRADO!" icon={<FaHome />} />
							)
						)}
					</Card.Body>
				</Card>
			</div>
		</div>
	);
}

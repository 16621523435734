import { validateCPF, validatePhone } from "validations-br";
import * as Yup from "yup";

import { onlyNumbers } from "@/utils/genericals";

declare module "yup" {
	interface StringSchema extends Yup.BaseSchema {
		cpf(message?: string): StringSchema;
		DDIPhone(message?: string): StringSchema;
	}
}

const DDIPhone = (message?: string) =>
	Yup.string().test("phone", message || "Telefone inválido", (value) =>
		validatePhone(value ? onlyNumbers(value.split("+55 ")[1] ?? "") : "")
	);

const cpf = (message?: string) =>
	Yup.string().test("cpf", message || "CPF inválido", (value) => validateCPF(value || ""));

Yup.addMethod(Yup.string, "DDIPhone", DDIPhone);
Yup.addMethod(Yup.string, "cpf", cpf);

export default Yup;

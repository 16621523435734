import Col from "react-bootstrap/Col";
import { toAbsoluteUrl } from "@/../_metronic/helpers";

type Props = {
	city?: string;
};

export function ReportHeader({ city }: Props) {
	return (
		<section className="d-flex justify-content-between w-100">
			<Col className="d-flex justify-content-start">
				<img
					src={toAbsoluteUrl("/media/logos/logo-multintegrada.png")}
					alt="Multintegrada - Tecnologia"
					width={150}
					height={45}
				/>
			</Col>
			<Col className="text-center">
				<h4 className="text-nowrap" style={{ textTransform: "uppercase" }}>
					PREFEITURA MUNICIPAL DE {city}
				</h4>
				<h4>SECRETARIA MUNICIPAL DE SAÚDE</h4>
				<h4>SISTEMA ÚNICO DE SAÚDE - SUS</h4>
			</Col>
			<Col className="d-flex justify-content-end">
				<img
					src={toAbsoluteUrl("/media/logos/logo-indicadores-esus-azul.png")}
					alt="Multintegrada - Tecnologia"
					width={135}
					height={40}
				/>
			</Col>
		</section>
	);
}

import Popover, { PopoverProps } from "react-bootstrap/Popover";

type MicroareaPopoverProps = PopoverProps;

export function MicroareaPopover({ ...rest }: MicroareaPopoverProps) {
	return (
		<Popover id="microarea-popover" {...rest}>
			<Popover.Body>Microárea</Popover.Body>
		</Popover>
	);
}

import { useState } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { BasePatient } from "@/@types/BasePatient";
import { Paginated } from "@/@types/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";
import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { ModalIndicator5 } from "../ModalIndicator5";

import "./styles.scss";

type Props = {
	patient: Paginated<BasePatient>;
	isLoading: boolean;
};

export function TableIndicator5({ isLoading, patient }: Props) {
	const [patientSelected, setPatientSelected] = useState<BasePatient>({} as BasePatient);
	const [showModalIndicator5, setShowModalIndicator5] = useState(false);
	const handleShowModalIndicator5 = () => {
		setShowModalIndicator5(true);
	};
	const handleCloseModalIndicator5 = () => {
		setShowModalIndicator5(false);
	};
	return (
		<div>
			<ModalIndicator5
				show={showModalIndicator5}
				onHide={handleCloseModalIndicator5}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						<th>
							<CitizenSyncLabel />
						</th>
						<th>CIDADÃO</th>
						<th>NOME DA MÃE</th>
						<th>DN</th>
						<th>CPF/CNS</th>
						<th>EQUIPE</th>
						<th>MC</th>
						<th>IDADE</th>
						<th className="no-print no-excel">AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && patient?.data ? (
						patient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								<td>
									<PremilinaryReportStatusIcon
										status={patient.preliminaryReportStatus}
									/>
								</td>
								<td className="text-nowrap">{patient.fullName}</td>
								<td className="text-nowrap">
									{patient.motherName?.toUpperCase() || "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap">
									{patient.birthdate
										? dayjs(patient.birthdate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap">
									{identifierOrNationalHealthCard(patient)}
								</td>
								<td className="text-nowrap">
									{patient.healthTeam?.name || "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap text-center">
									{patient.area || "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap">{patient.age}</td>
								<td className="text-end no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator5();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={9} height={20} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

import dayjs from "dayjs";

import { TbClipboardX } from "react-icons/tb";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PregnantPatient } from "@/@types/PregnantPatient";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { ExamsCards } from "@/components/ExamsCard";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator2({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="mb-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">Data de nascimento: </span>
							<span>{dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DUM: </span>
							<span>{dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DPP: </span>
							<span>{dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")}</span>
						</Col>
					</Row>
				</section>
				{(patientSelected.attendanceProceduresReqEvals?.length || 0) +
					(patientSelected.attendanceProcedures?.length || 0) ===
				0 ? (
					<NoRecordsFeedback
						icon={<TbClipboardX />}
						message="Nenhum atendimento foi encontrado"
					/>
				) : (
					<section className="d-flex flex-column">
						<section className="d-flex flex-column gap-5">
							{patientSelected.attendanceProceduresReqEvals?.length !== 0 && (
								<h1 className="fs-3">SOROLOGIAS</h1>
							)}
							{patientSelected.attendanceProceduresReqEvals?.map(
								(attendanceProcedure, index) =>
									attendanceProcedure.attProcedureEvaluated?.procedureCode !==
									"-" ? (
										<ExamsCards
											key={`attendance-procedure-req-eval-${index}`}
											healthTeam={patientSelected?.healthTeam}
											healthUnit={patientSelected.healthUnit}
											procedureDate={attendanceProcedure.date}
											procedure={attendanceProcedure.attProcedureEvaluated}
											professional={attendanceProcedure.professional1}
											cbo={attendanceProcedure.cbo1}
										/>
									) : (
										<ExamsCards
											key={`attendance-procedure-req-eval-${index}`}
											healthTeam={patientSelected?.healthTeam}
											healthUnit={patientSelected.healthUnit}
											procedure={attendanceProcedure.attProcedureRequested}
											professional={attendanceProcedure.professional1}
											isPendingExam={true}
											cbo={attendanceProcedure.cbo1}
										/>
									)
							)}
							{patientSelected.attendanceProcedures?.length !== 0 && (
								<h1 className="fs-3">TESTES</h1>
							)}
							{patientSelected.attendanceProcedures?.map((attendance, index) => (
								<ExamsCards
									key={`attendance-procedure-${index}`}
									healthTeam={patientSelected.healthTeam}
									healthUnit={patientSelected.healthUnit}
									procedure={attendance.procedure}
									professional={attendance.professional}
									cbo={attendance.cbo}
									procedureDate={attendance.date}
								/>
							))}
						</section>
					</section>
				)}
			</Modal.Body>
		</Modal>
	);
}

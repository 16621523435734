import { Carousel } from "react-responsive-carousel";
import banner1 from "@/../_metronic/assets/SupportEnvironment/banner-1.png";
import banner2 from "@/../_metronic/assets/SupportEnvironment/banner-2.png";
import banner3 from "@/../_metronic/assets/SupportEnvironment/banner-3.png";
import bannerMobile1 from "@/../_metronic/assets/SupportEnvironment/banner-mobile-1.png";
import bannerMobile2 from "@/../_metronic/assets/SupportEnvironment/banner-mobile-2.png";
import bannerMobile3 from "@/../_metronic/assets/SupportEnvironment/banner-mobile-3.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export function Banner() {
	const banners = [banner1, banner2, banner3];
	const bannersMobiles = [bannerMobile1, bannerMobile2, bannerMobile3];
	return (
		<Carousel
			className="z-1 position-relative"
			interval={3000}
			infiniteLoop
			autoPlay
			showStatus={false}
			showThumbs={false}
			animationHandler={"fade"}
		>
			{banners.map((item, index) => (
				<div key={`carousel-item-index-${index}`}>
					<picture>
						<source
							media="(max-width:1024px )"
							srcSet={bannersMobiles[index]}
							type="image/png"
						/>
						<img
							alt="banner ambiente de apoio"
							width="100%"
							src={item}
							style={{ minWidth: "300px" }}
						/>
					</picture>
				</div>
			))}
		</Carousel>
	);
}

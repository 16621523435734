import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FaPrint } from "react-icons/fa";

import { useApi } from "@/hooks/useApi";
import { Option } from "@/@types/Option";
import { FederalUnit } from "@/@types/FederalUnit";
import { Account } from "@/@types/Account";
import { useLayout } from "@/../_metronic/layout/core";

import { CityAdminReportContent } from "./CityAdminReportContent";
import { CityAdminReportFilters } from "./CityAdminReportFilters";
import { IndicatorsData } from "../PrevineBrasilDashboard";

export function CityAdminReport() {
	const api = useApi();
	const { setTitle } = useLayout();
	const [federalUnit, setFederalUnit] = useState<FederalUnit>({} as FederalUnit);
	const [city, setCity] = useState<Account>({} as Account);
	const resourceRef = useRef<HTMLDivElement | null>(null);
	const [indicators, setIndicators] = useState<IndicatorsData>({} as IndicatorsData);

	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const selectedQuarter = {
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	} as Option<string>;

	const printTable = useReactToPrint({
		content: () => resourceRef.current,
	});

	async function getIndicators(uf: string, ibgeCode: string) {
		try {
			const { data } = await api.get<IndicatorsData>("/indicator/v1/dashboard", {
				params: {
					uf,
					ibgeCode,
					quarter: selectedQuarter.value,
				},
			});
			return data;
		} catch (error) {
			console.error(error);
			return {} as IndicatorsData;
		}
	}

	async function fetchIndicators() {
		setIndicators(await getIndicators(federalUnit.abbreviation, city.ibgeCode));
	}

	useEffect(() => {
		setTitle("Relatório Previne Brasil");
		if (city.ibgeCode) {
			fetchIndicators();
		}
		return () => {
			setIndicators({} as IndicatorsData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [city]);

	return (
		<div className="p-2">
			<Card className="mb-8">
				<Card.Body>
					<CityAdminReportFilters
						selectedFederalUnit={federalUnit}
						setSelectedFederalUnit={setFederalUnit}
						setSelectedCity={setCity}
						handleFilter={fetchIndicators}
					/>
				</Card.Body>
			</Card>
			<CityAdminReportContent
				city={city}
				quarter={selectedQuarter.value}
				indicators={indicators}
				resourceRef={resourceRef}
			/>
			<Button
				variant="primary"
				title="Imprimir"
				className="btn-print d-flex align-items-center"
				onClick={printTable}
			>
				<FaPrint />
			</Button>
		</div>
	);
}

import dayjs from "dayjs";

import { TbFileReport } from "react-icons/tb";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { BasePatient } from "@/@types/BasePatient";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";

type Props = ModalProps & {
	patientSelected: BasePatient;
};

export function ModalIndicator7({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">DN: </span>
							<span>{dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")}</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">IDADE: </span>
							<span>{patientSelected.age}</span>
						</Col>
					</Row>
				</section>
				<h1>ATENDIMENTOS:</h1>
				<section className="d-flex flex-column gap-5">
					{patientSelected.attendances?.length !== 0 ? (
						patientSelected.attendances?.map((attendance, index) => (
							<div
								key={`dental-attendance-${index}`}
								className="bg-light-dark border border-primary rounded p-5"
							>
								<Row className="d-flex align-items-start justify-content-between gap-5 attendance-header">
									<Col
										sm={12}
										md={8}
										lg={8}
										xl={8}
										className="attendance-info-container"
									>
										<section>
											<article>
												<p>
													<strong className="text-primary me-2">
														Equipe:
													</strong>
													{`${patientSelected.healthTeam?.ine || ""} | ${
														patientSelected.healthTeam?.name ||
														"Não informado"
													}`}
												</p>
											</article>
											<article>
												<p>
													<strong className="text-primary me-2">
														Cid:
													</strong>
													<label>{`${attendance.problems[0]?.cid?.cidCode} - ${attendance.problems[0]?.cid?.description}`}</label>
												</p>
												<p>
													<strong className="text-primary me-2">
														Ciap:
													</strong>
													<label>{`${attendance.problems[0]?.ciap?.ciapCode} - ${attendance.problems[0]?.ciap?.description}`}</label>
												</p>
											</article>
											<article>
												<strong className="text-primary">
													1º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.problems[0]?.professional1?.name ||
														"Não informado"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.problems[0].cbo1?.description ||
														"Não informado"}
												</p>
											</article>
										</section>
									</Col>
									<Col sm={12} md={12} lg={3} xl={3}>
										<div className="d-flex align-items-center">
											<span>Data do registro: </span>
											<span className="ms-2 text-white text-center bg-primary p-2 rounded w-10">
												{attendance?.date
													? dayjs(attendance.date).format("DD/MM/YYYY")
													: "NÃO INFORMADO"}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						))
					) : (
						<NoRecordsFeedback
							icon={<TbFileReport />}
							message="Nenhum atendimento foi encontrado"
						/>
					)}
				</section>
				<h1 className="my-3">PROCEDIMENTOS:</h1>
				<section className="d-flex flex-column gap-5">
					{patientSelected.attendanceProceduresReqEvals?.length !== 0 ? (
						patientSelected.attendanceProceduresReqEvals?.map((attendance, index) => (
							<div
								key={`dental-attendance-${index}`}
								className="bg-light-dark border border-primary rounded p-5"
							>
								<Row className="d-flex align-items-start justify-content-between gap-5 attendance-header">
									<Col
										sm={12}
										md={8}
										lg={8}
										xl={8}
										className="attendance-info-container"
									>
										<section>
											<article>
												<p>
													<strong className="text-primary me-2">
														Equipe:
													</strong>
													{`${patientSelected.healthTeam?.ine || ""} | ${
														patientSelected.healthTeam?.name ||
														"Não informado"
													}`}
												</p>
											</article>
											<article>
												<p>
													<strong className="text-primary me-2">
														Código/Procedimento:
													</strong>
													<label>{`${attendance.attProcedureRequested?.procedureCode} - ${attendance.attProcedureRequested?.name}`}</label>
												</p>
											</article>
											<article>
												<strong className="text-primary">
													1º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.professional1?.name ||
														"Não informado"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.cbo1?.description ||
														"Não informado"}
												</p>
											</article>
										</section>
									</Col>
									<Col sm={12} md={12} lg={3} xl={3}>
										<div className="d-flex align-items-center">
											<span>Data do registro: </span>
											<span className="ms-2 text-white text-center bg-primary p-2 rounded w-10">
												{dayjs(attendance.date).format("DD/MM/YYYY")}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						))
					) : (
						<NoRecordsFeedback
							icon={<TbFileReport />}
							message="Nenhum procedimento foi encontrado"
						/>
					)}
				</section>
			</Modal.Body>
		</Modal>
	);
}

import { useEffect, useState } from "react";

import { useLayout } from "@/../_metronic/layout/core";

import { FaEye } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { useApi } from "@/hooks/useApi";
import { Role } from "@/@types/Role";

import { ModalPerfilAccess } from "@/components/ModalPerfilAccess";

export function AccessProfiles() {
	const api = useApi();
	const { setTitle } = useLayout();
	const [isloading, setIsLoading] = useState(false);
	const [selectedRole, setSelectedRole] = useState<Role>({} as Role);
	const [roles, setRoles] = useState<Role[]>([]);

	async function handleFetchProfiles() {
		setIsLoading(true);
		try {
			const { data } = await api.get<Role[]>("/profile/v1/GetAll");
			setRoles(data);
		} catch (error) {
			console.log(error);
			setRoles([]);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		setTitle("GESTÃO DE PERFIL");
		handleFetchProfiles();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showModalPerfilAccess, setShowModalPerfilAccess] = useState(false);
	const handleShowModalPerfilAcess = () => {
		setShowModalPerfilAccess(true);
	};
	const handleCloseModalPerfilAccess = () => setShowModalPerfilAccess(false);

	return (
		<div className="p-2">
			<ModalPerfilAccess
				show={showModalPerfilAccess}
				onHide={handleCloseModalPerfilAccess}
				role={selectedRole}
			/>
			<Card>
				<Card.Body>
					<table
						id="table-profile"
						className=" table-responsive table align-middle table-row-dashed fs-7 fw-bold dataTable bg-white no-footer"
					>
						<thead>
							<tr className="text-center text-muted">
								<th>
									<strong>PERFIL</strong>
								</th>
								<th>
									<strong>DESCRIÇÃO</strong>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody className="table-tbody">
							{roles?.map((role, index) => (
								<tr
									key={index}
									className="text-center  border-bottom-1 border-dark"
								>
									<td className="align-items-center">{role.name}</td>
									<td>{role.description}</td>
									<td>
										<div className="d-flex justify-content-end me-2 gap-1">
											<Button
												className="btn-primary"
												onClick={() => {
													setSelectedRole(role);
													handleShowModalPerfilAcess();
												}}
												disabled={isloading}
											>
												<FaEye />
											</Button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Card.Body>
			</Card>
		</div>
	);
}

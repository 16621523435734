import { useState } from "react";

import dayjs from "dayjs";

import { FaEye } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { Paginated } from "@/@types/paginated";
import { ActiveChildVaccination } from "@/@types/ActiveChildVaccination";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { TableSkeletons } from "@/components/TableSkeletons";
import { ModalActiveChildVaccinationSearch } from "../ModalActiveChildVaccination";

type Props = {
	activeChildVaccinationData: Paginated<ActiveChildVaccination>;
	isLoading: boolean;
};

export function TableActiveChildVaccinationSearch({
	activeChildVaccinationData,
	isLoading,
}: Props) {
	const [selectedCitizenActiveVaccination, setSelectedCitizenActiveVaccination] =
		useState<ActiveChildVaccination>({} as ActiveChildVaccination);
	const [showModalActiveVaccinationSearch, setShowModalActiveVaccinationSearch] = useState(false);
	const handleShowModalActiveVaccinationSearch = () => {
		setShowModalActiveVaccinationSearch(true);
	};
	const handleCloseModalActiveVaccinationSearch = () => {
		setShowModalActiveVaccinationSearch(false);
	};

	return (
		<div>
			<ModalActiveChildVaccinationSearch
				selectedCitizenActiveChildVaccination={selectedCitizenActiveVaccination}
				show={showModalActiveVaccinationSearch}
				onHide={handleCloseModalActiveVaccinationSearch}
			/>

			<Table className="bg-white" responsive striped bordered hover>
				<thead className="fw-bolder text-muted">
					<tr>
						<th className="text-center">NOME</th>
						<th className="text-center">CPF</th>
						<th className="text-center">CNS</th>
						<th className="text-center">DN</th>
						<th className="text-center text-nowrap">
							<label className="me-2">Nº DE PENDÊNCIAS</label>
							<CardOverlayTrigger message="Nº de pendências vacinais" />
						</th>
						<th className="text-center">AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{activeChildVaccinationData?.data && !isLoading ? (
						activeChildVaccinationData.data?.map((item, index) => (
							<tr key={index}>
								<td title="Nome" className="align-content-center text-nowrap ps-2">
									{item.fullName ?? "NÃO INFORMADO"}
								</td>
								<td className="text-center align-content-center ps-2">
									{item.identifier}
								</td>
								<td className="text-center align-content-center ps-2">
									{item.cns}
								</td>
								<td
									className="text-center align-content-center ps-2"
									title={item.age}
								>
									{item.birthdate
										? dayjs(item.birthdate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td className="align-content-center text-center">
									{item.registers.length ?? "-"}
								</td>
								<td className="align-content-center text-center">
									<Button
										className="bg-primary py-1 px-3"
										onClick={() => {
											handleShowModalActiveVaccinationSearch();
											setSelectedCitizenActiveVaccination(item);
										}}
									>
										<FaEye className="fs-4" />
									</Button>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={6} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

import { formatIdentifier } from "./formatIdentifier";
import { BasePatient } from "@/@types/BasePatient";

export function identifierOrNationalHealthCard(patient: BasePatient) {
	if (patient?.cpf) {
		return formatIdentifier(patient.cpf);
	} else if (patient?.cns) {
		return patient.cns;
	}

	return "NÃO INFORMADO";
}

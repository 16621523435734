import Col from "react-bootstrap/Col";

function stringToColor(name: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < name.length; i += 1) {
		hash = name.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */
	return color;
}

function stringAvatar(name: string) {
	try {
		let nameasarray: string[] = name.trim().split(" ");
		let abreviations: string = nameasarray[0][0];
		if (nameasarray.length > 1) {
			abreviations += nameasarray[1][0];
		}
		return {
			sx: {
				bgcolor: stringToColor(name.trim()),
			},
			children: abreviations,
		};
	} catch (exception) {
		return { sx: { bgcolor: "#FFFFFF" }, children: "" };
	}
}

type Props = {
	fullName: string;
};

export function BackgroundLetterAvatars({ ...rest }: Props) {
	return (
		<Col
			className="align-baseline justify-content-end text-uppercase text-white"
			direction="row"
			spacing={2}
		>
			<div
				style={{
					backgroundColor: stringAvatar(rest.fullName).sx.bgcolor,
					borderRadius: "50%",
					padding: "8px",
					marginRight: "0px",
				}}
				{...stringAvatar(rest.fullName)}
			/>
		</Col>
	);
}

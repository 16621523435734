import axios from "axios";

const AUTH_API_URL = process.env.REACT_APP_AUTH_BASE_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_API_URL}/verify_token`;
export const REGISTER_URL = `${AUTH_API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${AUTH_API_URL}/forgot_password`;

export const LOGIN_URL = `${AUTH_API_URL}/v1/auth/login`;
export const REFRESH_TOKEN_URL = `${AUTH_API_URL}/v1/auth/refresh`;

// Server should return AuthModel
export function login(userIdentifier: string, password: string) {
	const identifier = userIdentifier.replaceAll(".", "").replaceAll("-", "").replaceAll("_", "");
	return axios.post(LOGIN_URL, {
		identifier,
		password,
	});
}

// Server should return AuthModel
export function refreshToken(token: string, refreshToken: string) {
	return axios.post(REFRESH_TOKEN_URL, {
		token,
		refreshToken,
	});
}

import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import { StateMeasure } from "@/utils/indicators";

import { LegendMeasureColor } from "@/components/LegendMeasureColor";

type Props = {
	title?: string;
	total?: number;
	stateMeasures?: StateMeasure[];
	children?: React.ReactNode;
	description?: string;
	indicatorNumber: number;
};

export function IndicatorCard({
	title,
	total,
	stateMeasures,
	description,
	indicatorNumber,
	children,
}: Props) {
	return (
		<Card className="p-2 h-100">
			<Card.Header>
				<Card.Title className="d-flex justify-content-between w-100">
					<Link
						className="text-black text-hover-primary"
						to={`/componente-qualidade/indicador/${indicatorNumber}/detalhes`}
					>
						<label className="cursor-pointer">{title}</label>
					</Link>
					<label className="text-muted">{total?.toLocaleString()}</label>
				</Card.Title>
			</Card.Header>
			<Card.Body className="d-flex align-items-center flex-column bg-white p-0 h-100 w-100">
				<LegendMeasureColor
					indicatorMeasures={stateMeasures || []}
					style={{ fontSize: "8px" }}
				/>
				{children}

				<div className="d-flex align-items-stretch">
					<span
						className={`card align-self-center border border-primary text-primary p-2 fs-8`}
					>
						{description}
					</span>
				</div>
			</Card.Body>
		</Card>
	);
}

import { SelectHTMLAttributes } from "react";

import "./styles.scss";

type Props = SelectHTMLAttributes<HTMLSelectElement>;

export function NumberOfRecords({ ...rest }: Props) {
	return (
		<span className="d-flex flex-center gap-1 number-records">
			Mostrar{" "}
			<select className="form-select form-select-sm" {...rest}>
				<option value="10">10</option>
				<option value="25">25</option>
				<option value="50">50</option>
				<option value="100">100</option>
			</select>
			itens
		</span>
	);
}

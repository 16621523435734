import dayjs from "dayjs";

import { IndicatorModalCardTheme } from "@/@types/IndicatorModalCardTheme";
import { Vaccine } from "@/@types/Vaccine";

import { IndicatorModalCard } from "@/components/IndicatorModalCard";

type VaccinationCardsProps = {
	vaccine?: Vaccine;
	theme: IndicatorModalCardTheme;
	title?: string;
};

export function VaccinationCards({ vaccine, theme, title }: VaccinationCardsProps) {
	return (
		<IndicatorModalCard
			theme={theme}
			containerClassName="vaccination-cards-container my-2"
			title={title}
			subtitle={
				vaccine?.vaccine?.dateofVaccine ? (
					dayjs(vaccine.vaccine?.dateofVaccine).format("DD/MM/YYYY")
				) : (
					<span className="visibility-hidden">--/--/----</span>
				)
			}
			subtitleHeading="Aplicação"
		>
			<span>
				Aplicado por:
				<span className="ms-2 text-gray-900">
					{vaccine?.vaccine.professional?.name || "NÃO INFORMADO"}
				</span>
			</span>
			<span>
				Unidade:
				<span className="ms-2 text-gray-900">
					{vaccine?.vaccine.healthUnit?.name
						? `${vaccine?.vaccine.healthUnit?.cnes}-${vaccine?.vaccine.healthUnit?.name}`
						: "NÃO INFORMADO"}
				</span>
			</span>
		</IndicatorModalCard>
	);
}

import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { HomeVisitConclusionType } from "@/@types/HomeVisitConclusionType";

type Props = SelectProps & {
	conclusionTypes: HomeVisitConclusionType[];
};

const animatedComponents = makeAnimated();

export function SelectConclusionType({ conclusionTypes, ...rest }: Props) {
	const defaultOption = { label: "Todos" };
	const options = [
		...conclusionTypes?.map((conclusionType) => ({
			value: conclusionType.id,
			label: conclusionType.description,
		})),
	];
	return (
		<div className="d-flex flex-column">
			<label
				title="Lista de status de conclusão de visitas"
				htmlFor={rest.id}
				className="form-label"
			>
				Tipo de Conclusão:
			</label>
			<Select
				options={[defaultOption, ...options]}
				id={rest.id}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma conclusão encontrada"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				{...rest}
			/>
		</div>
	);
}

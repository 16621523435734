import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FaFilter, FaPrint } from "react-icons/fa";

import dayjs from "dayjs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useApi } from "@/hooks/useApi";
import { useUnits } from "@/hooks/useUnits";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useLayout } from "@/../_metronic/layout/core";
import { HealthTeam } from "@/@types/HealthTeam";
import { SelectOptionsProps } from "@/@types/genericals";

import { defaultUnit } from "@/components/UnitsSelect";
import { defaultHealthTeam } from "@/components/HealthTeamsSelect";
import { DashboardFilters } from "@/components/DashboardFilters";
import { PrevineBrasilComponent } from "./PrevineBrasilComponent";

import "./styles.css";

export type ISFHealthTeam = {
	healthTeam: HealthTeam | null;
	totalValid: number;
	totalIdentified: number;
	isf: number;
};

export type IndicatorData = {
	Identified: number;
	Valid: number;
};

export type IndicatorsData = {
	indicatorOne: IndicatorData[];
	indicatorTwo: IndicatorData[];
	indicatorThree: IndicatorData[];
	indicatorFour: IndicatorData[];
	indicatorFive: IndicatorData[];
	indicatorSix: IndicatorData[];
	indicatorSeven: IndicatorData[];
};

export function PrevineBrasilDashboard() {
	const api = useApi();
	const { units } = useUnits();
	const { setTitle } = useLayout();
	const { healthTeams } = useHealthTeams();
	const { uf, ibgeCode, cnes, currentAccount } = useCurrentAccount();
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
	const [indicatorsData, setIndicatorsData] = useState<IndicatorsData>({} as IndicatorsData);

	const [selectedUnit, setSelectedUnit] = useState<SelectOptionsProps>(defaultUnit);

	const [selectedHealthTeam, setSelectedHealthTeam] =
		useState<SelectOptionsProps>(defaultHealthTeam);
	const hasSelectedHealthTeam = selectedHealthTeam.value !== "0";
	const quarter = `${Math.ceil((dayjs().month() + 1) / 4)}`;
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<SelectOptionsProps>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});
	const dashboardDataRef = useRef<HTMLTableElement | null>(null);
	const handlePrint = useReactToPrint({
		content: () => dashboardDataRef.current,
	});

	async function fetchIndicatorsData() {
		if (!ibgeCode) return;
		setIsButtonDisabled(true);
		try {
			const { data } = await api.get<IndicatorsData>("/indicator/v1/dashboard", {
				params: {
					uf,
					ibgeCode,
					cnes: selectedUnit.value || cnes || "",
					quarter: selectedQuarter.value,
					...(hasSelectedHealthTeam && { ine: selectedHealthTeam.value }),
				},
			});

			setIndicatorsData(data);
			setIsButtonDisabled(false);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		setTitle("Dashboard ESF");
		fetchIndicatorsData();

		return () => {
			setIndicatorsData({} as IndicatorsData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuarter, selectedUnit, selectedHealthTeam]);

	return (
		<main className="app-container container-fluid p-2" ref={dashboardDataRef}>
			<section>
				<Row className="d-flex mx-0 w-100 no-print">
					<Col className="text-center mb-3 py-1 bg-white">
						<div className="d-flex justify-content-between mb-2 mt-2">
							<div className="d-flex text-start fs-5 ">
								<strong>
									{currentAccount.locale} - {selectedUnit.label}
								</strong>
							</div>
							<div className="d-flex gap-2 rounded">
								<OverlayTrigger
									rootClose
									trigger="click"
									placement="bottom"
									overlay={
										<Popover
											className="px-5 py-2 text-nowrap"
											id="popover-positioned-bottom"
											title="Popover bottom"
											style={{ minWidth: "30rem" }}
										>
											<h1 className="fs-6">Opções de Filtros</h1>
											<hr />
											<DashboardFilters
												selectedQuarter={selectedQuarter}
												selectedUnit={selectedUnit}
												selectedHealthTeam={selectedHealthTeam}
												units={units}
												healthTeams={healthTeams}
												handleChangeQuarter={(newValue) =>
													setSelectedQuarter(
														newValue as SelectOptionsProps
													)
												}
												handleChangeUnit={(newValue) =>
													setSelectedUnit(newValue as SelectOptionsProps)
												}
												handleChangeHealthTeams={(newValue) =>
													setSelectedHealthTeam(
														newValue as SelectOptionsProps
													)
												}
											/>
										</Popover>
									}
								>
									<Button
										className="button-color-dashboard bg-light fs-6 px-3 py-2 text-nowrap text-hover-white ms-2"
										disabled={isButtonDisabled}
									>
										<FaFilter /> Filtrar
									</Button>
								</OverlayTrigger>

								<Button
									className="button-color-dashboard bg-light text-hover-white fs-6 px-3 py-2 px-8"
									onClick={handlePrint}
									disabled={isButtonDisabled}
								>
									<FaPrint className="d-flex" />
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</section>
			<PrevineBrasilComponent
				city={currentAccount.locale}
				quarter={selectedQuarter.value}
				indicators={indicatorsData}
			/>
		</main>
	);
}

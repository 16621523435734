import { createContext, ReactNode, useState, useEffect } from "react";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";

export type Unit = {
	id: number;
	cnes: string;
	name: string;
	isActive: Boolean;
	neighborhood?: string;
};

type UnitsContextType = {
	units: Unit[];
};

export const UnitsContext = createContext({} as UnitsContextType);

type UnitsContextProviderProps = {
	children: ReactNode;
};

export function UnitsContextProvider({ children }: UnitsContextProviderProps) {
	const api = useApi();
	const [units, setUnits] = useState<Unit[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function getUnits(uf: string, ibgeCode?: string, cnes?: string): Promise<Unit[]> {
		if (!ibgeCode) return [];
		try {
			const response = await api.get("/healthunit/v1/gethealthunits", {
				params: { uf, ibgeCode, cnes },
			});
			return response?.data || [];
		} catch (error) {
			console.error(error);
			return [];
		}
	}
	useEffect(() => {
		async function fetchData() {
			const units = await getUnits(uf, ibgeCode, cnes);
			setUnits(units);
		}
		fetchData();
		return () => setUnits([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ibgeCode, cnes]);

	return <UnitsContext.Provider value={{ units }}>{children}</UnitsContext.Provider>;
}

import { useState, useEffect, ReactNode } from "react";
import Spinner from "react-bootstrap/Spinner";

import classNamesGroups from "clsx";
import "./styles.scss";

type LoadingScreenProps = {
	loading: boolean;
	messages?: string | string[];
	intervalBetweenMessages?: number;
	personalLoader?: ReactNode;
};

export function LoadingScreen({
	loading,
	messages = "Por favor, aguarde...",
	intervalBetweenMessages = 2000,
	personalLoader,
}: LoadingScreenProps) {
	const [currentMessage, setCurrentMessage] = useState(messages[0] || messages);

	useEffect(() => {
		if (!loading) {
			setCurrentMessage(messages[0] || messages);
			return;
		}

		if (loading && Array.isArray(messages)) {
			const currentIndex = messages.indexOf(currentMessage as string);

			if (currentIndex === messages.length - 1) {
				return;
			}

			setTimeout(() => {
				setCurrentMessage(messages[currentIndex + 1]);
			}, intervalBetweenMessages);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMessage, loading]);

	return (
		<div
			className={classNamesGroups(
				"loading-screen-overlay",
				loading && "loading-screen-overlay--visible"
			)}
		>
			<div className="loading-screen-modal">
				{personalLoader || (
					<Spinner
						animation="border"
						variant="primary"
						className="fs-2 loading-screen-spinner"
					/>
				)}

				{Array.isArray(messages) ? (
					currentMessage
				) : (
					<p className="text-center fs-5">{messages}</p>
				)}
			</div>
		</div>
	);
}

import Select, { Props as SelectProps } from "react-select";

import makeAnimated from "react-select/animated";
import { Account } from "@/@types/Account";

type Props = SelectProps & {
	accounts: Account[];
};

export function CitiesSelect({ accounts, ...rest }: Props) {
	const animatedComponents = makeAnimated();
	const options = [
		...accounts?.map((account) => ({
			value: account.ibgeCode,
			label: account.locale || "Todas as cidades",
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label title="Lista de cidades Disponiveis" className="form-label">
				Cidades:
			</label>
			<Select
				options={options}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma Cidade encontrada"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				{...rest}
			/>
		</div>
	);
}

export function phoneReplace(phone: string) {
	return phone
		.replace(/\D/g, "")
		.replace(
			/(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
			(fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
				return fullMatch;
			}
		);
}

export function phoneFormat(phone: string) {
	if (phone && phone.length === 13) {
		return `+${phone.substring(0, 2)}(${phone.substring(2, 4)})${phone.substring(
			4,
			5
		)}${phone.substring(5, 9)}-${phone.substring(9)}`;
	} else {
		return phone;
	}
}

import Select, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

import { HealthTeam } from "@/@types/HealthTeam";
import { Option } from "@/@types/Option";

type Props = SelectProps & {
	healthTeams: HealthTeam[];
	isLoading?: boolean;
};

const animatedComponents = makeAnimated();

export const defaultHealthTeam = { value: "", label: "Todas as equipes" };

export function HealthTeamsSelect({ healthTeams, isLoading, ...rest }: Props) {
	const options: Option<string>[] = [
		defaultHealthTeam,
		...healthTeams.map((healthTeam) => ({
			value: healthTeam.ine,
			label: healthTeam.ine + " - " + healthTeam.name,
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id} className="form-label">
				Equipes:
			</label>
			<Select
				options={options}
				id={rest.id}
				isLoading={isLoading}
				placeholder="Selecione..."
				components={animatedComponents}
				noOptionsMessage={() => "Nenhuma equipe encontrada"}
				styles={{ menu: (base) => ({ ...base, zIndex: 90 }) }}
				{...rest}
			/>
		</div>
	);
}

import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../AssetHelpers";
type Props = {
	className?: string;
	path: string;
	svgClassName?: string;
};

export function KTSVG({ className = "", path, svgClassName = "mh-50px" }: Props) {
	const svg = SVG({ src: toAbsoluteUrl(path), className: svgClassName });
	return <span className={`svg-icon ${className}`}>{svg || <></>}</span>;
}

import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { ApexOptions } from "apexcharts";

import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactApexChart from "react-apexcharts";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useApi } from "@/hooks/useApi";
import { AttendanceAggregatorEnum } from "@/utils/enums/AttendanceAggregatorEnum";

import { LoadingScreen } from "@/components/LoadingScreen";

type DashboardData<T, K> = {
	key: T;
	total: number;
	values?: K[];
};

type AggregatedDashboard = {
	aggregator: AttendanceAggregatorEnum;
	name: string;
	values: DashboardData<string, object>[];
};

export function AttendanceDashboard() {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const api = useApi();
	const { setTitle } = useLayout();
	const [isLoading, setIsloading] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	const [aggregatedDashboards, setAggregatedDashboards] = useState<AggregatedDashboard[]>([]);

	const attendanceAreaChartOptions: ApexOptions = {
		series: [
			{
				data:
					aggregatedDashboards
						.find(
							(aggregatedDashboard) =>
								aggregatedDashboard.aggregator === AttendanceAggregatorEnum.PERIOD
						)
						?.values.map((attendance) => attendance.total) || [],
				name: "Qtde. Atendimentos",
			},
		],
		chart: {
			redrawOnWindowResize: true,
			type: "area",
			foreColor: "black",
		},
		dataLabels: {
			enabled: true,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			categories:
				aggregatedDashboards
					.find(
						(aggregatedDashboard) =>
							aggregatedDashboard.aggregator === AttendanceAggregatorEnum.PERIOD
					)
					?.values.map((attendance) => attendance.key) || [],
		},
		yaxis: {
			opposite: false,
		},
	};

	var ageGroupedPieChartOptions: ApexOptions = {
		series:
			aggregatedDashboards
				.find(
					(aggregatedDashboard) =>
						aggregatedDashboard.aggregator === AttendanceAggregatorEnum.AGEGROUP
				)
				?.values.map((ageGroup) => ageGroup.total) || [],
		chart: {
			width: 100,
			type: "pie",
		},
		labels:
			aggregatedDashboards
				.find(
					(aggregatedDashboard) =>
						aggregatedDashboard.aggregator === AttendanceAggregatorEnum.AGEGROUP
				)
				?.values.map((ageGroup) => ageGroup.key) || [],
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 300,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
	};

	var healthUnitBarChartOptions: ApexOptions = {
		series: [
			{
				data:
					aggregatedDashboards
						.find(
							(aggregatedDashboard) =>
								aggregatedDashboard.aggregator ===
								AttendanceAggregatorEnum.HEALTHUNIT
						)
						?.values.sort(
							(healthUnitA, healthUnitB) => healthUnitB.total - healthUnitA.total
						)
						.map((healthUnitGroup) => healthUnitGroup.total) || [],
				name: "total",
			},
		],
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
				dataLabels: {
					position: "right",
				},
			},
		},
		dataLabels: {
			style: {
				colors: ["#333"],
			},
			textAnchor: "start",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: 400 },
		},
		xaxis: {
			categories:
				aggregatedDashboards
					.find(
						(aggregatedDashboard) =>
							aggregatedDashboard.aggregator === AttendanceAggregatorEnum.HEALTHUNIT
					)
					?.values.sort(
						(healthUnitA, healthUnitB) => healthUnitB.total - healthUnitA.total
					)
					.map((healthUnitGroup) => healthUnitGroup.key) || [],
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: true,
							distributed: true,
						},
					},
					chart: {
						height: 800,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: false,
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	const healthTeamBarChartOptions: ApexOptions = {
		series: [
			{
				data:
					aggregatedDashboards
						.find(
							(aggregatedDashboard) =>
								aggregatedDashboard.aggregator ===
								AttendanceAggregatorEnum.HEALTHTEAM
						)
						?.values.sort(
							(healthTeamA, healthTeamB) => healthTeamB.total - healthTeamA.total
						)
						.map((healthTeamGroup) => healthTeamGroup.total) || [],
				name: "total",
			},
		],
		chart: {
			type: "bar",
			height: 350,
			redrawOnWindowResize: true,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
				dataLabels: {
					position: "right",
				},
			},
		},
		dataLabels: {
			style: {
				colors: ["#333"],
			},
			textAnchor: "start",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: 400 },
		},
		xaxis: {
			categories:
				aggregatedDashboards
					.find(
						(aggregatedDashboard) =>
							aggregatedDashboard.aggregator === AttendanceAggregatorEnum.HEALTHTEAM
					)
					?.values.sort(
						(healthTeamA, healthTeamB) => healthTeamB.total - healthTeamA.total
					)
					.map((healthTeamGroup) => healthTeamGroup.key) || [],
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: true,
							distributed: true,
						},
					},
					chart: {
						height: 800,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: false,
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	var ciapBarChartOptions: ApexOptions = {
		series: [
			{
				data: (
					aggregatedDashboards.find(
						(aggregatedDashboard) =>
							aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CIAP
					)?.values || []
				)
					.sort((ciapA, ciapB) => ciapB.total - ciapA.total)
					.map((ciapGroup) => ciapGroup.total)
					.slice(0, 10),
				name: "total",
			},
		],
		labels: (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CIAP
			)?.values || []
		)
			.sort((ciapA, ciapB) => ciapB.total - ciapA.total)
			.map((ciapGroup) => ciapGroup.key)
			.slice(0, 10),
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
				dataLabels: {
					position: "right",
				},
			},
		},
		dataLabels: {
			style: {
				colors: ["#333"],
			},
			textAnchor: "start",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left" },
		},
		xaxis: {
			categories: (
				aggregatedDashboards.find(
					(aggregatedDashboard) =>
						aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CIAP
				)?.values || []
			)
				.sort((ciapA, ciapB) => ciapB.total - ciapA.total)
				.map((ciapGroup) => ciapGroup.key)
				.slice(0, 10),
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: false,
							distributed: true,
							dataLabels: {
								orientation: "vertical",
							},
						},
					},
					chart: {
						height: 400,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: false,
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	var cidBarChartOptions: ApexOptions = {
		series: [
			{
				data: (
					aggregatedDashboards.find(
						(aggregatedDashboard) =>
							aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CID
					)?.values || []
				)
					.sort((cidA, cidB) => cidB.total - cidA.total)
					.map((cidGroup) => cidGroup.total)
					.slice(0, 10),
				name: "total",
			},
		],
		labels: (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CID
			)?.values || []
		)
			.sort((cidA, cidB) => cidB.total - cidA.total)
			.map((cidGroup) => cidGroup.key)
			.slice(0, 10),
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
				dataLabels: {
					position: "right",
				},
			},
		},
		dataLabels: {
			style: {
				colors: ["#333"],
			},
			textAnchor: "start",
			enabled: true,
		},
		yaxis: {
			labels: { show: true, align: "left" },
		},
		xaxis: {
			categories: (
				aggregatedDashboards.find(
					(aggregatedDashboard) =>
						aggregatedDashboard.aggregator === AttendanceAggregatorEnum.CID
				)?.values || []
			)
				.sort((cidA, cidB) => cidB.total - cidA.total)
				.map((cidGroup) => cidGroup.key)
				.slice(0, 10),
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: false,
							distributed: true,
							dataLabels: {
								orientation: "vertical",
							},
						},
					},
					chart: {
						height: 400,
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: false,
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	var genderDonutOptions: ApexOptions = {
		series: (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum.GENDER
			)?.values || []
		).map((genderGroup) => genderGroup.total),
		chart: {
			type: "donut",
		},
		labels: (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum.GENDER
			)?.values || []
		).map((genderGroup) => genderGroup.key),
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 300,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
	};

	async function fetchDashboardAttendance(aggregator?: number) {
		try {
			if (!ibgeCode) {
				return [];
			}
			setIsloading(true);
			const { data } = await api.get<AggregatedDashboard[]>("/Attendance/v1/GetDashboard", {
				params: {
					uf,
					ibgeCode,
					cnes,
					startDate: selectedOptionStartDate,
					endDate: selectedOptionEndDate,
					aggregator: aggregator,
				},
			});
			setIsloading(false);
			return data || [];
		} catch (error) {
			console.log(error);
			setIsloading(false);
			return [];
		}
	}

	async function fetchDashboard() {
		setAggregatedDashboards(await fetchDashboardAttendance());
	}

	useEffect(() => {
		setTitle("Atendimentos individuais");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Form
				onSubmit={(event) => {
					event.preventDefault();
					fetchDashboard();
				}}
			>
				<Row className="d-flex justify-content-between">
					<Col sm={12} lg={6} className="justify-content-start">
						<div className="d-flex  align-items-center gap-3">
							<Form.Label className="align-items-center fw-bolder">
								Período
							</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="Data inicial"
									min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
									max={dayjs().format("YYYY/MM/DD")}
									defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionStartDate(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="data final"
									defaultValue={dayjs().format("YYYY-MM-DD")}
									min={selectedOptionStartDate}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionEndDate(event.target.value)
									}
								/>
							</InputGroup>
						</div>
					</Col>

					<Col sm={12} md={6} lg={2} className="justify-content-end">
						<Button
							className="d-flex align-items-center justify-content-center w-100"
							type="submit"
							disabled={isLoading}
						>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos mensais
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={attendanceAreaChartOptions.series}
								options={attendanceAreaChartOptions}
								type="area"
								height={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={ageGroupedPieChartOptions.series}
								options={ageGroupedPieChartOptions}
								type="pie"
								width={400}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Gêneros
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={genderDonutOptions.series}
								options={genderDonutOptions}
								type="donut"
								width={367}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por unidade
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={healthUnitBarChartOptions.series}
								options={healthUnitBarChartOptions}
								type="bar"
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por equipe
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={healthTeamBarChartOptions.series}
								options={healthTeamBarChartOptions}
								type="bar"
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							CIAP mais utilizados
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={ciapBarChartOptions.series}
								options={ciapBarChartOptions}
								type="bar"
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							CID mais utilizados
						</Card.Header>
						<Card.Body>
							<ReactApexChart
								series={cidBarChartOptions.series}
								options={cidBarChartOptions}
								type="bar"
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

import { ReactNode } from "react";
import Col from "react-bootstrap/Col";

type Props = {
	title: string;
	value: number | string;
	children?: ReactNode;
	backgroundColor?: string;
	textColor?: string;
};

export function CardWithBullet({ title, value, backgroundColor, textColor, children }: Props) {
	return (
		<Col className={`d-flex justify-content-between px-2 py-6 ${backgroundColor} gap-3`}>
			<div className="d-flex align-items-center">
				<span
					data-kt-element="bullet"
					className="bullet bullet-vertical d-flex align-items-center min-h-40px me-2 bg-light-primary"
				/>
				<strong className="d-flex align-items-center me-2">
					<p className={`${textColor}`}>{title}</p>
				</strong>
				<div className="d-flex flex-column">{children}</div>
			</div>
			<strong className="d-flex align-items-center">
				{value ? (
					<label className={`fs-1  ${textColor}`}>{value.toString()}</label>
				) : (
					<label className={`fs-1  ${textColor}`}>0</label>
				)}
			</strong>
		</Col>
	);
}

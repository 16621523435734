import React, { createContext, useContext, useState, useEffect } from "react";
import { DefaultLayoutConfig } from "./DefaultLayoutConfig";
import {
	getEmptyCssClasses,
	getEmptyCSSVariables,
	getEmptyHTMLAttributes,
	LayoutSetup,
} from "./LayoutSetup";
import {
	ILayout,
	ILayoutCSSVariables,
	ILayoutCSSClasses,
	ILayoutHTMLAttributes,
} from "./LayoutModels";

type Props = {
	children?: React.ReactNode;
};

export interface LayoutContextModel {
	title: string;
	setTitle: (title: string) => void;
	config: ILayout;
	classes: ILayoutCSSClasses;
	attributes: ILayoutHTMLAttributes;
	cssVariables: ILayoutCSSVariables;
	setLayout: (config: LayoutSetup) => void;
}

const LayoutContext = createContext<LayoutContextModel>({
	title: "",
	setTitle: (title: string) => {},
	config: DefaultLayoutConfig,
	classes: getEmptyCssClasses(),
	attributes: getEmptyHTMLAttributes(),
	cssVariables: getEmptyCSSVariables(),
	setLayout: (config: LayoutSetup) => {},
});

const enableSplashScreen = () => {
	const splashScreen = document.getElementById("splash-screen");
	if (splashScreen) {
		splashScreen.style.setProperty("display", "flex");
	}
};

const disableSplashScreen = () => {
	const splashScreen = document.getElementById("splash-screen");
	if (splashScreen) {
		splashScreen.style.setProperty("display", "none");
	}
};

const LayoutProvider: React.FC<Props> = ({ children }) => {
	const [title, setTitle] = useState("");
	const [config, setConfig] = useState(LayoutSetup.config);
	const [classes, setClasses] = useState(LayoutSetup.classes);
	const [attributes, setAttributes] = useState(LayoutSetup.attributes);
	const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);
	const setLayout = (_themeConfig: Partial<ILayout>) => {
		enableSplashScreen();
		const bodyClasses = Array.from(document.body.classList);
		bodyClasses.forEach((cl) => document.body.classList.remove(cl));
		LayoutSetup.updatePartialConfig(_themeConfig);
		setConfig(Object.assign({}, LayoutSetup.config));
		setClasses(LayoutSetup.classes);
		setAttributes(LayoutSetup.attributes);
		setCSSVariables(LayoutSetup.cssVariables);
		setTimeout(() => {
			disableSplashScreen();
		}, 500);
	};
	const value: LayoutContextModel = {
		title,
		setTitle,
		config,
		classes,
		attributes,
		cssVariables,
		setLayout,
	};

	useEffect(() => {
		setTimeout(disableSplashScreen, 500);
	}, []);

	return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
	return useContext(LayoutContext);
}

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

type Props = {
	totalVisits: number;
	totalRealized: number;
	totalRejected: number;
	totalAbsent: number;
};

export function CardsInformativesVisits({
	totalVisits,
	totalAbsent,
	totalRealized,
	totalRejected,
}: Props) {
	return (
		<div className="mx-3">
			<Row>
				<Col sm={6} md={6} lg={3} xxl={3} className="my-2">
					<Card className="bg-muted text-white text-center py-3">
						<Card.Title className="text-white ">
							Total de visitas individuais
						</Card.Title>
						<Card.Body className="fs-1 fw-bolder py-2">{totalVisits || 0}</Card.Body>
					</Card>
				</Col>
				<Col sm={6} md={6} lg={3} xxl={3} className="my-2">
					<Card className="bg-primary text-white text-center py-3">
						<Card.Title className="text-white ">Visitas realizadas</Card.Title>
						<Card.Body className="fs-1 fw-bolder py-2">{totalRealized || 0}</Card.Body>
					</Card>
				</Col>
				<Col sm={6} md={6} lg={3} xxl={3} className="my-2">
					<Card className="bg-warning text-white text-center py-3">
						<Card.Title className="text-white ">Ausente</Card.Title>
						<Card.Body className="fs-1 fw-bolder py-2">{totalAbsent || 0}</Card.Body>
					</Card>
				</Col>
				<Col sm={6} md={6} lg={3} xxl={3} className="my-2">
					<Card className="bg-danger text-white text-center py-3">
						<Card.Title className="text-white ">Visitas recusadas</Card.Title>
						<Card.Body className="fs-1 fw-bolder py-2">{totalRejected || 0}</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

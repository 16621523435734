import { useState } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { BasePatient } from "@/@types/BasePatient";
import { Paginated } from "@/@types/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator7 } from "../ModalIndicator7";

import "./styles.scss";

type Props = {
	patient: Paginated<BasePatient>;
	isLoading: boolean;
};

export function TableIndicator7({ isLoading, patient }: Props) {
	const [patientSelected, setPatientSelected] = useState<BasePatient>({} as BasePatient);
	const [showModalIndicator7, setShowModalIndicator7] = useState(false);
	const handleShowModalIndicator7 = () => {
		setShowModalIndicator7(true);
	};
	const handleCloseModalIndicator7 = () => {
		setShowModalIndicator7(false);
	};
	return (
		<div>
			<ModalIndicator7
				show={showModalIndicator7}
				onHide={handleCloseModalIndicator7}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						<th>CIDADÃO</th>
						<th>DN</th>
						<th>CPF/CNS</th>
						<th>EQUIPE</th>
						<th>MC</th>
						<th>AUTORREFERIDO </th>
						<th>CONDIÇÃO AVALIADA</th>
						<th>ULTIMA CONSULTA</th>
						<th>ULTIMA SOLICITAÇÃO</th>
						<th>PRÓXIMA AVALIAÇÃO</th>
						<th>AÇÕES</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && patient?.data ? (
						patient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								<td title="nome" className="text-nowrap">
									{patient.fullName || "NÃO INFORMADO"}
								</td>
								<td className="text-nowrap">
									{patient.birthdate
										? dayjs(patient.birthdate).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td title="cpf" className="text-nowrap">
									{identifierOrNationalHealthCard(patient)}
								</td>
								<td title="equipe" className="text-nowrap">
									{patient.healthTeam?.name || "NÃO INFORMADO"}
								</td>
								<td title="Micro Área" className="text-nowrap text-center">
									{patient.area || "NÃO INFORMADO"}
								</td>
								<td title="autorreferido" className="text-nowrap text-center">
									{patient.cardiacRisk?.haveDiabetesCds ? "SIM" : "NÃO"}
								</td>
								<td title="condição avaliada">
									{patient.attendances && patient.attendances?.length > 0
										? "SIM"
										: "NÃO"}
								</td>
								<td title="ultima consulta" className="text-nowrap">
									{patient.attendances && patient.attendances[0]?.date
										? dayjs(patient.attendances[0]?.date).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td title="ultima solicitação" className="text-nowrap">
									{patient.attendanceProceduresReqEvals &&
									patient.attendanceProceduresReqEvals[0]?.date
										? dayjs(
												patient.attendanceProceduresReqEvals[0]?.date
										  ).format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td title="Próxima avaliação">
									{patient.attendances && patient.attendances[0]?.date
										? dayjs(patient.attendances[0]?.date)
												.add(6, "month")
												.format("DD/MM/YYYY")
										: "NÃO INFORMADO"}
								</td>
								<td className="text-center no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator7();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={11} height={20} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}

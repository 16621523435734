import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { removeDuplicates } from "@/utils/genericals";
import { Account } from "@/@types/Account";
import { Period } from "@/@types/Period";

import { BarChart } from "@/components/Charts/BarChart";
import { LegendData } from "@/components/LegendData";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { DonutChart } from "@/components/Charts/DonutChart";

type Props = { uf: string };
type CityResumeChildBirth = {
	city: Account;
	resumeChildBirth: ResumeChildBirth[];
};

type ResumeChildBirth = {
	totalChildBirthNormal: number;
	totalChildBirthCesarean: number;
	totalAbortion: number;
	totalStillBirth: number;
	period: Period;
};

export function ChildBirth({ uf }: Props) {
	const api = useApi();
	const [resumeChildBirths, setResumeChildBirths] = useState<CityResumeChildBirth[]>([]);

	async function handleChildBirth() {
		try {
			const { data } = await api.get(`/FederalUnitAttendance/v1/ResumeChildBirth`, {
				params: {
					uf: uf,
					startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
					endDate: dayjs().format("YYYY-MM-DD"),
				},
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetch() {
		setResumeChildBirths(await handleChildBirth());
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const periodResumes = resumeChildBirths
		?.map((resume) => resume.resumeChildBirth)
		.reduce((acc, current) => [...acc, ...current], []);
	const periods = removeDuplicates<Period[]>(periodResumes?.map((resume) => resume.period));

	const getChildBirthResume = (
		resumeChildBirths: CityResumeChildBirth[],
		key: keyof ResumeChildBirth
	) => {
		return (
			resumeChildBirths?.map((resume) =>
				resume.resumeChildBirth
					?.map((resumeChildBirth) => resumeChildBirth[key] as number)
					.reduce((acc, curr) => acc + curr, 0)
			) || []
		);
	};

	const getChildBirthByPeriodResume = (
		periods: Period[],
		periodResumes: ResumeChildBirth[],
		key: keyof ResumeChildBirth
	) => {
		return (
			periods?.map((period) =>
				periodResumes
					.filter(
						(periodResume) =>
							periodResume.period.year === period.year &&
							periodResume.period.month === period.month
					)
					.reduce((acc, resume) => acc + ((resume[key] as number) || 0), 0)
			) || []
		);
	};

	const normalChildBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalChildBirthNormal"
	);
	const cesareanChildBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalChildBirthCesarean"
	);
	const stillBirthChildResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalStillBirth"
	);
	const abortionBirthChildResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalAbortion"
	);

	return (
		<>
			<Row className="h-100">
				<Col sm={12} md={12} lg={12} xl={8} className="h-100">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<label className="me-2"> Partos por cidade </label>
								<CardOverlayTrigger
									children={
										<LegendData
											ClassNamesColors={[
												"text-primary",
												"text-success",
												"text-warning",
												"text-danger",
											]}
											titles={["Normal", "Cesário", "Natimorto", "Aborto"]}
										/>
									}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="overflow-auto p-0" style={{ maxHeight: "684px" }}>
							<div className="h-100" style={{ minWidth: "100%" }}>
								<BarChart
									categories={
										resumeChildBirths?.map((resume) => resume.city?.locale) ||
										[]
									}
									series={[
										{
											name: "Normal",
											data: getChildBirthResume(
												resumeChildBirths,
												"totalChildBirthNormal"
											),
										},
										{
											name: "Cesário",
											data: getChildBirthResume(
												resumeChildBirths,
												"totalChildBirthCesarean"
											),
										},
										{
											name: "Natimorto",
											data: getChildBirthResume(
												resumeChildBirths,
												"totalStillBirth"
											),
										},
										{
											name: "Aborto",
											data: getChildBirthResume(
												resumeChildBirths,
												"totalAbortion"
											),
										},
									]}
									height={
										resumeChildBirths?.length < 10
											? 700
											: resumeChildBirths?.length * 30
									}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col
					sm={12}
					md={12}
					lg={12}
					xl={4}
					className="d-flex flex-column justify-content-between h-100"
				>
					<Card className="m-2 h-50">
						<Card.Header>
							<Card.Title>Finalizações gestacionais</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<DonutChart
								series={[
									normalChildBirthResume.reduce((acc, curr) => acc + curr, 0),
									cesareanChildBirthResume.reduce((acc, curr) => acc + curr, 0),
									stillBirthChildResume.reduce((acc, curr) => acc + curr, 0),
									abortionBirthChildResume.reduce((acc, curr) => acc + curr, 0),
								]}
								categories={[]}
								colors={[]}
								width="100%"
								labels={["Normal", "Cesáreo", "Natimorto", "Aborto"]}
							/>
						</Card.Body>
					</Card>
					<Card className="m-2 h-50">
						<Card.Header>
							<Card.Title>Nativivos x Natimortos</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<DonutChart
								series={[
									normalChildBirthResume.reduce((acc, curr) => acc + curr, 0) +
										cesareanChildBirthResume.reduce(
											(acc, curr) => acc + curr,
											0
										),
									stillBirthChildResume.reduce((acc, curr) => acc + curr, 0) +
										abortionBirthChildResume.reduce(
											(acc, curr) => acc + curr,
											0
										),
								]}
								width="100%"
								categories={[]}
								colors={[]}
								labels={["Nativivo", "Natimorto"]}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Card className="m-2 h-100">
					<Card.Header>
						<Card.Title>Partos por período</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto p-0">
						<BarChart
							series={[
								{
									name: "Normal",
									data: normalChildBirthResume,
								},
								{
									name: "Cesário",
									data: cesareanChildBirthResume,
								},
								{
									name: "Natimorto",
									data: stillBirthChildResume,
								},
								{
									name: "Aborto",
									data: abortionBirthChildResume,
								},
							]}
							categories={
								periods?.map(
									(resume) => `${resume?.year}/${SHORT_MONTHS[resume?.month - 1]}`
								) || []
							}
							width={
								resumeChildBirths?.length < 10
									? 1000
									: resumeChildBirths?.length * 30
							}
							isVertical
							isStacked
						/>
					</Card.Body>
				</Card>
			</Row>
		</>
	);
}

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export type Props = {
	height?: number;
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories?: string[];
	descriptionsLabels?: string[];
	apexOptions?: ApexOptions;
	hideLegend?: boolean;
};

export function SemiDonut({ apexOptions, height, series, descriptionsLabels, hideLegend }: Props) {
	const semiDonutOption: ApexOptions = apexOptions || {
		chart: {
			type: "donut",
		},
		plotOptions: {
			pie: {
				startAngle: -90,
				endAngle: 90,
				offsetY: 10,
			},
		},
		grid: {
			padding: {
				bottom: -80,
			},
		},
		labels: descriptionsLabels || [],
		legend: { show: !hideLegend, position: "bottom" },
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						show: !hideLegend,
						position: "bottom",
					},
				},
			},
		],
	};

	return (
		<ReactApexChart
			options={semiDonutOption}
			series={series}
			type="donut"
			height={height || 350}
		/>
	);
}

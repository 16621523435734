import { Fragment } from "react";
import { FaHome } from "react-icons/fa";

import Table from "react-bootstrap/Table";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { descriptions, indicatorsDetail } from "@/utils/indicators";
import { HealthTeam } from "@/@types/HealthTeam";
import { SelectOptionsProps } from "@/@types/genericals";

import { TableSkeletons } from "../TableSkeletons";
import { NoRecordsFeedback } from "../NoRecordsFeedback";

type IndicatorDetails = {
	healthTeam: HealthTeam;
	Identified: number;
	Valid: number;
};
type Props = {
	indicatorsData: IndicatorsHealthTeam;
	period: SelectOptionsProps;
};
export type IndicatorsHealthTeam = {
	indicatorOne: IndicatorDetails[];
	indicatorTwo: IndicatorDetails[];
	indicatorThree: IndicatorDetails[];
	indicatorFour: IndicatorDetails[];
	indicatorFive: IndicatorDetails[];
	indicatorSix: IndicatorDetails[];
	indicatorSeven: IndicatorDetails[];
};

export function HealthTeamReportTable({ indicatorsData, period }: Props) {
	const { ibgeCode } = useCurrentAccount();
	const { healthTeams } = useHealthTeams();
	function calculateIndicatorPercentage(validated: number, identified: number) {
		if (identified === 0) {
			return 0;
		}
		return Number(((validated * 100) / identified).toFixed(2));
	}

	return (
		<>
			<Table striped responsive className="overflow-auto">
				<thead className="text-center">
					<tr>
						<th colSpan={23} className="bg-primary text-white fw-bolder">
							RELATÓRIO DE DESEMPENHO DE EQUIPES
						</th>
					</tr>
					<tr>
						<th className="text-center bg-secondary" colSpan={23}>
							<label className="fw-bolder">QUADRIMESTRE {period.value}</label>
						</th>
					</tr>
					<tr className="border border-muted bg-secondary fw-bold fs-7">
						<th colSpan={2} className="fw-bolder bg-secondary">
							INDICADORES
						</th>
						<th title={descriptions[0]} colSpan={3} className="fw-bolder ">
							I1
						</th>
						<th title={descriptions[1]} colSpan={3} className="fw-bolder">
							I2
						</th>
						<th title={descriptions[2]} colSpan={3} className="fw-bolder">
							I3
						</th>
						<th title={descriptions[3]} colSpan={3} className="fw-bolder">
							I4
						</th>
						<th title={descriptions[4]} colSpan={3} className="fw-bolder">
							I5
						</th>
						<th title={descriptions[5]} colSpan={3} className="fw-bolder">
							I6
						</th>
						<th title={descriptions[6]} colSpan={3} className="fw-bolder">
							I7
						</th>
					</tr>
					<tr className="border border-muted bg-secondary fw-bold fs-7">
						<th className="fw-bolder bg-secondary">INE</th>
						<th className="fw-bolder bg-secondary">EQUIPE</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
						<th title="Válidos" className="fw-bolder" id="valids">
							Val
						</th>
						<th title="Identificados" className="fw-bolder" id="identified">
							Id
						</th>
						<th title="Percentual de Válidos/Identificados" className="fw-bolder">
							%
						</th>
					</tr>
				</thead>

				<tbody className="border border-secondary bg-white">
					{indicatorsData?.indicatorOne ? (
						<>
							{healthTeams?.map((healthTeam, index) => {
								const filteredIndicators = Array.from(
									Object.values(indicatorsData)
								).filter((indicator) =>
									indicator.some(
										(indicatorHealthTeam) =>
											indicatorHealthTeam.healthTeam.ine === healthTeam.ine &&
											indicatorHealthTeam.Identified > 0
									)
								);

								if (filteredIndicators.length === 0) return null;

								return (
									<tr key={`trMap${index}`}>
										<td
											key={`tdMapIne${index}`}
											className="fw-bolder pe-2 ps-2"
										>
											{healthTeam?.ine}
										</td>
										<td
											key={`tdMap${index}-${healthTeam.id}`}
											className="fw-bolder fs-8 ps-2"
										>
											{healthTeam?.name}
										</td>
										{Array.from(Object.entries(indicatorsData)).map(
											([key, indicatorDetail], index) => {
												const healthTeamData = indicatorDetail.find(
													(indicatorDetail) =>
														indicatorDetail.healthTeam.ine ===
														healthTeam.ine
												);

												return (
													<Fragment key={`indicatorFragment-${index}`}>
														{!healthTeamData ? (
															<>
																<td></td>
																<td></td>
																<td></td>
															</>
														) : (
															<Fragment>
																<td id="valids">
																	{healthTeamData.Valid || 0}
																</td>
																<td id="identified">
																	{healthTeamData.Identified || 0}
																</td>
																<td
																	key={`measureNumber${index}`}
																	className={
																		"text-center text-" +
																		Object.values(
																			indicatorsDetail
																		)[index]?.getChartColor(
																			calculateIndicatorPercentage(
																				healthTeamData.Valid,
																				healthTeamData.Identified
																			)
																		)
																	}
																>
																	<label>
																		{calculateIndicatorPercentage(
																			healthTeamData.Valid,
																			healthTeamData.Identified
																		) + "%"}
																	</label>
																</td>
															</Fragment>
														)}
													</Fragment>
												);
											}
										)}
									</tr>
								);
							})}
						</>
					) : (
						<>{ibgeCode && <TableSkeletons numberOfCells={23} numberOfRows={10} />}</>
					)}
				</tbody>
			</Table>
			<footer>
				{!ibgeCode && (
					<footer className="m-auto">
						<NoRecordsFeedback
							message={"Nenhuma Unidade Especificada!!"}
							icon={<FaHome />}
						/>
					</footer>
				)}
			</footer>
		</>
	);
}

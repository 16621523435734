import { useEffect } from "react";

import { useLayout } from "@/../_metronic/layout/core";

import { ReleaseNotes } from "@/components/ReleaseNotes";

export function ReleaseNotesManagement() {
	const { setTitle } = useLayout();
	useEffect(() => {
		setTitle("HISTÓRICO DE VERSÕES");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <ReleaseNotes />;
}

import Table from "react-bootstrap/Table";
import { TripleViralVaccinationsByCityAndPeriod } from "..";

type Props = {
	tripleViralVaccinationsByCityAndPeriods: TripleViralVaccinationsByCityAndPeriod[];
};

export function TripleViralDosagesAppliedTable({ tripleViralVaccinationsByCityAndPeriods }: Props) {
	return (
		<Table
			bordered
			responsive
			hover
			id="table-vaccinated-citizens"
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>MUNICÍPIO</th>
					<th>D1</th>
					<th>D2</th>
					<th>DU</th>
					<th>TOTAL</th>
					<th>MÉDIA MENSAL</th>
				</tr>
			</thead>
			<tbody className="table-tbody" id="triple-viral-dosages-applied-table">
				{tripleViralVaccinationsByCityAndPeriods.map(
					(tripleViralVaccinationsByCityAndPeriod, index) => (
						<tr
							key={`city-vaccinations-${index}`}
							className="text-center border-bottom-1 border-dark"
						>
							<td className="text-start">
								{tripleViralVaccinationsByCityAndPeriod.city.locale}
							</td>
							<td>
								{tripleViralVaccinationsByCityAndPeriod.vaccinationsResumes
									.map(
										(vaccinationsResume) =>
											vaccinationsResume.vaccinationResumes.find(
												(vaccinationResume) =>
													vaccinationResume.immunobiologicalDosage
														.acronym === "D1"
											)?.total || 0
									)
									.reduce((acc, current) => acc + current, 0)}
							</td>
							<td>
								{tripleViralVaccinationsByCityAndPeriod.vaccinationsResumes
									.map(
										(vaccinationsResume) =>
											vaccinationsResume.vaccinationResumes.find(
												(vaccinationResume) =>
													vaccinationResume.immunobiologicalDosage
														.acronym === "D2"
											)?.total || 0
									)
									.reduce((acc, current) => acc + current, 0)}
							</td>
							<td>
								{tripleViralVaccinationsByCityAndPeriod.vaccinationsResumes
									.map(
										(vaccinationsResume) =>
											vaccinationsResume.vaccinationResumes.find(
												(vaccinationResume) =>
													vaccinationResume.immunobiologicalDosage
														.acronym === "DU"
											)?.total || 0
									)
									.reduce((acc, current) => acc + current, 0)}
							</td>
							<td>
								{tripleViralVaccinationsByCityAndPeriod.vaccinationsResumes
									.map(
										(vaccinationsResume) =>
											vaccinationsResume.vaccinationResumes
												.map((vaccinationResume) => vaccinationResume.total)
												.reduce((acc, current) => acc + current, 0) || 0
									)
									.reduce((acc, current) => acc + current, 0)}
							</td>
							<td>
								{Math.round(
									tripleViralVaccinationsByCityAndPeriod.vaccinationsResumes
										.map(
											(vaccinationsResume) =>
												vaccinationsResume.vaccinationResumes
													.map(
														(vaccinationResume) =>
															vaccinationResume.total
													)
													.reduce((acc, current) => acc + current, 0) || 0
										)
										.reduce((acc, current) => acc + current, 0) /
										(tripleViralVaccinationsByCityAndPeriod?.vaccinationsResumes
											?.length || 1)
								)}
							</td>
						</tr>
					)
				)}
			</tbody>
		</Table>
	);
}

export function removeDuplicates<Type>(array: Array<unknown>): Type {
	if (!array || !array.length) {
		return [] as unknown as Type;
	}

	return array.filter((value, index) => {
		const _value = JSON.stringify(value);
		return (
			index ===
			array.findIndex((obj) => {
				return JSON.stringify(obj) === _value;
			})
		);
	}) as unknown as Type;
}

export function displaySingularOrPlural(number: number, text: string) {
	const plural = number !== 1 ? "s" : "";
	return `${number ?? 0} ${text}${plural}`;
}

export function onlyNumbers(value: string) {
	return value.replace(/\D/g, "");
}

import { useState } from "react";

import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { authApi } from "@/services/api";
import { identifierReplaceAll } from "@/utils/formatIdentifier";
import { MessageSenderTypeEnum } from "@/utils/enums/MessageSenderTypeEnum";
import { User } from "@/@types/user";

import { UpdatePassword } from "../UpdatePassword";
import { BaseModalProps } from "@/components/BaseModal";

type Props = BaseModalProps & {
	infoMask?: User;
	identifier: string;
};

export function ChooseConfirmation({ infoMask, identifier, ...rest }: Props) {
	const [messageSenderSelected, setMessageSenderSelected] = useState<number>(
		MessageSenderTypeEnum.EMAIL
	);
	const [showConfirmationCode, setShowConfirmationCode] = useState(false);

	async function handleShowConfirmationCode() {
		const identifierValid = identifierReplaceAll(identifier);

		try {
			const response = await authApi.get("/v1/auth/SendRecoveryAccountCode", {
				params: {
					identifier: identifierValid,
					senderType: messageSenderSelected,
				},
			});

			if (response.status === 200) {
				setShowConfirmationCode(true);
				toast.success("Codigo Enviado com sucesso.");
			}
		} catch (error) {
			toast.error("Falha ao enviar o codigo de verificação");
			console.log(error);
		}
	}

	const handleCloseConfirmationCode = () => setShowConfirmationCode(false);
	return (
		<Modal size="lg" scrollable centered {...rest}>
			<Modal.Header className="fs-3">
				<Modal.Title>Selecione como deseja Receber o Código:</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex flex-column py-15 gap-2">
				<UpdatePassword
					show={showConfirmationCode}
					onHide={handleCloseConfirmationCode}
					codeVerificationSentTo={
						messageSenderSelected === MessageSenderTypeEnum.EMAIL
							? infoMask?.email
							: infoMask?.phone
					}
					identifier={identifier}
				/>
				<form className="form-check">
					<section>
						<input
							className="form-check-input mt-5"
							type="radio"
							name="flexRadioDefault"
							id="flexRadioDefault1"
							value={MessageSenderTypeEnum.EMAIL}
							checked={messageSenderSelected === MessageSenderTypeEnum.EMAIL}
							onChange={(event) =>
								setMessageSenderSelected(Number(event.currentTarget.value))
							}
						/>
						<div className="d-flex flex-column">
							<label className="form-check-label">Email:</label>
							<label>{infoMask?.email}</label>
						</div>
					</section>
					<section className="mt-5">
						<input
							className="form-check-input mt-5"
							type="radio"
							name="flexRadioDefault"
							id="flexRadioDefault2"
							value={MessageSenderTypeEnum.WHATSAPP}
							checked={messageSenderSelected === MessageSenderTypeEnum.WHATSAPP}
							onChange={(event) =>
								setMessageSenderSelected(Number(event.currentTarget.value))
							}
						/>
						<div className="d-flex flex-column">
							<label className="form-check-label">Número de Telefone:</label>
							<label>{infoMask?.phone}</label>
						</div>
					</section>
				</form>

				<div className="d-flex justify-content-center gap-2 mt-2">
					<Button onClick={handleShowConfirmationCode}>Prosseguir</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

import { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "@/../_metronic/assets/ts/_utils";

import { CustomChart } from "./CustomChart";
import { Chart } from "@/@types/Chart";

type RadialChartSerie = number;
type RadialChartProps = Chart<RadialChartSerie> & {
	colors: string[];
	labelFontSize?: string;
	baseColor?: string;
	secondaryColor?: string;
	labelColor?: string;
};

export function RadialChart({
	series,
	height,
	width,
	colors,
	labelFontSize,
	labelColor,
	baseColor,
	secondaryColor,
}: RadialChartProps) {
	const labelColorChart: string = labelColor
		? getCSSVariableValue(`--bs-${labelColor}`)
		: getCSSVariableValue(`--bs-gray-700`);

	const backgroundColorChart: string[] = secondaryColor
		? [getCSSVariableValue(`--bs-light-${secondaryColor}`)]
		: colors?.map((color) => getCSSVariableValue(`--bs-light-${color}`));

	const baseColorChart: string[] = baseColor
		? [getCSSVariableValue(`--bs-${baseColor}`)]
		: colors.map((color) => getCSSVariableValue(`--bs-${color}`));

	let options: ApexOptions = {
		chart: {
			fontFamily: "inherit",
			type: "radialBar",
		},
		plotOptions: {
			radialBar: {
				dataLabels: {
					name: {
						show: false,
						fontWeight: "700",
						color: "rgb(94, 98, 120)",
					},
					value: {
						show: true,
						color: labelColorChart,
						fontSize: labelFontSize || "10px",
						fontWeight: "700",
						offsetY: 5,
						formatter: function (val) {
							return val + "%";
						},
					},
				},
				track: {
					margin: 2,
					// @ts-ignore
					background: backgroundColorChart,
					strokeWidth: "100%",
				},
			},
		},
		colors: baseColorChart,
		stroke: {
			lineCap: "butt",
		},
	};
	return (
		<CustomChart
			options={options}
			series={series}
			height={height}
			width={width}
			type="radialBar"
		/>
	);
}

import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { toAbsoluteUrl } from "@/../_metronic/helpers";
import "./styles.scss";

type AuthLayoutProps = {
	loginContainerClassName?: string;
	loginSectionContainerClassName?: string;
	loginFormContainerClassName?: string;
	outletClassName?: string;
	footerContainerClassName?: string;
	isLogin?: boolean;
};

export function AuthLayout({
	loginContainerClassName = "",
	loginSectionContainerClassName = "",
	loginFormContainerClassName = "",
	outletClassName = "",
	footerContainerClassName = "",
	isLogin = false,
}: AuthLayoutProps) {
	useEffect(() => {
		document.body.classList.add("bg-white");
		return () => {
			document.body.classList.remove("bg-white");
		};
	}, []);

	return (
		<div
			className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed"
			id="auth-layout"
		>
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				<div
					className={`d-flex flex-column-fluid flex-column flex-center p-10 login-container ${loginContainerClassName}`}
				>
					<div
						className={`d-flex flex-center login-section-container ${loginSectionContainerClassName}`}
					>
						<div
							className={`bg-body d-flex flex-column gap-10 p-10 pb-15 login-form-container ${loginFormContainerClassName}`}
						>
							<div className="d-flex align-items-center justify-content-center mt-5">
								<img
									id="login-body-logo"
									src={toAbsoluteUrl("/media/logos/logo-main-blue.svg")}
									alt="Indicadores ESUS - Logo azul escuro"
								/>
							</div>

							{isLogin && (
								<div className="text-center mb-3">
									<h1 className="text-dark">Login</h1>
								</div>
							)}

							<div className={outletClassName}>
								<Outlet />
							</div>
							<div className="d-flex w-100 justify-content-end ">
								<label className="d-flex">versão 2.3.0</label>
							</div>
						</div>
					</div>

					<div
						className={`mt-5 d-flex flex-column justify-content-end align-items-center footer-container ${footerContainerClassName}`}
					>
						<span className="text-white mb-3" id="product-title">
							Um produto:
						</span>
						<img
							src={toAbsoluteUrl("/media/logos/logo-white.svg")}
							alt="Multintegrada - Tecnologia"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ReleaseNote } from "@/@types/ReleaseNote";

type Props = {
	releaseNote: ReleaseNote;
};

export function ReleaseNoteCard({ releaseNote }: Props) {
	return (
		<Card className="border border-3 secondary">
			<Card.Header className="d-flex align-items-center pt-4">
				<Row className="w-100">
					<Col>
						<h3>{`${releaseNote.version} - ${releaseNote.title}`}</h3>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex flex-column">
						<p>
							<strong>Data de atualização: </strong>
							{releaseNote.createdAt &&
								dayjs(releaseNote.createdAt).format("DD/MM/YYYY hh:mm")}
						</p>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body className="d-flex">
				<div dangerouslySetInnerHTML={{ __html: releaseNote.description }}></div>
			</Card.Body>
		</Card>
	);
}

import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { federalUnits } from "@/utils/federalUnits";
import { getCurrentQuarter } from "@/utils/getQuaterCurrent";
import { CityResumeData } from "@/@types/CityResumeData";
import { FederalUnit } from "@/@types/FederalUnit";
import { Account } from "@/@types/Account";

import { LoadingScreen } from "@/components/LoadingScreen";
import { TableRankingCities } from "./TableRankingCities";
import { Diseases } from "./Diseases";
import { Classification, StateGeographicalNetwork } from "@/components/StateGeographicalNetwork";
import { ResumeDiseasesChronicTransmissibleOfCities } from "./Diseases/ChronicCommunicableDiseases";
import { AbandonmentRateOfTripleViral } from "./AbandonmentRateOfTripleViral";
import { ChildBirth } from "./ChildBirth";
import { ResumeDiseasesChronicNotTransmissibleOfCities } from "./Diseases/ChronicNotCommunicableDiseases";
import { CardDiseases } from "./Diseases/CardDiseases";
import { SemiDonut } from "@/components/Charts/SemiDonut";

export type ResumeCitieEpidemic = {
	city: Account;
	total: number;
};

export function DashboardFederalUnit() {
	const { setTitle } = useLayout();
	const api = useApi();
	const { isLoading, uf } = useCurrentAccount();
	const [isLoadingFetch, setIsLoadingFetch] = useState(false);
	const [isLoadingFetchDiseasesTransmissible, setIsLoadingFetchDiseasesTransmissible] =
		useState(false);
	const [isLoadingFetchDiseasesNotTransmissible, setIsLoadingFetchDiseasesNotTransmissible] =
		useState(false);
	const [dasboardCities, setDashboardCities] = useState<CityResumeData[]>([]);
	const [
		resumeDiseasesChronicTransmissibleOfCities,
		setResumeDiseasesChronicTransmissibleOfCities,
	] = useState<ResumeDiseasesChronicTransmissibleOfCities[]>([]);
	const [
		resumeDiseasesChronicNotTransmissibleOfCities,
		setResumeDiseasesChronicNotTransmissibleOfCities,
	] = useState<ResumeDiseasesChronicNotTransmissibleOfCities[]>([]);

	function getCasesTransmissible(
		locationWithCaseIdentified: ResumeDiseasesChronicTransmissibleOfCities
	) {
		const resume: ResumeDiseasesChronicTransmissibleOfCities = {
			city: locationWithCaseIdentified.city,
			totalHansen: locationWithCaseIdentified.totalHansen,
			totalAttendancesCitizensWithHansen:
				locationWithCaseIdentified.totalAttendancesCitizensWithHansen,
			totalTuberculosis: locationWithCaseIdentified.totalTuberculosis,
			totalAttendancesCitizensWithTuberculosis:
				locationWithCaseIdentified.totalAttendancesCitizensWithTuberculosis,
		};
		return resume;
	}

	function getCasesNotTransmissible(
		locationWithCaseIdentified: ResumeDiseasesChronicNotTransmissibleOfCities
	) {
		const resume: ResumeDiseasesChronicNotTransmissibleOfCities = {
			city: locationWithCaseIdentified.city,
			totalDiabetics: locationWithCaseIdentified.totalDiabetics,
			totalAttendancesCitizensWithDiabetics:
				locationWithCaseIdentified.totalAttendancesCitizensWithDiabetics,
			totalHypertension: locationWithCaseIdentified.totalHypertension,
			totalAttendancesCitizensWithHypertension:
				locationWithCaseIdentified.totalAttendancesCitizensWithHypertension,
		};
		return resume;
	}

	async function handleDashboardCities() {
		try {
			const { data } = await api.get(`/Indicator/v1/DashboardFederalUnit`, {
				params: {
					uf: uf,
					quarter: getCurrentQuarter(),
				},
			});
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchResumeDiseasesChronicTransmissibleOfCities() {
		try {
			const { data } = await api.get(
				`Epidemic/v1/GetResumeDiseasesChronicTransmissibleOfCities`,
				{
					params: {
						uf: uf,
						startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
						endDate: dayjs().format("YYYY-MM-DD"),
					},
				}
			);

			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchResumeDiseasesChronicNotTransmissibleOfCities() {
		try {
			const { data } = await api.get(
				`Epidemic/v1/GetResumeDiseasesChronicNotTransmissibleOfCities`,
				{
					params: {
						uf: uf,
						startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
						endDate: dayjs().format("YYYY-MM-DD"),
					},
				}
			);

			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetch() {
		setIsLoadingFetch(true);
		setDashboardCities(await handleDashboardCities());
		setIsLoadingFetchDiseasesTransmissible(true);
		setResumeDiseasesChronicTransmissibleOfCities(
			await fetchResumeDiseasesChronicTransmissibleOfCities()
		);
		setIsLoadingFetchDiseasesTransmissible(false);
		setIsLoadingFetchDiseasesNotTransmissible(true);
		setResumeDiseasesChronicNotTransmissibleOfCities(
			await fetchResumeDiseasesChronicNotTransmissibleOfCities()
		);
		setIsLoadingFetchDiseasesNotTransmissible(false);
		setIsLoadingFetch(false);
	}

	useEffect(() => {
		setTitle("DASHBOARD ESTADUAL");
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const totalCitizensAttendancesWithHansenAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithHansen)
			.reduce((acc, current) => acc + current, 0);
	const totalCitizensAttendancesWithTuberculosisAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithTuberculosis)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithHansenAllCities: number = resumeDiseasesChronicTransmissibleOfCities
		?.map((city) => city.totalHansen)
		.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithTuberculosisAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalTuberculosis)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensAttendancesWithHypertensionAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithHypertension)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensAttendancesWithDiabeticsAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithDiabetics)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithHypertensionAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalHypertension)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithDiabeticsAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalDiabetics)
			.reduce((acc, current) => acc + current, 0);

	return (
		<div className="overflow-auto" style={{ minWidth: "320px" }}>
			<LoadingScreen loading={isLoadingFetch} />
			<section className="mt-5 p-3">
				<h1 className="text-center text-white bg-primary p-2 mx-2 mb-5 rounded ">
					RANKING DO PREVINE BRASIL POR MUNICIPIO NO ÚLTIMO QUADRIMESTRE
				</h1>
				<Card className="p-3">
					<TableRankingCities rankedCities={dasboardCities} />
				</Card>
			</section>
			<section className="mt-5 p-3">
				<div className="d-flex flex-column text-center justify-content-center p-3 bg-white ">
					<h1 className="text-white bg-primary p-2 mx-2 mb-5 rounded ">
						MAPA ESTADUAL DE DOENÇAS CRÔNICAS NOS ÚLTIMOS 12 MESES
					</h1>
					<Row className="bg-white align-items-center">
						<Col sm={12} md={12} lg={8} xl={8} className="h-100 bg-white">
							{!isLoading && uf && (
								<StateGeographicalNetwork
									federalUnit={
										federalUnits.find(
											(federalUnit) => federalUnit.abbreviation === uf
										) || ({} as FederalUnit)
									}
									classifications={resumeDiseasesChronicTransmissibleOfCities.map<Classification>(
										(cityWithHansen) => {
											return { city: cityWithHansen.city, color: "#009ef7" };
										}
									)}
									casesTransmissible={resumeDiseasesChronicTransmissibleOfCities.map<ResumeDiseasesChronicTransmissibleOfCities>(
										(cityWithHansen) => getCasesTransmissible(cityWithHansen)
									)}
									casesNotTransmissible={resumeDiseasesChronicNotTransmissibleOfCities.map<ResumeDiseasesChronicNotTransmissibleOfCities>(
										(cityWithHansen) => getCasesNotTransmissible(cityWithHansen)
									)}
									height="600"
									width="600"
								/>
							)}
						</Col>
						<Col
							sm={12}
							md={12}
							lg={4}
							xl={4}
							className="d-flex flex-column align-items-center h-100"
						>
							<CardDiseases
								title="Hanseníase"
								value={totalCitizensWithHansenAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithHansenAllCities,
														totalCitizensWithHansenAllCities -
															totalCitizensAttendancesWithHansenAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Tuberculose"
								value={totalCitizensWithTuberculosisAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithTuberculosisAllCities,
														totalCitizensWithTuberculosisAllCities -
															totalCitizensAttendancesWithTuberculosisAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Hipertensos"
								value={totalCitizensWithHypertensionAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesNotTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithHypertensionAllCities,
														totalCitizensWithHypertensionAllCities -
															totalCitizensAttendancesWithHypertensionAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Diabéticos"
								value={totalCitizensWithDiabeticsAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesNotTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithDiabeticsAllCities,
														totalCitizensWithDiabeticsAllCities -
															totalCitizensAttendancesWithDiabeticsAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
						</Col>
					</Row>
				</div>
			</section>
			<section>
				<Diseases
					resumeDiseasesChronicNotTransmissibleOfCities={
						resumeDiseasesChronicNotTransmissibleOfCities
					}
					resumeDiseasesChronicTransmissibleOfCities={
						resumeDiseasesChronicTransmissibleOfCities
					}
				/>
			</section>
			<section className="mt-5 p-3">
				<h1 className="text-center text-white bg-primary p-2 mx-2 mb-5 rounded ">
					MATERNO INFANTIL
				</h1>
				<ChildBirth uf={uf} />
			</section>
			<section className="mt-5 p-3">
				<AbandonmentRateOfTripleViral uf={uf} />
			</section>
		</div>
	);
}

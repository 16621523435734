import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { DrawerComponent } from "../../assets/ts/components";

type Props = {
	children?: React.ReactNode;
};

const Content: React.FC<Props> = ({ children }) => {
	const location = useLocation();
	useEffect(() => {
		DrawerComponent.hideAll();
	}, [location]);

	return (
		<div id="kt_content_container" className="container-fluid p-0">
			{children}
		</div>
	);
};

export { Content };

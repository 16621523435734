import { createContext, ReactNode, useState, useEffect } from "react";

import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Professional } from "@/@types/Professional";

type ProfessionalContextType = {
	professionals: Professional[];
};

export const ProfessionalsContext = createContext({} as ProfessionalContextType);

type ProfessionalsContextProviderProps = {
	children: ReactNode;
};

export function ProfessionalsContextProvider({ children }: ProfessionalsContextProviderProps) {
	const api = useApi();
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function getUnits() {
		if (!ibgeCode) return;
		try {
			const response = await api.get<Professional[]>("/professional/v1/getprofessionals", {
				params: { uf, ibgeCode, cnes },
			});
			setProfessionals(response?.data || []);
		} catch (error) {
			console.error(error);
			setProfessionals([]);
		}
	}

	useEffect(() => {
		getUnits();

		return () => setProfessionals([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfessionalsContext.Provider value={{ professionals }}>
			{children}
		</ProfessionalsContext.Provider>
	);
}

import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import axios from "axios";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import { FaUser } from "react-icons/fa";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate, indicatorsDetail } from "@/utils/indicators";
import { IndicatorType } from "@/utils/enums/indicatorType";
import { exportExcel } from "@/utils/exports";
import { Indicators } from "@/utils/enums/indicators";
import { Paginated } from "@/@types/paginated";
import { Option } from "@/@types/Option";
import { GenericObject, SelectOptionsProps } from "@/@types/genericals";
import { ExportTableType } from "@/@types/ExportTableType";
import { PregnantPatient } from "@/@types/PregnantPatient";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { InformationIndicatorCard } from "@/components/InformationIndicatorCard";
import { LoadingScreen } from "@/components/LoadingScreen";
import { TableIndicator3 } from "./TableIndicator3";
import { TableIndicator3ExportPdf } from "./TableIndicator3ExportPdf";
import { FiltersIndicator3 } from "./FiltersIndicator3";
import { ReportIndicator3 } from "./ReportIndicator3";

type IndicatorResume = {
	valid: number;
	pendingEligible: number;
	pendingIneligible: number;
};

type Props = {
	selectedUnit: SelectOptionsProps;
	selectedHealthTeam: SelectOptionsProps;
	selectedQuarter: SelectOptionsProps;
};

export function Indicator3({ selectedUnit, selectedHealthTeam, selectedQuarter }: Props) {
	const api = useApi();

	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { setTitle } = useLayout();
	const { indicator3 } = indicatorsDetail;

	const { CancelToken } = axios;
	const indicatorSourceCancel = CancelToken.source();
	const resumeSourceCancel = CancelToken.source();

	const [indicatorResume, setIndicatorResume] = useState<IndicatorResume>();
	const [selectedIndicatorTableType, setSelectedIndicatorTableType] = useState<
		Option<IndicatorType | null>
	>({ value: null, label: "Todos" } as Option<IndicatorType | null>);
	const [isMounted, setIsMounted] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const printTableRef = useRef<HTMLTableElement | null>(null);
	const printPageRef = useRef<HTMLDivElement | null>(null);
	const [pregnantPatientExportPdf, setPregnantPatientExportPdf] = useState<
		Paginated<PregnantPatient>
	>({} as Paginated<PregnantPatient>);
	const [pregnantPatient, setPregnantPatient] = useState<Paginated<PregnantPatient>>(
		{} as Paginated<PregnantPatient>
	);
	const [searchBirthdateCitizen, setSearchBirthdateCitizen] = useState("");
	const debouncedSearchBirthdateCitizen = useDebounce(searchBirthdateCitizen, 1000);
	const [searchFullNameCitizen, setSearchFullNameCitizen] = useState("");
	const debouncedFullName = useDebounce(searchFullNameCitizen, 2000);

	async function handleIndicator3(pageSize?: number) {
		try {
			setIsLoading(true);
			const { data } = await api.get<Paginated<PregnantPatient>>(
				`/indicator/v1/getindicator`,
				{
					cancelToken: indicatorSourceCancel.token,
					params: {
						indicator: Indicators.PregnantWomenWithDentalCarePerformed,
						indicatorType: selectedIndicatorTableType?.value,
						searchBirthDateCitizen: searchBirthdateCitizen,
						searchCitizenFullName: searchFullNameCitizen,
						quarter: selectedQuarter.value,
						uf,
						ibgeCode,
						pageNumber,
						pageSize: pageSize || 10,
						cnes: selectedUnit.value || cnes,
						ine: selectedHealthTeam.value,
					},
				}
			);
			setIsLoading(false);
			return data || ({} as Paginated<PregnantPatient>);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			return {} as Paginated<PregnantPatient>;
		}
	}

	async function handleIndicatorResume() {
		try {
			setIsLoading(true);
			const { data } = await api.get<IndicatorResume>(`/indicator/v1/getResume`, {
				cancelToken: resumeSourceCancel.token,
				params: {
					indicator: Indicators.PregnantWomenWithDentalCarePerformed,
					quarter: selectedQuarter.value,
					uf,
					ibgeCode,
					cnes: selectedUnit.value || cnes,
					ine: selectedHealthTeam.value,
				},
			});
			setIsLoading(false);
			return data || ({} as IndicatorResume);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			return {} as IndicatorResume;
		}
	}

	async function handleExportTable(exportTableType: ExportTableType) {
		setShowLoadingScreen(true);
		const response = await handleIndicator3(pregnantPatient?.totalRecords);
		setPregnantPatientExportPdf(response);
		setShowLoadingScreen(false);

		if (Object.keys(response?.data || []).length) {
			if (exportTableType === "print") {
				printTable();
			} else if (exportTableType === "excel") {
				const excelData: GenericObject[] = transformPregnantPatientsToExportExcel(
					response?.data
				);
				exportExcel(excelData, `Tabela de Exames ${selectedIndicatorTableType}`);
			}
		}
	}

	function transformPregnantPatientsToExportExcel(pregnantPatients: PregnantPatient[]) {
		return pregnantPatients.map(
			(pregnantPatient) =>
				({
					Sinc: ["PEC", "PEC", "SISAB", "SINCRONIZADO"][
						pregnantPatient.preliminaryReportStatus
					],
					Nome: pregnantPatient.fullName,
					"Data de Nascimento": dayjs(pregnantPatient.birthdate).format("DD/MM/YYYY"),
					"Cpf/Cns": identifierOrNationalHealthCard(pregnantPatient),
					Equipe: pregnantPatient.healthTeam?.name || "NÃO INFORMADO",
					"Micro área": pregnantPatient.area,
					"Data Última Menstruação": displayFirstDumDate(pregnantPatient.firstDumDate),
					DPP: dayjs(pregnantPatient.dppDate).format("DD/MM/YYYY"),
				} as GenericObject)
		);
	}

	const printTable = useReactToPrint({
		content: () => printTableRef.current,
	});

	async function fetch() {
		if (isMounted) {
			setPregnantPatient(await handleIndicator3());
		}
	}

	async function fetchResume() {
		setIndicatorResume(await handleIndicatorResume());
	}

	const printPage = useReactToPrint({
		content: () => printPageRef.current,
	});

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetch();

		return () => {
			if (indicatorSourceCancel) {
				indicatorSourceCancel.cancel("Request canceled because change filters.");
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedHealthTeam,
		selectedQuarter,
		selectedUnit,
		debouncedFullName,
		debouncedSearchBirthdateCitizen,
		selectedIndicatorTableType,
	]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetch();
		}
		return () => {
			if (indicatorSourceCancel) {
				indicatorSourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		fetchResume();
		setPageNumber(1);
		return () => {
			if (resumeSourceCancel) {
				resumeSourceCancel.cancel("Request canceled because the component was ummonted.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedHealthTeam, selectedQuarter, selectedUnit]);

	useEffect(() => {
		setTitle("PAINEL DE INDICADORES");
		return () => {
			setIsMounted(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoadingScreen
				loading={showLoadingScreen}
				messages={[
					"Já estamos processando a geração da tabela.",
					"Os pacientes já vão aparecer, aguarde só um momento.",
					"Está quase lá!",
				]}
			/>

			<InformationIndicatorCard
				isLoading={isLoading}
				handlePrint={printPage}
				setSelectedIndicatorTableType={setSelectedIndicatorTableType}
				totalSeries={
					(indicatorResume?.valid || 0) +
					(indicatorResume?.pendingEligible || 0) +
					(indicatorResume?.pendingIneligible || 0)
				}
				totalValids={indicatorResume?.valid || 0}
				totalPendingEligible={indicatorResume?.pendingEligible || 0}
				totalPendingIneligible={indicatorResume?.pendingIneligible || 0}
				indicatorDetails={indicator3}
			/>
			<section className="mt-5">
				<Card
					className={`border border-3 border-${
						selectedIndicatorTableType.value === IndicatorType.Validated
							? "primary"
							: selectedIndicatorTableType.value === IndicatorType.PendingEligible
							? "warning"
							: selectedIndicatorTableType.value === IndicatorType.PendingIneligible
							? "danger"
							: "secondary"
					}`}
				>
					<Card.Header className="border-0 flex-nowrap d-flex justify-content-between no-print">
						<FiltersIndicator3
							handleExportTable={handleExportTable}
							searchBirthdate={searchBirthdateCitizen}
							searchCitizen={searchFullNameCitizen}
							indicatorType={selectedIndicatorTableType}
							setIndicatorType={setSelectedIndicatorTableType}
							setSearchBirthdate={setSearchBirthdateCitizen}
							setSearchCitizen={setSearchFullNameCitizen}
							isLoading={isLoading}
						/>
					</Card.Header>
					<Card.Body>
						<TableIndicator3 isLoading={isLoading} pregnantPatient={pregnantPatient} />
					</Card.Body>
					<Card.Footer className="no-print">
						{pregnantPatient.totalRecords ? (
							<PaginationLinks
								itemsPerPage={pregnantPatient.pageSize}
								totalPages={pregnantPatient.totalRecords}
								changeSelectedPage={handleChangePageNumber}
								pageNumber={pageNumber || 0}
							/>
						) : (
							pregnantPatient.totalRecords === 0 &&
							!isLoading && (
								<NoRecordsFeedback
									message={"Nenhum resultado encontrado."}
									icon={<FaUser />}
								/>
							)
						)}
					</Card.Footer>
				</Card>
			</section>
			<section title="relatório" className="print-container" ref={printPageRef}>
				<ReportIndicator3
					title={indicator3.title}
					healthTeam={selectedHealthTeam}
					healthUnit={selectedUnit}
					quarter={selectedQuarter}
				/>
				<div className="mt-20">
					<InformationIndicatorCard
						indicatorDetails={indicator3}
						isLoading={isLoading}
						setSelectedIndicatorTableType={setSelectedIndicatorTableType}
						totalSeries={
							(indicatorResume?.valid || 0) +
							(indicatorResume?.pendingEligible || 0) +
							(indicatorResume?.pendingIneligible || 0)
						}
						totalValids={indicatorResume?.valid || 0}
						totalPendingEligible={indicatorResume?.pendingEligible || 0}
						totalPendingIneligible={indicatorResume?.pendingIneligible || 0}
					/>
				</div>
			</section>
			<section title="exportação de tabela pdf">
				{pregnantPatientExportPdf?.data && (
					<TableIndicator3ExportPdf
						tableRef={printTableRef}
						pregnantPatient={pregnantPatientExportPdf}
					/>
				)}
			</section>
		</div>
	);
}

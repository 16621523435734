import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { Account } from "@/@types/Account";

import { TableRankingTripleViral, calculateAbandonmentRate } from "./TableRankingTripleViral";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

export type ResumeAbandonmentTripleViral = {
	city: Account;
	totalChildren: number;
	totalWithoutVaccination: number;
	totalWithVaccinationPending: number;
	totalWithVaccinationFinished: number;
	totalD1: number;
	totalD2: number;
	totalDU: number;
};

type Props = { uf: string };

export function AbandonmentRateOfTripleViral({ uf }: Props) {
	const api = useApi();
	const [isLoading, setIsLoading] = useState(false);
	const [resumeAbandonmentTripleViral, setResumeAbandonmentTripleViral] = useState<
		ResumeAbandonmentTripleViral[]
	>([]);

	const resumeGlobal: ResumeAbandonmentTripleViral = {
		city: {} as Account,
		totalChildren: getAbandonmentTripleViralResume(
			resumeAbandonmentTripleViral,
			"totalChildren"
		),
		totalWithoutVaccination: getAbandonmentTripleViralResume(
			resumeAbandonmentTripleViral,
			"totalWithoutVaccination"
		),
		totalWithVaccinationPending: getAbandonmentTripleViralResume(
			resumeAbandonmentTripleViral,
			"totalWithVaccinationPending"
		),
		totalWithVaccinationFinished: getAbandonmentTripleViralResume(
			resumeAbandonmentTripleViral,
			"totalWithVaccinationFinished"
		),
		totalD1: getAbandonmentTripleViralResume(resumeAbandonmentTripleViral, "totalD1"),
		totalD2: getAbandonmentTripleViralResume(resumeAbandonmentTripleViral, "totalD2"),
		totalDU: getAbandonmentTripleViralResume(resumeAbandonmentTripleViral, "totalDU"),
	};

	function getAbandonmentTripleViralResume(
		resumeAbandonmentTripleViral: ResumeAbandonmentTripleViral[],
		key: keyof ResumeAbandonmentTripleViral
	) {
		return resumeAbandonmentTripleViral
			?.map((federalUnit) => federalUnit[key] as number)
			.reduce((acc, current) => acc + current, 0);
	}

	async function handleResumeAbandonmentTripleViral() {
		try {
			const { data } = await api.get(
				`/federalunitvaccination/v1/ResumeAbandonmentTripleViral`,
				{
					params: {
						uf: uf,
						startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
						endDate: dayjs().format("YYYY-MM-DD"),
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetch() {
		setIsLoading(true);
		setResumeAbandonmentTripleViral(await handleResumeAbandonmentTripleViral());
		setIsLoading(false);
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<h1 className="text-white text-center bg-primary p-2 mx-2 mb-5 rounded ">
				TAXA DE ABANDONO DE TRÍPLICE VIRAL EM CRIANÇAS{" "}
				<CardOverlayTrigger message="Aplicações realizadas entre 1 e 2 anos de idade em crianças que completaram 2 anos nos últimos 12 meses" />
			</h1>
			<Row className="mb-3">
				<Col sm={12} md={3} lg={3} xl={3} className="mb-2">
					<Card className="h-100 bg-primary">
						<Card.Body className="d-flex flex-column justify-content-center text-center">
							<label className="text-white fw-bolder fs-4">D1 Tríplice viral</label>
							<label className="text-white fw-bolder fs-1">
								{getAbandonmentTripleViralResume(
									resumeAbandonmentTripleViral,
									"totalD1"
								)?.toLocaleString()}
							</label>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={3} lg={3} xl={3} className="mb-2">
					<Card className="h-100 bg-primary">
						<Card.Body className="d-flex flex-column justify-content-center text-center">
							<label className="text-white fw-bolder fs-4"> D2 Tríplice viral</label>
							<label className="text-white fw-bolder fs-1">
								{getAbandonmentTripleViralResume(
									resumeAbandonmentTripleViral,
									"totalD2"
								)?.toLocaleString()}
							</label>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={3} lg={3} xl={3} className="mb-2">
					<Card className="h-100 bg-primary">
						<Card.Body className="d-flex flex-column justify-content-center text-center">
							<label className="text-white fw-bolder fs-4"> DU Tetraviral</label>
							<label className="text-white fw-bolder fs-1">
								{getAbandonmentTripleViralResume(
									resumeAbandonmentTripleViral,
									"totalDU"
								)?.toLocaleString()}
							</label>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={3} lg={3} xl={3} className="mb-2">
					<Card className="h-100 bg-primary">
						<Card.Body className="d-flex flex-column justify-content-center text-center">
							<label className="text-white fw-bolder fs-4"> Taxa de abandono</label>
							<label className="text-white fw-bolder fs-1">
								{Math.round(calculateAbandonmentRate(resumeGlobal) * 100) / 100}%
							</label>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<TableRankingTripleViral
				resumeAbandonmentTripleViral={resumeAbandonmentTripleViral}
				isLoading={isLoading}
			/>
		</div>
	);
}

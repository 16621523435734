import { RadialChart } from "@/components/Charts/RadialChart";

type Props = {
	percentage: number;
};

export function TripleViralResumeChart({ percentage }: Props) {
	function getChartColor(percentage: number) {
		if (percentage >= 95) return "blue";
		if (percentage >= 20) return "yellow";
		return "red";
	}

	return (
		<RadialChart
			labelFontSize="1.5rem"
			width={400}
			colors={[getChartColor(percentage)]}
			secondaryColor="muted"
			series={[percentage]}
		/>
	);
}

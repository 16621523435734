import { useEffect, useState } from "react";

import axios from "axios";

import { FaUser } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useDebounce } from "@/hooks/useDebounce";
import { Paginated } from "@/@types/paginated";
import { ActiveChildVaccination } from "@/@types/ActiveChildVaccination";
import { HealthUnit } from "@/@types/HealthUnit";
import { Option } from "@/@types/Option";
import { HealthTeam } from "@/@types/HealthTeam";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { SearchInput } from "@/components/SearchInput";
import { GlobalInformativeActiveVaccination } from "../GlobalInformativeActiveVaccination";
import { Skeleton } from "@/components/Skeleton";
import { TableActiveChildVaccinationSearch } from "./TableActiveChildVaccination";
import { BarChart } from "@/components/Charts/BarChart";
import { ActiveVaccinationFilters } from "../ActiveVaccinationFilters";

type ActiveVaccinationResume = {
	totalCitizens: number;
	immunobiologicalName: string;
	immunobiologicalPreview: number;
	immunobiologicalDelayed: number;
};

type Props = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export function ActiveVaccinationChild({ uf, ibgeCode, cnes }: Props) {
	const api = useApi();
	const { setTitle } = useLayout();
	const { CancelToken } = axios;
	const sourceCancel = CancelToken.source();
	const [searchFullName, setSearchFullName] = useState<string>("");
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const [selectedHealthUnit, setSelectedHealthUnit] = useState<Option<HealthUnit>>(
		{} as Option<HealthUnit>
	);
	const [selectedHealthTeam, setSelectedhealthTeam] = useState<Option<HealthTeam>>(
		{} as Option<HealthTeam>
	);
	const [activeChildVaccinationData, setActiveChildVaccinationData] = useState<
		Paginated<ActiveChildVaccination>
	>({} as Paginated<ActiveChildVaccination>);
	const [activeChildVaccinationResume, setActiveChildVaccinationResume] = useState<
		ActiveVaccinationResume[]
	>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingResume, setIsLoadingResume] = useState(false);

	const totalImmunobiologicalPreview = activeChildVaccinationResume
		.map((item) => item.immunobiologicalPreview)
		.reduce((acc, curr) => acc + curr, 0);
	const totalImmunobiologicalDelayed = activeChildVaccinationResume
		.map((item) => item.immunobiologicalDelayed)
		.reduce((acc, curr) => acc + curr, 0);

	const imunobiologicalCategories = activeChildVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalName);

	const vaccinesDelayedSeries = activeChildVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalDelayed);

	const vaccinesPendingSeries = activeChildVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalPreview - vaccine.immunobiologicalDelayed);

	async function handleActiveChildVaccinationResume() {
		try {
			const { data } = await api.get<ActiveVaccinationResume[]>(
				`/ActiveVaccination/v1/GetResumeActiveVaccinationChild`,
				{
					params: {
						uf: uf,
						ibgeCode: ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
						ine: selectedHealthTeam.value,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleActiveChildVaccinationSearch() {
		try {
			const { data } = await api.get<Paginated<ActiveChildVaccination>>(
				`/ActiveVaccination/v1/GetChilds`,
				{
					cancelToken: sourceCancel.token,
					params: {
						uf: uf,
						ibgeCode: ibgeCode,
						pageNumber: pageNumber,
						fullName: searchFullName,
						cnes: selectedHealthUnit.value || cnes,
						ine: selectedHealthTeam.value,
					},
				}
			);
			return data;
		} catch (error) {
			console.log(error);
			return {} as Paginated<ActiveChildVaccination>;
		}
	}

	async function fetchResume() {
		setIsLoadingResume(true);
		setActiveChildVaccinationResume([]);
		setActiveChildVaccinationResume(await handleActiveChildVaccinationResume());
		setIsLoadingResume(false);
	}

	async function fetchData() {
		setIsLoading(true);
		setActiveChildVaccinationData(await handleActiveChildVaccinationSearch());
		setIsLoading(false);
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		setTitle("BUSCA ATIVA VACINAL");
		fetchResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetchData();
		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change filters.");
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetchData();
		}
		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	return (
		<div>
			<ActiveVaccinationFilters
				handleSearch={() => {
					fetchResume();
					fetchData();
				}}
				setSelectedHealthUnit={setSelectedHealthUnit}
				setSelectedHealthTeam={setSelectedhealthTeam}
			/>
			<Row>
				<GlobalInformativeActiveVaccination
					total={activeChildVaccinationResume.at(0)?.totalCitizens || 0}
					totalPreview={totalImmunobiologicalPreview}
					totalPending={totalImmunobiologicalPreview - totalImmunobiologicalDelayed}
					totalDelayed={totalImmunobiologicalDelayed}
				/>
			</Row>
			<Row className="d-flex justify-content-stretch my-2">
				<Col
					md={6}
					lg={6}
					xl={6}
					className="d-flex align-items-center justify-content-center bg-white"
				>
					<Card className="w-100 h-100">
						<Card.Header>
							<Card.Title>QUANTIDADE DE VACINAS PREVISTAS</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center w-100 h-100">
							{!isLoadingResume ? (
								<BarChart
									width={500}
									height={
										activeChildVaccinationResume?.length < 10
											? 400
											: activeChildVaccinationResume?.length * 40
									}
									categories={imunobiologicalCategories}
									series={[
										{
											name: "Pendentes",
											data: vaccinesPendingSeries,
										},
										{
											name: "Atrasadas",
											data: vaccinesDelayedSeries,
										},
									]}
									colors={["#ffc700", "#f1416c"]}
									isStacked
								/>
							) : (
								<div className="d-flex flex-column w-100 text-center  ">
									<Skeleton height={250} width="95%" />
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>

				<Col md={6} lg={6} xl={6}>
					<Card className="w-100 h-100">
						<Card.Header className="text-center">
							<Card.Title>DETALHAMENTO POR IMUNOBIOLÓGICO</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-2 w-100 h-100">
							{!isLoadingResume ? (
								<Table>
									<thead>
										<tr>
											<th className="fw-bolder text-center text-muted">
												Imunobiológico
											</th>
											<th className="fw-bolder text-center bg-info opacity-75 text-white">
												Previsto
											</th>
											<th className="fw-bolder text-center pe-2 bg-warning opacity-75 text-white">
												Pendentes
											</th>
											<th className="fw-bolder text-center bg-danger opacity-75 text-white">
												Atrasadas
											</th>
										</tr>
									</thead>
									<tbody>
										{activeChildVaccinationResume.map((item, index) => (
											<tr key={index}>
												<td className="ps-2 text-muted fs-8">
													{item.immunobiologicalName}
												</td>
												<td className="text-center text-info">
													{item.immunobiologicalPreview?.toLocaleString()}
												</td>
												<td className="text-center text-warning">
													{(
														Number(item.immunobiologicalPreview || 0) -
														Number(item.immunobiologicalDelayed || 0)
													)?.toLocaleString()}
												</td>
												<td className="text-center text-danger">
													{item.immunobiologicalDelayed?.toLocaleString()}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							) : (
								<div className="d-flex flex-column w-100 text-center ">
									<Skeleton height={250} width="95%" />
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Card>
				<Card.Body>
					<Row className="my-3">
						<Col sm={12} md={5} lg={5} xl={5}>
							<label className="mb-2">
								<strong>Pesquisar Cidadão:</strong>
							</label>
							<SearchInput
								placeholder="Pesquisar"
								value={searchFullName}
								setValue={setSearchFullName}
							/>
						</Col>
					</Row>
					<TableActiveChildVaccinationSearch
						activeChildVaccinationData={activeChildVaccinationData}
						isLoading={isLoading}
					/>
				</Card.Body>
				<Card.Footer className="no-print">
					{activeChildVaccinationData.totalRecords ? (
						<PaginationLinks
							itemsPerPage={activeChildVaccinationData.pageSize}
							totalPages={activeChildVaccinationData.totalRecords}
							changeSelectedPage={handleChangePageNumber}
							pageNumber={pageNumber || 1}
						/>
					) : (
						activeChildVaccinationData.totalRecords === 0 &&
						!isLoading && (
							<NoRecordsFeedback
								message={"Nenhum resultado encontrado."}
								icon={<FaUser />}
							/>
						)
					)}
				</Card.Footer>
			</Card>
		</div>
	);
}

import { ActionMeta } from "react-select";

import { Unit } from "@/contexts/UnitsContext";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeam } from "@/@types/HealthTeam";
import { SelectOptionsProps } from "@/@types/genericals";

import { QuartersSelect } from "@/components/QuartersSelect";
import { UnitsSelect } from "@/components/UnitsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";

type ReactSelectChangeFunction = (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;

type Props = {
	selectedQuarter: SelectOptionsProps;
	handleChangeQuarter: ReactSelectChangeFunction;
	units: Unit[];
	selectedUnit: SelectOptionsProps;
	handleChangeUnit: ReactSelectChangeFunction;
	healthTeams: HealthTeam[];
	selectedHealthTeam: SelectOptionsProps;
	handleChangeHealthTeams: ReactSelectChangeFunction;
};

export function DashboardFilters({
	selectedQuarter,
	handleChangeQuarter,
	units,
	selectedUnit,
	handleChangeUnit,
	healthTeams,
	selectedHealthTeam,
	handleChangeHealthTeams,
}: Props) {
	const hasSelectedUnit = selectedUnit.value !== "0";
	const hasSelectedHealthTeam = selectedHealthTeam.value !== "0";
	const { cnes } = useCurrentAccount();

	return (
		<div>
			<div>
				<QuartersSelect
					id="quarters-select"
					value={selectedQuarter}
					onChange={handleChangeQuarter}
				/>
			</div>
			{!cnes && (
				<div>
					<UnitsSelect
						id="units-select"
						units={units}
						value={selectedUnit}
						onChange={handleChangeUnit}
						isDisabled={!hasSelectedUnit && hasSelectedHealthTeam}
					/>
				</div>
			)}
			<div>
				<HealthTeamsSelect
					id="health-teams-select"
					healthTeams={healthTeams}
					value={selectedHealthTeam}
					onChange={handleChangeHealthTeams}
				/>
			</div>
		</div>
	);
}

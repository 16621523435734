import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaSync } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Unit } from "@/contexts/UnitsContext";
import { useApi } from "@/hooks/useApi";

import { BaseModalProps } from "@/components/BaseModal";
import { LoadingScreen } from "../LoadingScreen";

type Props = BaseModalProps & {
	uf?: string;
	ibgeCode?: string;
};

export function ModalUpdateUnits({ uf, ibgeCode, ...rest }: Props) {
	const api = useApi();
	const [units, setUnits] = useState<Unit[]>([]);
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

	async function handleUpdateCityHealthUnits() {
		if (!uf || !ibgeCode) {
			return;
		}
		try {
			setIsLoadingUpdate(true);
			const response = await api.post(`HealthUnit/v1/updateHealthUnits`, undefined, {
				params: { uf, ibgeCode },
			});
			if (response.status === 200 && response.data > 0) {
				toast.success(`${response.data} Unidades adicionadas`, { autoClose: 1500 });
			} else if (response.status === 200 && response.data === 0) {
				toast.info("Unidades estão atualizadas", { autoClose: 1500 });
			} else {
				toast.error("não foi possivel atualizar no momento.");
			}
			getUnits();
		} catch (error) {
			toast.error("Algo Inesperado Aconteceu, verifique a conection String", {
				autoClose: 1500,
			});
		} finally {
			setIsLoadingUpdate(false);
		}
	}

	async function getUnits() {
		if (!uf || !ibgeCode) {
			return;
		}
		try {
			const { data: records } = await api.get("/healthunit/v1/gethealthunits", {
				params: { uf, ibgeCode },
			});
			setUnits(records || []);
		} catch (error) {
			setUnits([]);
		}
	}

	useEffect(() => {
		getUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ibgeCode]);

	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Atualização de Unidades </Modal.Title>
			</Modal.Header>
			<div className="text-center">
				<Button className="btn-sm" onClick={handleUpdateCityHealthUnits}>
					<FaSync /> Buscar Atualização
				</Button>
			</div>
			<Modal.Body>
				<LoadingScreen loading={isLoadingUpdate} />
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>CNES</th>
							<th>NOME</th>
							<th>BAIRRO</th>
						</tr>
					</thead>
					<tbody>
						{units.map((unit: Unit) => (
							<tr key={unit.cnes}>
								<td>{unit.cnes}</td>
								<td>{unit.name}</td>
								<td>{unit.neighborhood}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	);
}

import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";

type RestrictProps = {
	permissions?: string[];
	children?: ReactNode;
	redirectIfNotAuthorized?: boolean;
};

export function Restrict({ permissions, children, redirectIfNotAuthorized }: RestrictProps) {
	const currentAccount = useCurrentAccount();
	const userPermissions = currentAccount.permissions;

	const formattedPermissions = permissions
		? [...permissions, PermissionEnum.SUPER_ADMIN]
		: [PermissionEnum.SUPER_ADMIN];

	const verifiedPermission = userPermissions.find((userPermission) =>
		formattedPermissions.find((permission) => userPermission === permission)
	);
	if (verifiedPermission) {
		return <>{children}</>;
	}
	if (!redirectIfNotAuthorized) {
		return null;
	} else {
		return <Navigate to={"/error/401"}></Navigate>;
	}
}

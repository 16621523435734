import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import classNamesGroups from "clsx";

import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { authApi } from "@/services/api";
import { identifierReplaceAll } from "@/utils/formatIdentifier";

import { BaseModalProps } from "@/components/BaseModal";

type Props = BaseModalProps & {
	codeVerificationSentTo?: string;
	identifier: string;
};

const initialValues = {
	codeConfirmation: "",
	password: "",
	confirmPassword: "",
};

const codeVerificationSchema = Yup.object().shape({
	codeConfirmation: Yup.string()
		.min(7, "Codigo inválido")
		.max(7, "Codigo inválido")
		.required("Codigo Obrigatório"),
	password: Yup.string()
		.min(6, "precisa ter mais que 6 caracteres")
		.max(30, "tamanho máximo excedido")
		.required("Senha Obrigatória"),
	confirmPassword: Yup.string()
		.min(6, "Mínimo de 6 caracteres")
		.max(20, "Máximo de 20 caracteres")
		.required("Confirmação de senha é obrigatória")
		.oneOf([Yup.ref("password"), null], "Senhas não conferem"),
});

export function UpdatePassword({ codeVerificationSentTo, identifier, ...rest }: Props) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues,
		validationSchema: codeVerificationSchema,
		onSubmit: handleSubmit,
	});

	async function handleSubmit() {
		const codeValid = identifierReplaceAll(formik.values.codeConfirmation);
		const identifierValid = identifierReplaceAll(identifier);
		setIsLoading(true);

		try {
			const response = await authApi.post("/v1/auth/RecoveryAccount", {
				identifier: identifierValid,
				code: codeValid,
				password: formik.values.password,
				confirmPassword: formik.values.confirmPassword,
			});

			if (response.status === 200) {
				toast.success("Senha alterada com sucesso.");
				setTimeout(() => {
					navigate("/");
				}, 4000);
			}
		} catch (error) {
			toast.error("Codigo de Confirmação invalido.");
			console.log(error);
		}

		setIsLoading(false);
	}

	return (
		<Modal size="lg" centered scrollable {...rest}>
			<Modal.Header className="d-flex flex-column">
				<Modal.Title>
					<p>Código de Confirmação Enviado para:</p>
				</Modal.Title>
				<p>{codeVerificationSentTo}</p>
			</Modal.Header>
			<Modal.Body>
				<form noValidate id="kt-Code-Verification" onSubmit={formik.handleSubmit}>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex flex-column gap-2">
							<p>Digite o Código Recebido</p>
							<ReactInputMask
								{...formik.getFieldProps("codeConfirmation")}
								className=" form-control form-control text-center mb-5"
								maskChar={null}
								name="codeConfirmation"
								id="input-codeVerification"
								type="text"
								mask="999-999"
								autoFocus
								autoComplete="off"
								style={{ width: "min-content" }}
							/>
							{formik.touched.codeConfirmation && formik.errors.codeConfirmation && (
								<div className="fv-plugins-message-container text-danger">
									<span role="alert">{formik.errors.codeConfirmation}</span>
								</div>
							)}
						</div>
						<div className="d-flex flex-column gap-2 mb-3">
							<label>Nova Senha:</label>

							<input
								{...formik.getFieldProps("password")}
								className={classNamesGroups(
									"form-control form-control-lg px-5 fs-3",
									{
										"is-invalid":
											formik.touched.password && formik.errors.password,
									},
									{
										"is-valid":
											formik.touched.password && !formik.errors.password,
									}
								)}
								type="password"
								name="password"
								disabled={formik.errors.codeConfirmation ? true : false}
							/>

							{formik.touched.password && formik.errors.confirmPassword && (
								<div className="fv-plugins-message-container text-danger m-0">
									<span role="alert">{formik.errors.password}</span>
								</div>
							)}
						</div>
						<div className="d-flex flex-column gap-2">
							<label>Confirme a Senha</label>

							<input
								{...formik.getFieldProps("confirmPassword")}
								className={classNamesGroups(
									"form-control form-control-lg px-5 fs-3",
									{
										"is-invalid":
											formik.touched.confirmPassword &&
											formik.errors.confirmPassword,
									},
									{
										"is-valid":
											formik.touched.confirmPassword &&
											!formik.errors.confirmPassword,
									}
								)}
								name="confirmPassword"
								type="password"
								autoComplete="off"
								disabled={formik.errors.codeConfirmation ? true : false}
							/>
							{formik.touched.confirmPassword && formik.errors.confirmPassword && (
								<p className="text-danger">senhas devem ser iguais!!</p>
							)}
						</div>
					</div>

					<div className="d-flex justify-content-center gap-2 mt-5">
						<Button
							type="submit"
							id="kt_password_reset_submit"
							className="btn btn-lg btn-primary fw-bolder me-4"
							disabled={!formik.isValid}
						>
							<span className="indicator-label text-white">Confirmar</span>
							{isLoading && (
								<span className="indicator-progress">
									Por favor, aguarde...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

import { StateMeasure } from "@/utils/indicators";

type Props = {
	indicatorMeasures: StateMeasure[];
	style?: React.CSSProperties;
};

export function LegendMeasureColor({ indicatorMeasures, style }: Props) {
	return (
		<div className="card-toolbar gap-1 m-2">
			{indicatorMeasures?.map((measure, index) => (
				<span
					key={`stateMeasure-${index}`}
					className={`badge badge-light-${measure?.classColor} m-1 p-0`}
					style={style}
				>
					{measure?.description}
				</span>
			))}
		</div>
	);
}

import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import classNamesGroups from "clsx";
import { IndicatorDetails } from "@/utils/indicators";
import { RadialChart } from "../Charts/RadialChart";

type ChartOptions = {
	chartColors: string[];
	chartHeight?: string;
	chartSeries?: number[];
	chartLabels?: string[];
	chartSize?: string;
};

type Props = {
	isLoading: boolean;
	className?: string;
	indicatorDetails: IndicatorDetails;
	percentage: number;
	showLink?: boolean;
	chartOptions: ChartOptions;
};

export function IndicatorChart({
	isLoading,
	className,
	chartOptions,
	percentage,
	indicatorDetails,
	showLink = true,
}: Props) {
	return (
		<Card className={`card ${className} h-100`}>
			<Card.Header className="border-0 py-5">
				<Card.Title className="align-items-start flex-column w-100">
					<Card.Subtitle className="card-label fw-bolder fs-3 mb-1 w-100">
						{indicatorDetails?.title}
					</Card.Subtitle>
				</Card.Title>
				{indicatorDetails.stateMeasures?.length > 0 && (
					<div className="card-toolbar gap-2">
						<span className="fw-bold">Legenda:</span>
						{indicatorDetails.stateMeasures?.map((stateMeasure, index) => (
							<span
								key={`stateMeasures-${index}`}
								className={`badge badge-light-${stateMeasure?.classColor}`}
							>
								{stateMeasure?.description}
							</span>
						))}
					</div>
				)}
			</Card.Header>
			<hr className="print-container" />
			<Card.Body className="d-flex w-100 p-0 flex-column align-items-center">
				<div>
					<RadialChart
						labelFontSize="1.5rem"
						width={300}
						colors={[indicatorDetails?.getChartColor(percentage)]}
						series={isLoading ? [0] : [percentage]}
					/>
				</div>

				<div>
					<p className={classNamesGroups("text-center fs-6 mx-1", showLink && "pb-5")}>
						{indicatorDetails?.description}
					</p>

					{showLink && (
						<Link
							to={`/componente-qualidade/indicador/${indicatorDetails?.id}/detalhes`}
							className={`btn btn-${chartOptions?.chartColors[0]} py-3 no-print`}
						>
							Ver detalhes
						</Link>
					)}
				</div>
			</Card.Body>
		</Card>
	);
}

import { useEffect, useState, useRef } from "react";

import Card from "react-bootstrap/Card";

import statesAndCities from "@/utils/static/states-and-cities.json";
import { City, StatesAndCities } from "@/@types/statesAndCities";
import { Account } from "@/@types/Account";
import { FederalUnit } from "@/@types/FederalUnit";

import { ResumeDiseasesChronicTransmissibleOfCities } from "@/pages/DashboardFederalUnit/Diseases/ChronicCommunicableDiseases";
import { ResumeDiseasesChronicNotTransmissibleOfCities } from "@/pages/DashboardFederalUnit/Diseases/ChronicNotCommunicableDiseases";

export type Classification = {
	city: Account;
	color: string;
};

type Props = {
	federalUnit: FederalUnit;
	classifications?: Classification[];
	casesNotTransmissible?: ResumeDiseasesChronicNotTransmissibleOfCities[];
	casesTransmissible?: ResumeDiseasesChronicTransmissibleOfCities[];
	width: string;
	height: string;
};

export function StateGeographicalNetwork({
	federalUnit,
	casesNotTransmissible,
	casesTransmissible,
	width,
	height,
	classifications,
}: Props) {
	const [svgData, setSvgData] = useState<string>();
	const svgContainerRef = useRef<HTMLDivElement>(null);
	const { cities } = statesAndCities as StatesAndCities;
	const [selectedCity, setSelectedCity] = useState<City>();

	const citiesWithFederalUnit = cities.filter(
		(city) => city.microrregiao.mesorregiao.UF.sigla === federalUnit.abbreviation
	);

	useEffect(() => {
		const fetchSvg = async () => {
			try {
				const response = await fetch(
					`https://servicodados.ibge.gov.br/api/v3/malhas/estados/${federalUnit.abbreviation}?intrarregiao=municipio`
				);
				const svgText = await response.text();
				setSvgData(svgText);
			} catch (error) {
				console.error("Erro ao obter o SVG:", error);
			}
		};

		fetchSvg();
	}, [federalUnit]);

	function showTooltip(evt: MouseEvent, city?: City) {
		const tooltip = document.getElementById("tooltip");
		if (!tooltip) return;
		setSelectedCity(city);
		tooltip.style.display = "block";
		tooltip.style.left = evt.pageX + 10 + "px";
		tooltip.style.top = evt.pageY + -170 + "px";
	}

	function hideTooltip() {
		const tooltip = document.getElementById("tooltip");
		if (!tooltip) return;
		setSelectedCity({} as City);
		tooltip.style.display = "none";
	}

	useEffect(() => {
		if (svgContainerRef.current && svgData) {
			const svgElement = svgContainerRef.current.querySelector("svg");
			if (svgElement) {
				const paths = svgElement.querySelectorAll("path");
				paths.forEach((path) => {
					const aux = classifications?.find(
						(item) => path.getAttribute("id") === item.city.ibgeCode
					);
					const colorDefault = "#dbdfe9";
					const originalColor = aux?.color || colorDefault;
					path.setAttribute("fill", originalColor);

					// Add hover event listeners
					path.addEventListener("mouseenter", (event) => {
						const cityId = path.getAttribute("id");
						path.setAttribute("fill-opacity", "0.7"); // cor de preenchimento ao passar o mouse
						showTooltip(
							event,
							citiesWithFederalUnit?.find((city) => String(city.id) === cityId)
						);
					});
					path.addEventListener("mouseleave", () => {
						path.setAttribute("fill-opacity", originalColor); // cor original ao sair do mouse
						hideTooltip();
					});
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [svgData, classifications]);

	const applyFillColorTeste = (svgText: string) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(svgText, "image/svg+xml");
		const svgElement = doc.documentElement;

		resizing(width, height, svgElement);

		return new XMLSerializer().serializeToString(svgElement);
	};

	const resizing = (width: string, height: string, svgElement: HTMLElement) => {
		svgElement.setAttribute("width", width);
		svgElement.setAttribute("height", height);
	};

	const coloredSvg = svgData ? applyFillColorTeste(svgData) : null;

	return (
		<div className="mt-2">
			<div
				className="cursor-pointer overflow-auto"
				ref={svgContainerRef}
				dangerouslySetInnerHTML={{ __html: coloredSvg || "" }}
			/>
			<div id="tooltip" style={{ position: "absolute", display: "none", zIndex: "5" }}>
				<Card>
					<Card.Header className="bg-primary">
						<Card.Title className="text-white">{selectedCity?.nome}</Card.Title>
					</Card.Header>
					<Card.Body className="border border-secondary">
						<p>
							<label className="fw-bolder mx-2">Casos de hanseníase:</label>
							{casesTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalHansen?.toLocaleString() || 0}
							<label className="fw-bolder mx-2">Atendidos: </label>
							{casesTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalAttendancesCitizensWithHansen?.toLocaleString() || 0}
						</p>
						<p>
							<label className="fw-bolder mx-2">Casos de Tuberculose:</label>
							{casesTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalTuberculosis?.toLocaleString() || 0}
							<label className="fw-bolder mx-2">Atendidos: </label>
							{casesTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalAttendancesCitizensWithTuberculosis?.toLocaleString() || 0}
						</p>
						<p>
							<label className="fw-bolder mx-2">Casos de Hipertensos:</label>
							{casesNotTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalHypertension?.toLocaleString() || 0}
							<label className="fw-bolder mx-2">Atendidos: </label>
							{casesNotTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalAttendancesCitizensWithHypertension?.toLocaleString() || 0}
						</p>
						<p>
							<label className="fw-bolder mx-2">Casos de Diabéticos:</label>
							{casesNotTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalDiabetics?.toLocaleString() || 0}
							<label className="fw-bolder mx-2">Atendidos: </label>
							{casesNotTransmissible
								?.find((item) => item.city.ibgeCode === String(selectedCity?.id))
								?.totalAttendancesCitizensWithDiabetics?.toLocaleString() || 0}
						</p>
					</Card.Body>
				</Card>
			</div>
		</div>
	);
}

import { FederalUnit } from "@/@types/FederalUnit";

export const federalUnits: FederalUnit[] = [
	{ id: 1, name: "Acre", abbreviation: "AC", ibgeCode: "12" },
	{ id: 2, name: "Alagoas", abbreviation: "AL", ibgeCode: "27" },
	{ id: 3, name: "Amapá", abbreviation: "AP", ibgeCode: "16" },
	{ id: 4, name: "Amazonas", abbreviation: "AM", ibgeCode: "13" },
	{ id: 5, name: "Bahia", abbreviation: "BA", ibgeCode: "29" },
	{ id: 6, name: "Ceará", abbreviation: "CE", ibgeCode: "23" },
	{ id: 7, name: "Distrito Federal", abbreviation: "DF", ibgeCode: "53" },
	{ id: 8, name: "Espírito Santo", abbreviation: "ES", ibgeCode: "32" },
	{ id: 9, name: "Goiás", abbreviation: "GO", ibgeCode: "52" },
	{ id: 10, name: "Maranhão", abbreviation: "MA", ibgeCode: "21" },
	{ id: 11, name: "Mato Grosso", abbreviation: "MT", ibgeCode: "51" },
	{ id: 12, name: "Mato Grosso do Sul", abbreviation: "MS", ibgeCode: "50" },
	{ id: 13, name: "Minas Gerais", abbreviation: "MG", ibgeCode: "31" },
	{ id: 14, name: "Pará", abbreviation: "PA", ibgeCode: "15" },
	{ id: 15, name: "Paraíba", abbreviation: "PB", ibgeCode: "25" },
	{ id: 16, name: "Paraná", abbreviation: "PR", ibgeCode: "41" },
	{ id: 17, name: "Pernambuco", abbreviation: "PE", ibgeCode: "26" },
	{ id: 18, name: "Piauí", abbreviation: "PI", ibgeCode: "22" },
	{ id: 19, name: "Rio Grande do Norte", abbreviation: "RN", ibgeCode: "24" },
	{ id: 20, name: "Rio Grande do Sul", abbreviation: "RS", ibgeCode: "43" },
	{ id: 21, name: "Rio de Janeiro", abbreviation: "RJ", ibgeCode: "33" },
	{ id: 22, name: "Rondônia", abbreviation: "RO", ibgeCode: "11" },
	{ id: 23, name: "Roraima", abbreviation: "RR", ibgeCode: "14" },
	{ id: 24, name: "Santa Catarina", abbreviation: "SC", ibgeCode: "42" },
	{ id: 25, name: "São Paulo", abbreviation: "SP", ibgeCode: "35" },
	{ id: 26, name: "Sergipe", abbreviation: "SE", ibgeCode: "28" },
	{ id: 27, name: "Tocantins", abbreviation: "TO", ibgeCode: "17" },
];

import { Link, useLocation } from "react-router-dom";

type BreadcrumbProps = {
	initialLink: string;
	initialItem: string;
	uniqueLink?: boolean;
};

export function Breadcrumbs({ initialItem, initialLink, uniqueLink }: BreadcrumbProps) {
	const location = useLocation();

	const crumbs = location.pathname.split("/").filter(Boolean);
	const items = crumbs.length > 2 ? [crumbs.slice(0, -1).join(" "), crumbs.slice(-1)] : crumbs;

	return (
		<nav>
			<ul className="breadcrumb breadcrumb-line text-muted fs-6 fw-bold pb-1">
				{uniqueLink ? (
					<li className="breadcrumb-item text-muted text-capitalize">{initialItem}</li>
				) : (
					<>
						<li className="breadcrumb-item">
							<Link to={initialLink}>{initialItem}</Link>
						</li>
						{items.map((link, index) => (
							<li
								key={`breadcrumb-item-${index}`}
								className="breadcrumb-item text-muted text-capitalize"
							>
								{link}
							</li>
						))}
					</>
				)}
			</ul>
		</nav>
	);
}

import { FaSyringe } from "react-icons/fa";

import { CitizenVaccinations } from "@/pages/TripleViralManagement/TripleViralCitizensTable";
import { VaccinationCards } from "@/components/IndicatorsPanel/Indicator5/VaccinationCards";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";

type Props = {
	citizenVaccinations: CitizenVaccinations;
};

export function TripleViralCitizenVaccinationsList({ citizenVaccinations }: Props) {
	return (
		<div>
			{citizenVaccinations.vaccinations.map((vaccination, index) => (
				<VaccinationCards
					key={`citizen-vaccination-${index}`}
					vaccine={{ vaccine: vaccination }}
					title={vaccination.immunobiologicalDosage.acronym}
					theme="success"
				/>
			))}
			{!(citizenVaccinations.vaccinations.length > 0) && (
				<NoRecordsFeedback message="Vacinas não encontradas" icon={<FaSyringe />} />
			)}
		</div>
	);
}

import React, { useState } from "react";

import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";

type TrackListFormData = {
	title: string;
	description: string;
	thumbnailFile?: File;
};

export function FormTrackList() {
	const api = useApi();
	const initialValues = {} as TrackListFormData;
	const [isLoading, setIsLoading] = useState(false);
	const formik = useFormik<TrackListFormData>({
		initialValues,
		onSubmit: handleRegisterTrackList,
	});

	async function handleRegisterTrackList(trackListFormData: TrackListFormData) {
		if (!formik.values.thumbnailFile) return;
		const formData = new FormData();
		formData.append("thumbnailFile", formik.values.thumbnailFile);
		formData.append("title", trackListFormData.title);
		formData.append("description", trackListFormData.description);

		try {
			setIsLoading(true);
			const responseTrackList = await api.post("/tracklist/v1/register", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (responseTrackList.status !== 200) {
				toast.error("Erro ao registrar playlist");
			} else {
				toast.success("Playlist registrada com sucesso");
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error("Erro ao registrar playlist");
		} finally {
			setIsLoading(false);
		}
	}

	async function handleFileChange(event: React.ChangeEvent<any>, formikKey: string) {
		if (event.target.files) {
			formik.setFieldValue(formikKey, event.target.files[0]);
		}
	}

	return (
		<section className="bg-white p-4">
			<ToastContainer />
			<h1 className="text-primary fs-4 mb-5">Cadastro de playlist</h1>
			<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
				<Form.Group>
					<Form.Label>Título</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formik.values.title}
						onChange={formik.handleChange}
						required
					/>
				</Form.Group>
				<Form.Group className="m-2">
					<Form.Label>Descrição</Form.Label>
					<Form.Control
						type="text"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						required
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Arquivo da Thumbnail</Form.Label>
					<Form.Control
						type="file"
						name="thumbnailFile"
						accept={"image/*"}
						onChange={(event) => handleFileChange(event, "thumbnailFile")}
						required
					/>
				</Form.Group>
				<Button className="m-2" type="submit" disabled={isLoading}>
					Registrar
				</Button>
			</Form>
		</section>
	);
}

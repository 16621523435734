import { v4 as uuidv4 } from "uuid";
import { Skeleton } from "../Skeleton";

type Props = {
	numberOfCells: number;
	numberOfRows?: number;
	height?: number;
};

export function TableSkeletons({ numberOfCells, numberOfRows, height }: Props) {
	return (
		<>
			{Array.from({ length: numberOfRows || 10 }, () => (
				<tr key={uuidv4()}>
					{Array.from({ length: numberOfCells }, () => (
						<td key={uuidv4()}>
							<Skeleton height={height || 20} />
						</td>
					))}
				</tr>
			))}
		</>
	);
}

import CloseButton from "react-bootstrap/CloseButton";
import Modal from "react-bootstrap/Modal";
import { ModalProps } from "react-bootstrap";

import { ReleaseNote } from "@/@types/ReleaseNote";
import { FormReleaseNote } from "../FormReleaseNote";

type Props = ModalProps & {
	releaseNote?: ReleaseNote;
};

export function ModalReleaseNoteEdit({ releaseNote, ...rest }: Props) {
	return (
		<Modal scrollable {...rest} size="lg">
			<Modal.Header className="d-block pb-3">
				<div className="col-xl-12 d-flex">
					<CloseButton onClick={rest.onHide} />
				</div>
				<div className="col-xl-12">
					<Modal.Title className="text-center">Notas de atualização</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<FormReleaseNote releaseNote={releaseNote} />
			</Modal.Body>
		</Modal>
	);
}

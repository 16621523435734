import { FaInfoCircle, FaUsers } from "react-icons/fa";
import { MdOutlinePendingActions } from "react-icons/md";
import { BsGraphUp, BsGraphUpArrow } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { CardInformative } from "@/pages/ReterritorializationAcsDashboard/CardInformative";

import "./styles.scss";

type Props = {
	total: number;
	totalPreview: number;
	totalDelayed: number;
	totalPending: number;
};

export function GlobalInformativeActiveVaccination({
	total,
	totalPreview,
	totalDelayed,
	totalPending,
}: Props) {
	return (
		<Row className="row-informative-active-vacination-image mx-3 bg-white">
			<Row className="row-information-active-vaccination pt-5 mb-10 justify-content-evenly rounded">
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-active-vaccination bg-white rounded p-2 my-2"
				>
					<CardInformative
						title="Total cidadãos"
						value={total}
						classNameTitle="text-muted"
						classNameValue="text-muted"
						icon={<FaUsers className="fs-2 text-muted" />}
						metaData={
							<CardOverlayTrigger
								header="Total de cidadãos"
								message="Quantidade de cidadãos identificados dentro do grupo selecionado."
								icon={<FaInfoCircle className="text-muted" />}
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-active-vaccination bg-info rounded p-2 my-2"
				>
					<CardInformative
						title="Previsto"
						classNameCard="bg-info"
						classNameTitle="text-white"
						classNameValue="text-white"
						value={totalPreview}
						icon={<BsGraphUp className="fs-2 fw-bolder text-white" />}
						metaData={
							<CardOverlayTrigger
								header="Total de vacinas previstas"
								message="Totalidade de vacinas a serem aplicadas (Pendentes + atrasados)"
								icon={<FaInfoCircle className="text-white" />}
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-active-vaccination bg-warning rounded p-2 my-2"
				>
					<CardInformative
						title="Pendentes"
						classNameCard="bg-warning"
						classNameTitle="text-white"
						classNameValue="text-white"
						value={totalPending}
						icon={<MdOutlinePendingActions className="text-white fs-2 fw-bolder" />}
						metaData={
							<CardOverlayTrigger
								header="Total de vacinas Pendentes"
								message="Quantidade de vacinas que não ultrapassaram a data prevista ou aprazamento."
								icon={<FaInfoCircle className="text-white" />}
							/>
						}
					/>
				</Col>
				<Col
					sm={6}
					md={4}
					lg={2}
					xxl={2}
					className="card-information-active-vaccination bg-danger rounded p-2 my-2"
				>
					<CardInformative
						title="Atrasadas"
						classNameCard="bg-danger"
						classNameTitle="text-white"
						classNameValue="text-white"
						value={totalDelayed}
						icon={<BsGraphUpArrow className="text-white fs-2 fw-bolder" />}
						metaData={
							<CardOverlayTrigger
								header="Total de vacinas atrasadas"
								message="Quantidade de vacinas que ultrapassaram a data prevista ou aprazamento"
								icon={<FaInfoCircle className="text-white" />}
							/>
						}
					/>
				</Col>
			</Row>
		</Row>
	);
}

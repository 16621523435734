import { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import { FaHome } from "react-icons/fa";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import ModalFooter from "react-bootstrap/ModalFooter";
import Table from "react-bootstrap/Table";

import { useApi } from "@/hooks/useApi";
import { Paginated } from "@/@types/paginated";
import { Role } from "@/@types/Role";
import { Permission } from "@/@types/Permission";
import { RolePermission } from "@/@types/RolePermission";

import { TableSkeletons } from "@/components/TableSkeletons";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";

type Props = ModalProps & {
	role: Role;
};

export function ModalPerfilAccess({ role, ...rest }: Props) {
	const api = useApi();
	const [isLoading, setIsLoading] = useState(false);
	const [permissions, setPermissions] = useState<Paginated<Permission>>(
		{} as Paginated<Permission>
	);
	const [rolePermissions, setRolePermissions] = useState<RolePermission[]>([]);
	const [pageNumber, setPageNumber] = useState(1);

	async function handleFetchPermissions() {
		setIsLoading(true);
		try {
			const { data } = await api.get<Paginated<Permission>>(`/permission/v1/GetAll`, {
				params: { roleId: role.id, pageNumber: pageNumber, pageSize: 10 },
			});
			setPermissions(data);
		} catch (error) {
			console.error(error);
			setPermissions({} as Paginated<Permission>);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleFetchProfilePermissions() {
		setIsLoading(true);
		try {
			const { data } = await api.get<RolePermission[]>(
				`/profilepermission/v1/GetByProfileId`,
				{
					params: { roleId: role?.id },
				}
			);
			setRolePermissions(data);
		} catch (error) {
			console.error(error);
			setRolePermissions([]);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleChangeIsActive(roleId: number, permissionId: number) {
		setIsLoading(true);

		const searchPermission = rolePermissions.find(
			(rolepermission) => permissionId === rolepermission.permissionId
		);

		try {
			await api.post(
				`/profilepermission/v1/${
					searchPermission ? "DeleteProfilePermission" : "RegisterProfilePermission"
				}`,
				{
					roleId: roleId,
					permissionId: permissionId,
				}
			);
			handleFetchProfilePermissions();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		handleFetchProfilePermissions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		handleFetchPermissions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!role?.id) {
			return;
		}
		if (pageNumber !== 1) {
			setPageNumber(1);
		} else {
			handleFetchProfilePermissions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role]);

	return (
		<Modal {...rest} size="lg">
			<ToastContainer />
			<Modal.Header closeButton>
				<Modal.Title>
					Lista de Permissões{" "}
					<label className="d-flex text-primary fs-6 ms-3">{role?.name}</label>{" "}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Table className="table align-middle fs-6 dataTable no-footer " responsive>
					<thead>
						<tr className="fw-bold ">
							<th>STATUS</th>
							<th>DESCRIÇÃO</th>
						</tr>
					</thead>
					<tbody>
						{permissions?.data ? (
							permissions.data?.map((permission, index) => (
								<tr className="fs-6" key={index}>
									<td>
										<div className="form-check ms-2 rounded">
											<input
												className="form-check-input rounded"
												type="checkbox"
												id="check-permission"
												onChange={() =>
													handleChangeIsActive(role.id, permission.id)
												}
												checked={
													rolePermissions.find(
														(rolepermission) =>
															permission?.id ===
															rolepermission.permissionId
													)
														? true
														: false
												}
											/>
										</div>
									</td>
									<td className="text-start">{permission.name}</td>
								</tr>
							))
						) : isLoading ? (
							<TableSkeletons numberOfCells={2} />
						) : null}
					</tbody>
				</Table>
			</Modal.Body>

			<ModalFooter className="m-auto">
				{permissions?.totalRecords ? (
					<PaginationLinks
						itemsPerPage={permissions?.pageSize}
						totalPages={permissions?.totalRecords}
						changeSelectedPage={handleChangePageNumber}
						pageNumber={pageNumber || 0}
					/>
				) : (
					!isLoading &&
					!permissions && (
						<NoRecordsFeedback
							message={"Nenhuma permissão encontrada."}
							icon={<FaHome />}
						/>
					)
				)}
			</ModalFooter>
		</Modal>
	);
}
